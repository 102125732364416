import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FolderIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SwitchWithTooltipProps {
  isProjectGroup: boolean;
  activeFilter: "groupItems" | "projectFromGroup";
  handleFilterSwitch: (filter: "groupItems" | "projectFromGroup") => void;
  iconProject: string;
}

const SwitchTooltip: React.FC<SwitchWithTooltipProps> = ({
  isProjectGroup,
  activeFilter,
  handleFilterSwitch,
  iconProject,
}) => {
  const { t } = useTranslation();

  if (!isProjectGroup) {
    return <BiSearchAlt2 color={"#9ca3af"} className="absolute mt-5 top-17 left-500 w-6 h-6" />;
  }

  if (isProjectGroup && activeFilter === "groupItems") {
    return (
      <Tooltip
        title={t("general.clickToSearchProject")}
        placement="bottom"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <FolderIcon
          color="#00C889"
          className="cursor-pointer absolute top-17 left-500 w-6 h-6"
          onClick={() => handleFilterSwitch("projectFromGroup")}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={t("general.clickToSearchProjectGroup")}
      placement="bottom"
      PopperProps={{
        disablePortal: true,
      }}
    >
      <img
        src={iconProject}
        alt="Module"
        className="h-5 w-5 cursor-pointer absolute top-17 left-500"
        onClick={() => handleFilterSwitch("groupItems")}
      />
    </Tooltip>
  );
};

export default SwitchTooltip;
