import { IProjectGroupWithProjects } from "src/features/projectGroups/state/projectGroupsSlice";
import { IMedia } from "../../../model/unityObject";
import { IMediaFilter } from "./gallerySlice";

/**
 * Init fonctions : iterate in all media values to get the tag, mediaType and label values
 */

// Iterate in all tags value to get all the possible values
// list.map((media: IMedia) => media.tags) returns an array of array
// we convert it to a flat array Array.prototype.concat.apply
// The last filter is used to get get an unique value array
export const getTagsOrganization = (listTags: any, orgaId: number) => {
  return listTags
    .filter((tag: any) => Number(tag.organization_id.id) === Number(orgaId))
    .map((tag: any) => tag.name);
};
export const getTagsOrganizationMedia = (listTags: any, orgaId: number) => {
  return listTags.filter((tag: any) => Number(tag.organization_id.id) === Number(orgaId));
};

// Filter a media array by size
export const getMediaTypeValue = (list: Array<IMedia>) => {
  return [...list].map((media: IMedia) => media.type).filter((v, i, a) => a.indexOf(v) === i);
};
const prettifyFormat = (format: string) => {
  switch (format) {
    case "image":
    case "audio":
      return format.replace(/^\w/, (c) => c.toUpperCase());
    case "model3d":
      return "3D Model";
    case "video":
      return "video";
    default:
      return format;
  }
};

export const getMediaLabelValue = (list: Array<IMedia>) => {
  return [...list].map((media: IMedia) => media.label).filter((v, i, a) => a.indexOf(v) === i);
};

// Filter a media and project group array by date
export const sortByDate = (list: Array<any>, direction: string): Array<any> => {
  return [...list].sort((elem1: any, elem2: any) => {
    const firstElement = direction === "desc" ? elem2 : elem1;
    const secondElement = direction === "desc" ? elem1 : elem2;

    // Determine the values to compare
    const firstValue =
      firstElement.updated_at_ts !== undefined
        ? Number(firstElement.updated_at_ts)
        : new Date(firstElement.createdAt).getTime();
    const secondValue =
      secondElement.updated_at_ts !== undefined
        ? Number(secondElement.updated_at_ts)
        : new Date(secondElement.createdAt).getTime();

    return firstValue - secondValue;
  });
};

// Filter a media array by size
export const sortBySize = (list: Array<IMedia>, direction: "asc" | "desc"): Array<IMedia> => {
  return [...list].sort((elem1: IMedia, elem2: IMedia) => {
    const firstElement = direction === "desc" ? elem2 : elem1;
    const secondElement = direction === "desc" ? elem1 : elem2;
    return (firstElement.size_in_bytes || 0) - (secondElement.size_in_bytes || 0);
  });
};

export const sortByAlpha = (list: Array<any>, direction: string): Array<any> => {
  return [...list].sort((elem1: any, elem2: any) => {
    const firstElement = direction === "desc" ? elem2 : elem1;
    const secondElement = direction === "desc" ? elem1 : elem2;
    return firstElement.name.toLowerCase().localeCompare(secondElement.name.toLowerCase());
  });
};
export const sortByAlphabeticalOrder = (
  list: Array<any>,
  propertyName: string,
  direction: "asc" | "desc",
  isMediaList = false,
): Array<any> => {
  return [...list].sort((elem1: any, elem2: any) => {
    let property1 = elem1[propertyName];
    let property2 = elem2[propertyName];

    if (propertyName === "permissions") {
      property1 = elem1[propertyName].length;
      property2 = elem2[propertyName].length;
    }
    if (isMediaList) {
      property1 = propertyName === "Type" ? prettifyFormat(elem1.media_360_tag) : elem1.type;
      property2 = propertyName === "Type" ? prettifyFormat(elem2.media_360_tag) : elem2.type;
    }

    if (propertyName === "projectGroupId") {
      const projectGroupId1 = [];

      elem1.permissions.forEach((permission: any) => {
        projectGroupId1.push(permission.project_group.id);
      });

      const projectGroupId2 = [];

      elem2.permissions.forEach((permission: any) => {
        projectGroupId2.push(permission.project_group.id);
      });

      property1 = projectGroupId1.length;
      property2 = projectGroupId2.length;
    }

    if (direction === "asc") {
      if (String(property1) < String(property2)) {
        return -1;
      }
      if (String(property1) > String(property2)) {
        return 1;
      }
    } else if (direction === "desc") {
      if (String(property1) > String(property2)) {
        return -1;
      }
      if (String(property1) < String(property2)) {
        return 1;
      }
    }
    return 0;
  });
};

export const filterByTag = (list: any, tagToFilter: string) => {
  return [...list].filter((media) =>
    media.tags.map((tags: any) => tags.name).includes(tagToFilter),
  );
};
export const filterByOrderOrganization = (list: any, typeToFilter?: string) => {
  if (typeToFilter === "public") {
    return [...list].filter((mediaList) => mediaList.organization.id.toString() === "3");
  } else if (typeToFilter === "private") {
    return [...list].filter((mediaList) => !(mediaList?.organization?.id?.toString() === "3"));
  } else {
    return list;
  }
};

export const filterByType = (list: Array<IMedia>, typeToFilter: string) => {
  if (typeToFilter === "all") {
    return list;
  }
  return [...list].filter((media) => media.type === typeToFilter);
};

export const filterByLabels = (list: Array<IMedia>, labelToFilter: Array<string>) => {
  return [...list].filter((media) => labelToFilter.includes(media.label));
};

export const filterByMedia360Tag = (list: Array<IMedia>, media360TagToFilter: string) => {
  return [...list].filter((media) =>
    media.media_360_tag && media.media_360_tag !== ""
      ? media360TagToFilter.includes(media.media_360_tag)
      : false,
  );
};
export const filterByFileName = (list: Array<IMedia>, mediaFileName: string) => {
  return [...list].filter((media) =>
    media.name && media.name !== ""
      ? media.name.toLowerCase().includes(mediaFileName.toLowerCase())
      : false,
  );
};

export const filterMediaResults = (list: Array<IMedia>, filter: IMediaFilter): Array<IMedia> => {
  let filteredList = [...list];
  if (filter.filename) {
    filteredList = filterByFileName(filteredList, filter.filename);
  }
  if (filter.mediaType) {
    filteredList = filterByType(filteredList, filter.mediaType);
  }
  if (filter.labels && Array.isArray(filter.labels) && filter.labels.length > 0) {
    filteredList = filterByLabels(filteredList, filter.labels);
  }
  if (filter.media360tag && filter.media360tag !== "") {
    filteredList = filterByMedia360Tag(filteredList, filter.media360tag);
  }
  if (filter.property !== "all") {
    filteredList = filterByOrderOrganization(filteredList, filter.property);
  }
  if (filter && filter.order) {
    // Order at last, it's easiest to sort a small array
    const order = filter.order && filter.order.split("_");
    console.log("filterMediaResults order[0]:", order[0]);
    if (
      (order &&
        Array.isArray(order) &&
        (order[0] === "size" ||
          order[0] === "date" ||
          order[0] === "alphanum" ||
          order[0] === "public" ||
          order[0] === "private") &&
        order[1] === "asc") ||
      order[1] === "desc"
    ) {
      if (order[0] === "alphanum") {
        filteredList = sortByAlpha(filteredList, order[1]);
      } else if (order[0] === "size") {
        filteredList = sortBySize(filteredList, order[1]);
      } else if (order[0] === "public" || order[0] === "private") {
        filteredList = filterByOrderOrganization(filteredList, order[0]);
      } else {
        filteredList = sortByDate(filteredList, order[1]);
      }
    }
  }

  return filteredList;
};
export const filterGroupResults = (
  list: Array<IProjectGroupWithProjects>,
  filter: string[],
): Array<IProjectGroupWithProjects> => {
  let filteredList = [...list];
  if (filter) {
    if (filter[0] === "alphanum") {
      filteredList = sortByAlpha(filteredList, filter[1]);
    } else {
      filteredList = sortByDate(filteredList, filter[1]);
    }
  }

  return filteredList;
};

export const sortByStatus = (
  list: Array<any>,
  direction: "asc" | "desc",
  listStatus: Array<any>,
): Array<any> => {
  const statusOrder = sortByAlphabeticalOrder(listStatus, "status", direction);

  return [...list].sort((elem1: any, elem2: any) => {
    const property1 = statusOrder.findIndex((item: any) => item.id === elem1.id);
    const property2 = statusOrder.findIndex((item: any) => item.id === elem2.id);

    if (direction === "asc") {
      if (String(property1) < String(property2)) {
        return -1;
      }
      if (String(property1) > String(property2)) {
        return 1;
      }
    } else if (direction === "desc") {
      if (String(property1) > String(property2)) {
        return -1;
      }
      if (String(property1) < String(property2)) {
        return 1;
      }
    }
    return 0;
  });
};

export const sortByUsers = (
  list: Array<any>,
  direction: "asc" | "desc",
  propertyName: any,
  isStats = false,
): Array<any> => {
  let mappingIdAndUser = [];

  if (isStats) {
    mappingIdAndUser = list.map((elem: any) => ({
      id: elem.id,
      user: elem.enduser?.[propertyName],
    }));
  } else {
    mappingIdAndUser = list.map((elem: any) => ({
      id: elem.id,
      user: elem.user[propertyName],
    }));
  }

  const statusOrder = sortByAlphabeticalOrder(mappingIdAndUser, "user", "asc");

  return [...list].sort((elem1: any, elem2: any) => {
    const elementUser1 = isStats ? elem1.enduser : elem1.user;
    const elementUser2 = isStats ? elem2.enduser : elem2.user;
    const property1 = statusOrder.findIndex(
      (item: any) => item.user === elementUser1?.[propertyName],
    );
    const property2 = statusOrder.findIndex(
      (item: any) => item.user === elementUser2?.[propertyName],
    );
    return direction === "asc" ? property1 - property2 : property2 - property1;
  });
};

export const sortByExtenxion = (list: Array<any>, direction: "asc" | "desc"): Array<any> => {
  const mappingIdAndUser = list.map((elem: any) => ({
    id: elem.id,
    extension: elem.filename.split(".").pop().toUpperCase(),
  }));
  const statusOrder = sortByAlphabeticalOrder(mappingIdAndUser, "extension", "asc");

  return [...list].sort((elem1: any, elem2: any) => {
    const property1 = statusOrder.findIndex((item: any) => item.id === elem1.id);
    const property2 = statusOrder.findIndex((item: any) => item.id === elem2.id);
    return direction === "asc" ? property1 - property2 : property2 - property1;
  });
};
