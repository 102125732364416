import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import Badge, { BadgeProps } from "../../common/components/Badge/Badge";
import Icon from "../../common/components/Icon/Icon";
import PlatformIcon from "../../common/components/Icon/PlatformIcon/PlatformIcon";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import PlaceholderWithIcon from "../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import { Tooltip } from "../../common/components/Tooltip/Tooltip";
import { AppDispatch } from "../../common/state/store";
import {
  fetchCurrentProjectGroupBuilds,
  retryPublication,
  getBuilds,
} from "../../features/builds/state/buildsSlice";
import NewPublicationButton from "../../features/projectGroupPublication/components/NewPublicationButton";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import { ILanguage } from "../../model/model";
import { dateConfig } from "src/common/util/DateConfig";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { languageConfig } from "src/common/util/langConfig";
import TableComp from "src/common/components/Table/Table";

export const PROJECT_STATUS_COMPONENT_ID = "projectStatusComponent";
export const PROJECT_GROUP_BUILD_LIST_COMPONENT_ID = "projectGroupBuildListComponent";

export interface ISelectedPlatform {
  platform: string;
}

export const PROJECT_GROUP_PUBLISH_COMPONENT_ID = "projectGroupPublishTableId";

const ProjectGroupPublish = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);
  const builds = useSelector(getBuilds);
  const currentUser = useSelector(getCurrentUser);

  const currentProjectGroupBuilds = builds
    ? builds.filter((elt) => Number(elt.project_group?.id) === Number(currentProjectGroup.id))
    : [];

  const lastVersionNumber =
    currentProjectGroupBuilds && currentProjectGroupBuilds.length > 0
      ? [...currentProjectGroupBuilds].sort((a, b) => Number(b.version) - Number(a.version))[0]
          .version
      : -1;

  const noAccessRows = currentProjectGroupBuilds.filter((b) => b.version < lastVersionNumber);
  const currentRows = currentProjectGroupBuilds.filter(
    (b) => b.status === "ChangesNeeded" || b.status === "PublishWaiting",
  );
  const publishedRows = currentProjectGroupBuilds.filter(
    (b) => b.status === "Published" && b.version >= lastVersionNumber,
  );
  const errorRows = currentProjectGroupBuilds.filter((b) => b.status === "ErrorWhileBuilding");

  const preferredLanguage = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const [isDarkMode, setIsDarkMode] = useState(false);

  const onSelectMode = (mode: "dark" | "light") => {
    if (mode === "dark") {
      if (!isDarkMode) setIsDarkMode(true);
    } else {
      if (isDarkMode) setIsDarkMode(false);
    }
  };

  useEffect(() => {
    // Check to see if Media-Queries are supported
    if (window.matchMedia) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => onSelectMode(e.matches ? "dark" : "light"));

      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        if (!isDarkMode) setIsDarkMode(true);
      }
    }
  }, []);

  const fetchBuilds = () => {
    dispatch(
      fetchCurrentProjectGroupBuilds({
        projectGroupId: currentProjectGroup.id,
        componentId: PROJECT_GROUP_PUBLISH_COMPONENT_ID,
      }),
    );
  };

  useEffect(() => {
    fetchBuilds();
  }, [currentProjectGroup]);

  const getBadgeParams = ({ status }: { status: string }): BadgeProps => {
    switch (status) {
      case "Published":
        return {
          color: "bg-green-500",
          textColor: "text-green-100",
          text: t("general.published"),
        };
      case "PublishWaiting":
        return {
          color: "bg-blue-500",
          textColor: "text-blue-100",
          text: t("pages.projectGroup.publish.buildStatus.inProgress"),
        };
      case "ChangesNeeded":
        return {
          color: "bg-red-500",
          textColor: "text-red-100",
          text: t("pages.projectGroup.publish.buildStatus.requestChanges"),
        };
      case "ErrorWhileBuilding":
        return {
          color: "bg-red-500",
          textColor: "text-red-100",
          text: t("pages.projectGroup.publish.buildStatus.errorWhileBuilding"),
        };
      case "NoAccess":
      default:
        return {
          color: "bg-gray-500",
          textColor: "text-gray-100",
          text: t("pages.projectGroup.publish.buildStatus.noAccess"),
        };
    }
  };

  const retryBuild = ({ buildId }: { buildId: number }) => {
    dispatch(retryPublication({ buildId })).then(() => fetchBuilds());
  };

  const colHeaders = (status: any) => {
    const commonHeaders = [
      t("pages.projectGroup.publish.buildsTable.version"),
      t("pages.projectGroup.publish.buildsTable.status"),
      t(
        status !== "PublishWaiting" && status !== "ErrorWhileBuilding"
          ? "pages.projectGroup.publish.buildsTable.publishDate"
          : "pages.projectGroup.publish.buildsTable.requestDate",
      ),
      t("pages.projectGroup.publish.buildsTable.modules"),
      t("pages.projectGroup.publish.buildsTable.platform"),
    ];

    if (status === "Published") {
      commonHeaders.push(t("general.download"));
    }
    if (status === "ErrorWhileBuilding") {
      commonHeaders.push(t("pages.projectGroup.publish.buildsTable.retry"));
    }
    return commonHeaders;
  };

  const rowsPublishWaiting = (data: any, status: any) => {
    return data.map((elt: any) => {
      return {
        check: "",
        version: `V${elt.version}`,
        status: (
          <Badge
            {...getBadgeParams({
              status: status !== "NoAccess" ? elt.status : "NoAccess",
            })}
            className="p-2"
          />
        ),
        publishDate: new Date(
          (status !== "PublishWaiting" && status !== "ErrorWhileBuilding"
            ? elt.publishing_date_ts
            : elt.request_date_ts) * 1000,
        ).toLocaleString(dateConfig(preferredLanguage)),
        modules: (
          <div className="flex">
            {elt.project_group_snapshot_sorted_by_order.published_projects.map((p: any) => {
              return (
                <div
                  className="bg-gray-600 mr-2 p-1 text-white rounded-sm"
                  key={`${status}-mod-${elt.id}-${p.project.id}`}
                >
                  {p.project.name}
                </div>
              );
            })}
          </div>
        ),
        platform: (
          <div className="flex h-8 p-1 pl-1">
            <PlatformIcon
              platform={elt.target_platform}
              scormVersion={elt?.scorm_version}
              iconProps={{ fill: "black" }}
              inTable
            />
          </div>
        ),
      };
    });
  };
  // refacto for build dowload hidde here
  const rowsPublished = (data: any, status: any) => {
    return data.map((elt: any) => {
      return {
        check: "",
        version: `V${elt.version}`,
        status: (
          <Badge
            {...getBadgeParams({
              status: status !== "NoAccess" ? elt.status : "NoAccess",
            })}
            className="p-2"
          />
        ),
        publishDate: new Date(
          (status !== "PublishWaiting" && status !== "ErrorWhileBuilding"
            ? elt.publishing_date_ts
            : elt.request_date_ts) * 1000,
        ).toLocaleString(dateConfig(preferredLanguage)),
        modules: (
          <div className="flex">
            {elt.project_group_snapshot_sorted_by_order.published_projects.map((p: any) => {
              return (
                <div
                  className="bg-gray-600 mr-2 p-1 text-white rounded-sm"
                  key={`${status}-mod-${elt.id}-${p.project.id}`}
                >
                  {p.project.name}
                </div>
              );
            })}
          </div>
        ),
        platform: (
          <div className="flex h-8 p-1 pl-1">
            <PlatformIcon
              platform={elt.target_platform}
              iconProps={{ fill: "black" }}
              scormVersion={elt?.scorm_version}
              inTable
            />
          </div>
        ),

        download:
          status === "Published" ? (
            <div className="flex ml-7">
              {elt.executable_path ? (
                <button>
                  <a href={elt.s3_url}>
                    <Icon
                      icon={"DownloadIcon"}
                      className={""}
                      width={24}
                      stroke="rgb(0 200 137)"
                    ></Icon>
                  </a>
                </button>
              ) : (
                <Tooltip
                  message={t("pages.projectGroup.publish.brokenLinkBuildTooltip")}
                  classNameChildren="right-0"
                >
                  <Icon
                    icon={"ExclamationIcon"}
                    className={"stroke-yellow-400 dark:stroke-yellow-200"}
                    width={24}
                  />
                </Tooltip>
              )}
            </div>
          ) : status === "ErrorWhileBuilding" ? (
            <td scope="row" className="px-6">
              <div className="flex justify-center">
                <button onClick={() => retryBuild({ buildId: elt.id })}>
                  <Icon icon={"RefreshIcon"} className={""} width={24} stroke="rgb(0 200 137)" />
                </button>
              </div>
            </td>
          ) : null,
      };
    });
  };

  const getGridRowClass = () => {
    let nbRows = 0;
    currentRows.length > 0 ? nbRows++ : null;
    publishedRows.length > 0 ? nbRows++ : null;
    noAccessRows.length > 0 ? nbRows++ : null;
    errorRows.length > 0 ? nbRows++ : null;

    switch (nbRows) {
      case 1:
        return "grid-rows-1";
      case 2:
        return "grid-rows-2";
      case 3:
        return "grid-rows-3";
      case 4:
        return "grid-rows-4";
      default:
        return "";
    }
  };

  return (
    <>
      <InfoButton relevantArticle="howToPublish" />
      <IsLoading componentId={PROJECT_GROUP_PUBLISH_COMPONENT_ID} showSuccess={false}>
        <div className="h-full w-full flex flex-col" style={{ height: "calc(100% - 10rem)" }}>
          {currentProjectGroupBuilds.length > 0 ? (
            <div className="h-full w-full">
              <div className="h-[3rem] right-0 flex self-end mr-4 absolute mt-4">
                <NewPublicationButton />
              </div>
              <div
                className={`grid ${getGridRowClass()} mt-8`}
                style={{ height: "calc(100% - 2rem)" }}
              >
                {currentRows.length > 0 && (
                  <div className="m-4 flex flex-col">
                    <h1 className="font-medium text-xl">
                      {t("pages.projectGroup.publish.inProgressPublish")}
                    </h1>

                    <div className="overflow-y-auto">
                      {/* {getPublishingTable({ data: currentRows, status: "PublishWaiting" })} */}
                      <TableComp
                        isCursorDesativated={true}
                        filterActivated={false}
                        rows={rowsPublishWaiting(currentRows, "PublishWaiting")}
                        colHeaders={colHeaders("PublishWaiting")}
                        className="h-full w-full overflow-x-hidden the-table-comp"
                      />
                    </div>
                  </div>
                )}

                {errorRows.length > 0 && (
                  <div className="m-4 flex flex-col">
                    <h1 className="font-medium text-xl">
                      {t("pages.projectGroup.publish.failedPublish")}
                    </h1>
                    <div className="overflow-y-auto">
                      <TableComp
                        isCursorDesativated={true}
                        filterActivated={false}
                        rows={rowsPublished(errorRows, "ErrorWhileBuilding")}
                        colHeaders={colHeaders("ErrorWhileBuilding")}
                        className="h-full w-full overflow-x-hidden the-table-comp"
                      />
                    </div>
                  </div>
                )}

                {publishedRows.length > 0 && (
                  <div className="m-4 flex flex-col">
                    <h1 className="font-medium text-xl">
                      {t("pages.projectGroup.publish.finishedPublish")}
                    </h1>
                    <div className="overflow-y-auto">
                      <TableComp
                        isCursorDesativated={true}
                        filterActivated={false}
                        rows={rowsPublished(publishedRows, "Published")}
                        colHeaders={colHeaders("Published")}
                        className="h-full w-full overflow-x-hidden the-table-comp"
                      />
                    </div>
                  </div>
                )}

                {noAccessRows.length > 0 && (
                  <div className="m-4 flex flex-col">
                    <h1 className="font-medium text-xl">
                      {t("pages.projectGroup.publish.noAccessPublish")}
                    </h1>

                    <div className="overflow-y-auto">
                      <TableComp
                        isCursorDesativated={true}
                        filterActivated={false}
                        rows={rowsPublishWaiting(noAccessRows, "NoAccess")}
                        colHeaders={colHeaders("NoAccess")}
                        className="h-full w-full overflow-x-hidden the-table-comp"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <div className="h-72 space-y-11">
                <PlaceholderWithIcon
                  title={t("pages.projectGroup.publish.noBuilds")}
                  text={t("pages.projectGroup.publish.noBuildsSubtitle")}
                  heroIcon="InformationCircleIcon"
                />
                <div className="flex justify-center items-center">
                  <NewPublicationButton />
                </div>
              </div>
            </div>
          )}
        </div>
      </IsLoading>
    </>
  );
};

export default ProjectGroupPublish;
