import * as React from "react";
import ModalStandard from "src/common/components/Modal/ModalStandard";
import { IMedia } from "src/model/unityObject";
import Player360 from "./Player360";
import Model3dViewerWeb from "./Model3dViewerWeb";
import { prettifyFileSizeInBytes } from "src/common/util/prettifyFileSizeInBytes";
import { ILanguage } from "src/model/model";
import { languageConfig } from "src/common/util/langConfig";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { prettifyMediaType } from "src/common/util/prettifyMediaType";
import { FiDownload } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface IModalPreviewProps {
  media: IMedia;
  onCloseCb(arg?: any): any;
}

export const ModalPreview: React.FC<IModalPreviewProps> = ({ media, onCloseCb }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const handleDownload = async () => {
    try {
      const response = await fetch(media.s3_url, { mode: "cors" });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = media.name || "downloaded_file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup the object URL after a short delay
      setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const MediaPreviewer = () => {
    if (
      (media.media_360_tag === "media360" || media.media_360_tag === "video180stereo") &&
      media.type !== "model3d"
    ) {
      return (
        <Player360
          mediaUrl={media.s3_url}
          mediaType={media.type as "image" | "video"}
          mediaTag360={media.media_360_tag as "media2d" | "media360" | "video180stereo"}
          className="w-full h-full object-contain rounded-2xl"
          canvasClassName="rounded-2xl"
          disableFullScreen
        />
      );
    }
    if (media.type === "image") {
      return <img className="object-contain w-full h-full" src={media.s3_url} alt="image-media" />;
    }
    if (media.type === "video") {
      return (
        <video controls autoPlay className="w-full h-full">
          <source src={media.s3_url} type="video/mp4" />
        </video>
      );
    }
    if (media.type === "audio") {
      return (
        <audio id="myAudio" className="w-full" controls autoPlay>
          <source src={media.s3_url} />
        </audio>
      );
    }
    if (media.type === "pdf") {
      return <p>coming soon(tm)</p>;
    }
    if (media.type === "model3d") {
      return (
        <Model3dViewerWeb
          modelUrl={media.s3_url}
          className="w-full h-[95%]"
          canvasClassName="rounded-2xl"
        />
      );
    }
    return (
      <img
        className="object-contain h-full w-full"
        src="https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/link_broken.png"
        alt="Broken link"
      />
    );
  };

  return (
    <ModalStandard
      id={`preview_${media.id}`}
      className="w-1/2 h-2/3 relative"
      onClose={onCloseCb}
      modalType="mediaPreview"
      overFlowOff
      showCloseButton
    >
      <div className="flex flex-col h-full">
        {/* Media Preview Container */}
        <div className="media-preview-container relative flex items-center justify-center flex-grow overflow-auto">
          <MediaPreviewer />
          {/* Download Button */}
          <button
            onClick={handleDownload}
            className="btn-primary-fill absolute bottom-4 right-4 flex items-center px-3 py-2"
          >
            <FiDownload className="mr-2" /> {t("general.download")}
          </button>
        </div>
        {/* Footer Section */}
        <div className="flex justify-between mb-4 mt-2 flex-shrink-0">
          <div className="flex">
            <p className="mr-12 text-sm">{media.name}</p>
            <p className="text-gray-500 text-sm">
              {prettifyFileSizeInBytes(Number(media.size_in_bytes), currentLocale)}
            </p>
          </div>
          <p className="text-gray-500 ">
            {prettifyMediaType({ type: media.type, media_360_tag: media.media_360_tag })}
          </p>
        </div>
      </div>
    </ModalStandard>
  );
};
