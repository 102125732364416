import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalStandard from "../Modal/ModalStandard";
import ModalCloseButton from "../Modal/ModalCloseButton";
import Spinner from "../Loader/Spinner";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { closeStandardModal } from "../../state/slice/modal/modalSlice";

const Avatar3dPopup = ({
  handleAvatar3dChange,
  currentUserAvatar3dUrl,
}: {
  handleAvatar3dChange: (url: string) => void;
  currentUserAvatar3dUrl: string;
}) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [currentUrl, setCurrentUrl] = useState<string>(currentUserAvatar3dUrl);

  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (
        typeof event.data === "string" &&
        event.data.startsWith("https://") &&
        event.data.endsWith(".glb") &&
        event.data !== currentUrl
      ) {
        const glbUrl = event.data;
        const pngUrl = glbUrl.replace(".glb", ".png");
        setCurrentUrl(pngUrl);
        handleAvatar3dChange(glbUrl);
        dispatch(closeStandardModal("modalEditAvatar3d"));
      }
    };

    window.addEventListener("message", receiveMessage);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [currentUrl, handleAvatar3dChange, dispatch]);

  const handleImageLoad = () => setIsLoading(false);

  return (
    <>
      <ModalStandard id="modalPreviewAvatar3d" fullScreen={false}>
        <div className="flex w-full">
          <div className="relative grow w-full max-w-7xl xl:px-0 mx-auto">
            <h2 className="pt-1 pb-6 font-medium text-lg">
              {t("pages.profile.main.previewAvatar.title")}
            </h2>
            <ModalCloseButton isAvatarField />

            {isLoading && (
              <div className="w-full h-12 completely-centered">
                <Spinner />
              </div>
            )}
            <img
              style={{ display: isLoading ? "none" : "block" }}
              className="inline object-cover w-full rounded-2xl"
              src={
                currentUrl.replace(".glb", ".png") ??
                "https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/link_broken.png"
              }
              onLoad={handleImageLoad}
              alt="Profile image"
            />
          </div>
        </div>
      </ModalStandard>

      <ModalStandard showCloseButton id="modalEditAvatar3d" fullScreen={false}>
        <div className="flex w-full">
          <div className="relative grow w-full max-w-7xl xl:px-0 mx-auto">
            <h2 className="pt-1 pb-6 font-medium text-lg">
              {t("pages.profile.main.avatar3dCreation.title")}
            </h2>
            <iframe
              id="inlineFrameExample"
              title="Avatar Creator"
              width="100%"
              height="500px"
              src="https://wixar.readyplayer.me/"
              allow="clipboard-write"
            ></iframe>
          </div>
        </div>
      </ModalStandard>
    </>
  );
};

export default Avatar3dPopup;
