import React, { useState } from "react";
import { useSelector } from "react-redux";
import { mediaSelector } from "../state/gallerySlice";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import SearchableDropdown from "src/common/components/SearchableDropdown/SearchableDropdown";

const BlockadeImageToImage = ({
  onSelect,
  onCancel,
}: {
  onSelect: (value: File | string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<string | null>(null);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState<string | null>(null);

  const media = useSelector(mediaSelector);

  const filteredMedia = media.filter((m) => m.media_360_tag === "media360" && m.type === "image");

  const handleGallerySelect = (selectedId: string | number) => {
    const selectedMedia = filteredMedia.find((media) => media.id === selectedId);
    if (selectedMedia) {
      setPreview(selectedMedia.s3_url);
      setSelectedGalleryImage(selectedMedia.s3_url);
      onSelect(selectedMedia.s3_url);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validExtensions = [".png", ".jpg", ".jpeg"];
      const fileName = file.name.toLowerCase();
      const isValidExtension = validExtensions.some((ext) => fileName.endsWith(ext));

      if (isValidExtension) {
        const fileUrl = URL.createObjectURL(file);
        setPreview(fileUrl);
        onSelect(file);
      } else {
        alert(t("general.extensionNotAcceptedImage"));
        handleCancel();
      }
    }
  };

  const handleCancel = () => {
    setPreview(null);
    setSelectedGalleryImage(null);
    onCancel();
  };

  return (
    <div>
      {preview ? (
        <div className="relative">
          <Tooltip title={t("general.cancel")}>
            <button
              onClick={handleCancel}
              className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center bg-gray-200 hover:bg-gray-300 rounded-full shadow-md"
              aria-label={t("pages.gallery.generateMediaPanel.closePreview")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </Tooltip>
          <img
            src={preview}
            alt={t("pages.gallery.generateMediaPanel.previewImageAlt")}
            className="mb-6 w-full max-h-64 object-cover"
          />
        </div>
      ) : (
        <div>
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <label htmlFor="gallery-select" className="block text-xs font-medium text-gray-600">
                {t("pages.gallery.generateMediaPanel.selectFromGallery")}
              </label>
              <SearchableDropdown
                options={filteredMedia.map((media) => ({
                  id: media.id,
                  name: media.name || t("pages.gallery.generateMediaPanel.unnamedImage"),
                }))}
                selectedId={
                  filteredMedia.find((media) => media.s3_url === selectedGalleryImage)?.id
                }
                onSelect={handleGallerySelect}
                placeholder={t("pages.gallery.generateMediaPanel.chooseImage")}
                searchPlaceholder={t("pages.gallery.generateMediaPanel.chooseImage")}
                noItemFound={t("pages.gallery.generateMediaPanel.noImageFound")}
              />
            </div>
            <div className="text-gray-500 font-medium text-xs">
              {t("pages.gallery.generateMediaPanel.or")}
            </div>
            <div className="flex-1">
              <label htmlFor="file-upload" className="block text-xs font-medium text-gray-600">
                {t("pages.gallery.generateMediaPanel.uploadFile")}
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".png,.jpg,.jpeg,image/png,image/jpeg"
                className="mt-1 block w-full border border-gray-300 rounded-lg p-2 text-xs"
                onChange={handleFileUpload}
              />
            </div>
          </div>
        </div>
      )}
      <hr className="mt-6 mb-4 w-4/5 mx-auto border-gray-300" />
    </div>
  );
};

export default BlockadeImageToImage;
