import {
  ChatAlt2Icon,
  ExternalLinkIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import Navigation from "../../common/components/Navigation/Navigation";
import { useTranslation } from "react-i18next";
import { MdBugReport } from "react-icons/md";
import { ZendeskAPI } from "react-zendesk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HelpCenter = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleRedirection = (url: string, isExternal = false) => {
    if (isExternal) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const [isChatOpen, setIsChatOpen] = useState(false);

  const chatClickHandler = () => {
    if (isChatOpen) {
      ZendeskAPI("webWidget", "hide");
      setIsChatOpen(false);
    } else {
      ZendeskAPI("webWidget", "show");
      ZendeskAPI("webWidget", "open");
      setIsChatOpen(true);

      ZendeskAPI("webWidget:on", "userEvent", function (userEvent: any) {
        if (userEvent.action === "Web Widget Minimised") {
          ZendeskAPI("webWidget", "hide");
          setIsChatOpen(false);
        }
      });
    }
  };

  const documentationLink =
    i18n.language === "en"
      ? "https://wixar.zendesk.com/hc/fr/categories/4402855477138-WiXar-User-Guide-EN"
      : "https://wixar.zendesk.com/hc/fr/categories/360002877319-Guide-d-utilisation-de-WiXar-FR";

  const customizeZendeskPosition = () => {
    ZendeskAPI("webWidget", "updateSettings", {
      webWidget: {
        position: {
          horizontal: "right",
          vertical: "bottom",
        },
      },
    });
  };

  useEffect(() => {
    customizeZendeskPosition();
  }, []);

  return (
    <>
      <Navigation title={t("sidebar.helpCenter")} />
      <div>
        <h2 className="text-2xl font-medium mt-10 ml-5">{t("pages.helpCenter.howCanWeHelp")}</h2>
      </div>
      <div className="flex items-center justify-center bg-gray-100">
        <div className="w-full max-w-lg space-y-2 1536px:space-y-5 mt-10">
          <button
            className="w-full h-25 min-h-[100px] flex justify-between items-center p-5 rounded-lg hover:bg-[#e6e6e6] transition"
            onClick={() => handleRedirection(documentationLink, true)}
          >
            <div className="flex items-center">
              <InformationCircleIcon className="w-8 h-8 stroke-green-500 mr-3" />
              <div className="text-left">
                <h2 className="text-lg font-medium">{t("pages.helpCenter.documentation")}</h2>
                <p className="text-gray-500">{t("pages.helpCenter.documentationText")}</p>
              </div>
            </div>
            <ExternalLinkIcon className="w-5 h-5 stroke-gray-500" />
          </button>

          <button
            className="w-full flex h-25 min-h-[100px] justify-between items-center p-5 rounded-lg hover:bg-[#e6e6e6] transition"
            onClick={() => handleRedirection("/chat/wixarpedia")}
          >
            <div className="flex items-center">
              <ChatAlt2Icon className="w-10 h-10 stroke-green-500 mr-3" />
              <div className="text-left">
                <h2 className="text-lg font-medium">{t("pages.helpCenter.chatSupport")}</h2>
                <p className="text-gray-500">{t("pages.helpCenter.chatText")}</p>
              </div>
            </div>
          </button>

          <button
            className="w-full h-25 flex min-h-[100px] justify-between items-center p-5 rounded-lg hover:bg-[#e6e6e6] transition"
            onClick={() =>
              handleRedirection("https://wixar.atlassian.net/servicedesk/customer/portal/2", true)
            }
          >
            <div className="flex items-center">
              <MdBugReport className="w-8 h-8 text-green-500 mr-3" />
              <div className="text-left">
                <h2 className="text-lg font-medium">{t("pages.helpCenter.reportBug")}</h2>
                <p className="text-gray-500">{t("pages.helpCenter.reportBugText")}</p>
              </div>
            </div>
            <ExternalLinkIcon className="w-5 h-5 stroke-gray-500" />
          </button>
          <button
            className="w-full h-25 flex min-h-[100px] justify-between items-center p-5 rounded-lg hover:bg-[#e6e6e6] transition"
            onClick={chatClickHandler}
          >
            <div className="flex items-center">
              <UserGroupIcon className="w-8 h-8 stroke-green-500 mr-3" />
              <div className="text-left">
                <h2 className="text-lg font-medium">{t("pages.helpCenter.contactUs")}</h2>
                <p className="text-gray-500">{t("pages.helpCenter.contactText")}</p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
