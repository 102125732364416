import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

interface DropdownOption {
  id: string | number;
  name: string;
}

interface SearchableDropdownProps {
  options: DropdownOption[];
  selectedId?: string | number;
  onSelect: (selectedId: string | number) => void;
  placeholder?: string;
  searchPlaceholder: string;
  noItemFound: string;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  selectedId,
  onSelect,
  placeholder,
  searchPlaceholder,
  noItemFound,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const getDropdownStyles = () => {
    if (!triggerRef.current) return {};
    const rect = triggerRef.current.getBoundingClientRect();
    return {
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
    };
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const selectedOption = options.find((option) => Number(option.id) === Number(selectedId));

  const DropdownList = (
    <div
      ref={dropdownRef}
      style={getDropdownStyles()}
      className="absolute border rounded bg-white shadow-lg z-50 max-h-60 overflow-y-auto"
    >
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={searchPlaceholder}
        className="border-b px-3 py-2 w-full focus:outline-none"
      />
      <ul>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option) => (
            <li
              key={option.id}
              onClick={() => {
                onSelect(option.id);
                setIsOpen(false);
              }}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer truncate"
              title={option?.name}
            >
              {option?.name}
            </li>
          ))
        ) : (
          <li className="px-3 py-2 text-gray-500">{noItemFound}</li>
        )}
      </ul>
    </div>
  );

  return (
    <div className="relative w-full">
      <div
        ref={triggerRef}
        className="border rounded px-3 py-2 bg-white cursor-pointer flex justify-between items-center w-full text-sm"
        onClick={() => setIsOpen((prev) => !prev)}
        style={{
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,<svg width='12' height='12' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='rgb(0, 200, 137)' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/></svg>\")",
          backgroundPosition: "right 0.5rem top 1rem",
          backgroundSize: "1.5em 1.5em",
          backgroundRepeat: "no-repeat",
        }}
      >
        <span className="truncate">
          {selectedOption ? selectedOption.name : placeholder || t("general.selectAnItem")}
        </span>
      </div>
      {isOpen && createPortal(DropdownList, document.body)}
    </div>
  );
};

export default SearchableDropdown;
