import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  deleteTheme,
  getAllProjectGroupsWithNestedElements,
  updateProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { deleteThemeInStore, fetchThemes, getThemes } from "src/features/themes/state/themeSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { useEffect, useState } from "react";
import { IThemeInfo } from "src/model/model";
import ModalThemeEditor from "src/features/themes/components/ModalThemeEditor";
import {
  closeConfirmationModal,
  closeStandardModal,
  openConfirmationModal,
  openStandardModal,
} from "src/common/state/slice/modal/modalSlice";
import ModalConfirmation from "src/common/components/Modal/ModalConfirmation";
import { setAlert } from "src/features/alert/state/alertsSlice";
import ThemeItem from "src/features/themes/components/ThemeItem";
import ThemesView from "./ThemesView";
import AssignThemeToProjectGroup from "./AssignThemeToProjectGroup";

const CHOOSE_THEME_COMPONENT = "chooseThemeComponent";
const ThemesList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const themes = useSelector(getThemes);
  const projectGroups = useSelector(getAllProjectGroupsWithNestedElements);

  const [publicThemes, setPublicThemes] = useState<IThemeInfo[]>([]);
  const [privatesThemes, setPrivatesThemes] = useState<IThemeInfo[]>([]);
  const [selectedThemeId, setSelectedThemeId] = useState<number>(0); // themeId < 1 -> new theme
  const [themeToDeleteId, setThemeToDeleteId] = useState<number>(-1);

  const [createthemeWithThisBase, setCreatethemeWithThisBase] = useState<boolean>(false);

  useEffect(() => {
    dispatch(closeStandardModal("modalThemeEditor"));
    dispatch(closeConfirmationModal("deleteThemeModal"));
    dispatch(fetchThemes({ componentId: CHOOSE_THEME_COMPONENT }));
  }, []);

  useEffect(() => {
    const pubThemes: IThemeInfo[] = [];
    const priThemes: IThemeInfo[] = [];
    if (themes) {
      themes?.forEach((elt) => {
        if (Number(elt.organization?.id) === 3) {
          pubThemes.push(elt);
        } else {
          priThemes.push(elt);
        }
      });

      setPublicThemes(pubThemes.sort((a, b) => a.id - b.id));
      setPrivatesThemes(priThemes);
    }
  }, [themes]);

  const onThemeCardClick = (theme: IThemeInfo) => {
    if (
      Number(theme?.organization.id) !== 3 // WIXAR-4017: end communist management of collective themes.
    ) {
      setCreatethemeWithThisBase(false);
      setSelectedThemeId(theme.id);
      dispatch(openStandardModal("modalThemeEditor"));
    }
  };

  const onNewThemeClick = () => {
    setCreatethemeWithThisBase(false);
    setSelectedThemeId(0);
    dispatch(openStandardModal("modalThemeEditor"));
  };

  const onConfirmModalThemeEditor = (themeId: number) => {
    setSelectedThemeId(themeId);
    dispatch(closeStandardModal("modalThemeEditor"));
    dispatch(fetchThemes({ componentId: CHOOSE_THEME_COMPONENT }));
  };

  const onConfirmDeleteTheme = () => {
    dispatch(closeConfirmationModal("deleteThemeModal"));

    dispatch(deleteTheme({ themeId: themeToDeleteId, componentId: CHOOSE_THEME_COMPONENT }))
      .then(() => {
        const projectGroupsToUpdate = projectGroups.filter(
          (group) =>
            group.theme_info &&
            (typeof group.theme_info === "object"
              ? group.theme_info.id === themeToDeleteId
              : group.theme_info === themeToDeleteId),
        );

        Promise.all(
          projectGroupsToUpdate.map(
            (group) => dispatch(updateProjectGroup({ id: group.id, payload: { theme_info: 1 } })), // when a theme is deleted, we assign the default theme
          ),
        );

        dispatch(deleteThemeInStore({ themeId: themeToDeleteId }));
        setThemeToDeleteId(-1);
        dispatch(setAlert({ msg: t("general.successDeleteTheme"), type: "success" }));
      })
      .catch(() => {
        dispatch(setAlert({ msg: t("general.errorDeleteTheme"), type: "error" }));
      });
  };

  const handleOnThemeMenuClick = (item: any, _index: number) => {
    if (item.itemId === "createthemeWithThisBase") {
      setCreatethemeWithThisBase(true);
      setSelectedThemeId(item.themeId);
      dispatch(openStandardModal("modalThemeEditor"));
    }
    if (item.itemId === "editTheme") {
      setCreatethemeWithThisBase(false);
      setSelectedThemeId(item.themeId);
      dispatch(openStandardModal("modalThemeEditor"));
    }
    if (item.itemId === "deleteTheme") {
      setCreatethemeWithThisBase(false);
      setThemeToDeleteId(item.themeId);
      dispatch(openConfirmationModal("deleteThemeModal"));
    }
    if (item.itemId === "assignToProjectGroup") {
      setSelectedThemeId(item.themeId);
      dispatch(openStandardModal("assignToProjectGroupModal"));
    }
  };

  const adaptedProjectGroups = projectGroups.map((group) => ({
    ...group,
    theme_info: typeof group.theme_info === "object" ? group.theme_info?.id : group.theme_info,
  }));

  return (
    <div className="w-full overflow-auto h-full personalize-scroll-visible">
      <IsLoading componentId={CHOOSE_THEME_COMPONENT} showSuccess={false}>
        <ThemesView
          publicThemes={publicThemes}
          privatesThemes={privatesThemes}
          onNewThemeClick={onNewThemeClick}
          onThemeClick={onThemeCardClick}
          onThemeMenuClick={handleOnThemeMenuClick}
          componentType="list"
          selectedThemeId={selectedThemeId}
          setSelectedThemeId={setSelectedThemeId}
          ThemeItemComponent={ThemeItem}
        />
        <ModalThemeEditor
          title={
            createthemeWithThisBase
              ? t("pages.themeEditor.createthemeWithThisBase")
              : selectedThemeId === 0
              ? t("pages.themeEditor.create")
              : t("pages.themeEditor.edit")
          }
          themeId={selectedThemeId}
          createthemeWithThisBase={createthemeWithThisBase}
          onConfirm={() => onConfirmModalThemeEditor(selectedThemeId)}
        />
        <ModalConfirmation
          title={t("general.confirmTitleDeleteTheme")}
          onClickSubmit={onConfirmDeleteTheme}
          id="deleteThemeModal"
        >
          {t("general.confirmBodyDeleteTheme")}
        </ModalConfirmation>
        <AssignThemeToProjectGroup
          projectGroups={adaptedProjectGroups}
          selectedThemeId={selectedThemeId}
          onConfirm={() => {
            dispatch(closeStandardModal("assignToProjectGroupModal"));
          }}
        />
      </IsLoading>
    </div>
  );
};

export default ThemesList;
