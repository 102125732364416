import { useEffect, useRef, useState } from "react";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import ModernGenericTable from "src/common/components/ModernGenericTable/ModernGenericTable";
import { ILanguage } from "src/features/languages/state/languagesSlice";
import { useTranslation } from "react-i18next";
import Icon from "src/common/components/Icon/Icon";
import AskUsForAdditionalLanguages from "./AskUsForAdditionalLanguages";

interface ModernLanguageSelectorProps {
  rows?: ILanguage[];
  selectedLanguages: string[]; // Using string IDs
  setSelectedLanguages?: (selectedIds: string[]) => void;
}

export const MODERN_LANGUAGE_SELECTOR_COMPONENT_ID = "modern-language-selector";

const ModernLanguageSelector = ({
  rows = [],
  selectedLanguages,
  setSelectedLanguages = () => null,
}: ModernLanguageSelectorProps) => {
  const { t } = useTranslation();
  const [tableHeight, setTableHeight] = useState(0);
  const [showAddLanguages, setShowAddLanguages] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const transformedRows = rows.map((language) => ({
    id: language.id.toString(),
    Name: t(`general.languages.IETF_BCP_47.${language.IETF_BCP_47}`),
    "ISO 639": language.ISO_639_1,
  }));

  useEffect(() => {
    const calculateHeight = () => setTableHeight(Math.round(window.innerHeight * 0.5));
    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  const handleSelectionChange = (selectedRowIds: (string | number)[]) => {
    // Ensure all ids are strings
    const selected = selectedRowIds.map((id) => id.toString());
    console.log("ModernLanguageSelector - Selected IDs:", selected);
    setSelectedLanguages(selected);
  };

  const isoRenderer = (row: any) => (
    <span style={{ fontStyle: "italic", color: "gray" }}>{row["ISO 639"]}</span>
  );
  return (
    <IsLoading
      componentId={MODERN_LANGUAGE_SELECTOR_COMPONENT_ID}
      spinnerPlaceholder
      showSuccess={false}
    >
      <div className="flex flex-row">
        <div className="w-8/12">
          <ModernGenericTable
            key={tableHeight}
            maxTableHeightPx={tableHeight}
            rows={transformedRows}
            colHeaders={["Name", "ISO 639"]}
            className="h-full overflow-x-hidden the-table-comp w-[85%]"
            ref={tableContainerRef}
            checkAll={true}
            showSearchBar={true}
            onSelectionChange={handleSelectionChange}
            selectedRowIds={selectedLanguages}
            nonDeselectableRowIds={[selectedLanguages[0]]}
            customCellRenderers={{ "ISO 639": isoRenderer }}
            columnAlignments={{ "ISO 639": "center" }}
            showCheckboxesForUnselected={showAddLanguages}
          />
          {!showAddLanguages && (
            <div className="flex flex-row cursor-pointer items-center m-4">
              <Icon icon="PlusIcon" height={20} width={20} className="stroke-green-500 mr-1" />
              <p
                onClick={() => setShowAddLanguages(true)}
                className="text-green-500 hover:text-green-400 font-bold cursor-pointer"
              >
                {t("pages.editProjectLanguage.addLanguages")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center w-4/12">
          <AskUsForAdditionalLanguages />
        </div>
      </div>
    </IsLoading>
  );
};

export default ModernLanguageSelector;
