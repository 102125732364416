import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  currentOrgSelector,
  currentUserSelector,
  jwtSelector,
} from "../state/selectors/authSelector";

import { AppDispatch, RootState } from "../state/store";
import { ES, InitEventSource, IEventMessage } from "../util/EventSource";

import CreatorDashboard from "../../pages/CreatorDashboard/CreatorDashboard";
import { EULA } from "../../pages/EULA";
import { Help } from "../../pages/Help";
import { Login, ForgottenPassword } from "../../pages/Login";
import { DownloadClient } from "../../pages/DownloadClient";

import Layout from "./Layout";
import { ProjectsList, Templates, Main, ProjectEdit } from "../../pages/Projects";

import ResetPassword from "../../pages/Login/ResetPassword";
import {
  StatisticsDetail,
  StatisticsParticipants,
  StatisticsWrapper,
  Statistics,
} from "../../pages/Statistics";
import { Organization, ProfileMain, ProfileWrapper, Subscription } from "../../pages/Profile";

import { NodeScreen, ProjectGraph } from "../../pages/Projects/ProjectGraph";
import {
  //getEditorWebLastCommit,
  logoutAuth,
} from "../../features/auth/state/authSlice";
import { BackgroundMusic } from "../../features/graph/components/BackgroundMusic";
import { ProjectGroupsList } from "../../pages/ProjectGroups";
import CreateProjectGroupWrapper from "../../pages/ProjectGroups/CreateProjectGroupWrapper";
import CreateProjectWrapper from "../../pages/Projects/CreateProjectWrapper";
import ProjectGroupEdit from "../../pages/ProjectGroups/ProjectGroupEdit";
import ProjectGroupPublish, {
  PROJECT_GROUP_PUBLISH_COMPONENT_ID,
} from "../../pages/ProjectGroups/ProjectGroupPublish";
import ProjectGroupInformation from "../../pages/ProjectGroups/ProjectGroupInformation";
import ProjectInfo from "../../pages/Projects/ProjectInformation";

import AskPublicationWrapper from "../../features/projectGroupPublication/components/AskPublicationWrapper";
import { CreateNewScene } from "../../features/graph/components/CreateNewScene";
import EditProjectNameWrapper from "../../pages/Projects/EditProjectNameWrapper";
import FaitTemplateWrapper from "../../features/faitTemplate/components/FaitTemplateWrapper";
import EditProjectGroupNameWrapper from "../../pages/ProjectGroups/EditProjectGroupNameWrapper";
import {
  fetchAnonymousSessionsCount,
  fetchPaEmbedLinks,
  fetchSubscription,
  getSubscriptionStatus,
  setSubscriptionState,
} from "../../features/subscription/state/subscriptionSlice";
import NoSubscriptionHome from "../../pages/NoSubscriptionHome/NoSubscriptionHome";
import ErrorView from "../components/ErrorBoundary/ErrorView";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import { clearAlerts } from "../../features/alert/state/alertsSlice";
import { Gallery } from "../../features/gallery/Gallery";
import ZendeskChat from "../components/ZendeskChat/ZendeskChat";
import { MobileDashboard } from "../../mobilePages/MobileDashboard";
import { MobileUploadSelectedFile } from "../../mobilePages/MobileUploadSelectedFile";
import { MobileUploadsList } from "../../mobilePages/MobileUploadsList";
import MobileLayout from "./MobileLayout";
import EnduserDashboard from "../../enduserPages/EnduserDashboard/EnduserDashboard";
import EnduserProjectGroupsList from "../../enduserPages/EnduserProjectGroupsList/EnduserProjectGroupsList";
import EnduserProjectsList from "../../enduserPages/EnduserProjectsList/EnduserProjectsList";
import ProjectPreview from "../components/ProjectPreview/ProjectPreview";
import { isStringNullOrEmpty } from "../util/isStringNullOrEmpty";
import ProjectParticipants from "../../pages/Projects/ProjectParticipants";
import ProjectGroupCollaborators from "../../pages/ProjectGroups/ProjectGroupCollaborators";
import SelectPlatformsStep from "../../features/projectGroupPublication/components/SelectPlatformsStep";
import PublishNewVersion from "../../features/projectGroupPublication/components/PublishNewVersion";
import { getFromLocalStorage, storeInLocalStorage } from "../util/localStorage";
import detectIfMobileBrowser from "../util/DetectIfMobileBrowser";
import { GlobalModals } from "../components/GlobalModals/GlobalModals";
import { productionMode } from "./App";
import { fetchBuilds } from "src/features/builds/state/buildsSlice";

import {
  CollaboratorsList,
  CollaboratorsWrapper,
  CreateCollaborator,
  UpdateCollaborator,
} from "src/pages/Collaborators";
import {
  CreateParticipant,
  NewParticipants,
  ParticipantsCsvImport,
  ParticipantsList,
  ParticipantsWrapper,
  UpdateParticipant,
} from "src/pages/Participants";
import { getMedia, setBlockadeLabsImagePreview } from "src/features/gallery/state/gallerySlice";
import { getAllSessionsByProjectGroup } from "src/features/stats/state/statsSlice";
import { STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID } from "src/features/stats/components/StatsOverview/StatsOverview";
import { getAllProjects, setCurrentProjectFromId } from "src/features/projects/state/projectsSlice";
import TemplatesWrapper from "src/pages/Templates/TemplatesWrapper";
import TemplatesList from "src/pages/Templates/TemplatesList";
import ThemesWrapper from "src/pages/Themes/ThemesWrapper";
import ThemesList from "src/pages/Themes/ThemesList";
import SpecificTemplateWrapper from "src/pages/Templates/SpecificTemplateWrapper";
import ChatWrapper from "src/pages/Chat/ChatWrapper";
import ScenarioHelperChat from "src/pages/Chat/ScenarioHelperChat";
import WixarpediaChat from "src/pages/Chat/WixarpediaChat";
import { ANONYMOUS_SESSION_COUNTER_COMPONENT } from "src/features/subscription/components/AnonymousSessionsCounter";
import EditProjectGroupThemeWrapper from "src/pages/ProjectGroups/EditProjectGroupThemeWrapper";
import EditProjectGroupThumbnailWrapper from "src/pages/ProjectGroups/EditProjectGroupThumbnailWrapper";
import EditProjectGroupImageWrapper from "src/pages/ProjectGroups/EditProjectGroupImageWrapper";
import EditProjectThumbnailWrapper from "src/pages/Projects/EditProjectThumbnailWrapper";
import EditProjectLanguageWrapper from "src/pages/Projects/EditProjectLanguageWrapper";
import HelpCenter from "src/pages/Help/HelpCenter";

const HEARTBEAT_TIMEOUT_MS = 30000;

const Router = () => {
  const jwt = useSelector(jwtSelector);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const subscriptionStatus = useSelector(getSubscriptionStatus);
  // const isAdmin = useSelector(getIsAdminOfOrganization);
  const isSubscriptionRunning = subscriptionStatus === "Active";

  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  let lastHeartbeat = Date.now();

  // TODO: handle Mobile-landscape separately for style purposes
  // const [isInLandscapeMode, setLandscapeMode] = useState(
  //   window.matchMedia("(orientation: landscape)").matches,
  // );

  const isMobile = detectIfMobileBrowser();

  const navigate = useNavigate();
  useEffect(() => {
    const checkHeartbeat = () => {
      if (Date.now() - lastHeartbeat > HEARTBEAT_TIMEOUT_MS && jwt) {
        // This started from good intentions, but commenting it to troubleshoot the "savage" redirections to home (WIXAR-3303)
        // window.location.reload();
        // console.log("Nyahalo. I would've entered the window.location.reload() block");
      }

      setTimeout(() => {
        checkHeartbeat();
      }, 10000);
    };

    checkHeartbeat();

    dispatch(clearAlerts());
    if (jwt) {
      navigate("/");
    }
  }, []);

  // If signed-in, check EventSource existence & initialize if connection isn't open. This handles refresh
  if (jwt) {
    if (
      (ES.OPEN as any) === 0 || // readyState: connecting
      (ES.OPEN as any) === 2 || // readyState: closed
      !ES
    ) {
      InitEventSource(jwt);
    }
  }

  const { user, organization } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      organization: currentOrgSelector(state),
    };
  });

  const checkNewCommit = (lastCom: string) => {
    const currentCommitHash = getFromLocalStorage("CURRENT_COMMIT_HASH");
    if (lastCom !== currentCommitHash && !isStringNullOrEmpty(lastCom)) {
      storeInLocalStorage("CURRENT_COMMIT_HASH", lastCom);
      window.location.href = `${window.location.origin}/?nocache=${new Date().getTime()}`;
    }
  };

  useEffect(() => {
    // https://medium.com/trabe/server-sent-events-sse-streams-with-node-and-koa-d9330677f0bf
    ES.onopen = () => {
      //
    };
    ES.onerror = (e: any) => {
      console.error("SSE error: ", e);
    };
    ES.onmessage = (event: any) => {
      const eventMessage = JSON.parse(event.data) as IEventMessage;
      const currentUserId = Number(user?.id);

      const disconnect = () => {
        alert(`${t("disconnected")}`);
        dispatch(logoutAuth());
      };

      if (!productionMode && eventMessage.action !== "001_HEARTBEAT") {
        console.warn("SSE received: ", eventMessage);
      }

      /*
        SSE CODE:
          - 0** : PING
          - 1** : LOGIN/LOGOUT
          - 2** : DATABASE INSERT
          - 3** : DATABASE UPDATE
          - 4** : DATABASE DELETE
      */
      switch (eventMessage.action) {
        case "101_DISCONNECT_ONE": {
          const environmentId = eventMessage.issuedFromEnv;

          if (Number(eventMessage?.issuedFromUser) === currentUserId) {
            if (
              Number(environmentId) === 2 && // hardcoded-value for environment.id 2 (should be "WebApp" in DB)
              !isMobile
            ) {
              // Someone complained about the good old alert() being ugly, and they attributed the JIRA ticket to YOU ?
              // Oh my sweet summer child. Before 'prettifying' it, please read this (and the comments)
              // https://css-tricks.com/evil-please-dont-modals-overlays-edition/
              // Good luck :)

              // 8 Novembre 2023: cette feature est trop clunky, je la désactive le temps du rework SSE (redis)
              // console.log("I should call ", disconnect);
              disconnect();
            }
            if (Number(environmentId) === 4 && isMobile) {
              // hardcoded-value for environment.id 4 (should be "WebAppMobile" in DB)
              // uncomment this once dev is done
              // disconnect();
            }
          }
          return null;
        }

        case "103_SUBSCRIPTION_EXPIRED": {
          dispatch(setSubscriptionState("Terminated"));
          return null;
        }

        case "301_TRANSCODED_MEDIA": {
          const mediaId = eventMessage.targetObjects[0].id as number;
          if (Number(eventMessage?.issuedFromUser) === Number(currentUserId)) {
            dispatch(getMedia({ mediaId }));
          }
          return null;
        }

        case "305_UPDATED_PROJECT": {
          dispatch(getAllProjects({ componentId: "projectListComponent" })).then(() => {
            dispatch(setCurrentProjectFromId(Number(eventMessage.targetObjects[0].id)));
          });

          return null;
        }

        case "001_HEARTBEAT": {
          lastHeartbeat = Date.now();
          return null;
        }

        case "201_NEW_WEB_RELEASE": {
          checkNewCommit((eventMessage.targetObjects[0] as any).commit_hash);
          return null;
        }

        case "304_UPDATED_BUILD_STATUS": {
          dispatch(fetchBuilds({ componentId: PROJECT_GROUP_PUBLISH_COMPONENT_ID }));
          dispatch(
            getAllSessionsByProjectGroup({
              componentId: STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID,
            }),
          );
          return null;
        }
        case "331_BLOCKADE_LAB_GENERATION_COMPLETED": {
          const data = eventMessage.targetObjects[0] as any;
          dispatch(setBlockadeLabsImagePreview(data));
          return null;
        }
        case "332_BLOCKADE_LAB_GENERATION_ERROR": {
          const data = eventMessage.targetObjects[0] as any;
          dispatch(setBlockadeLabsImagePreview(data));
          return null;
        }

        case "307_UPDATE_PROABONO_SUBSCRIPTION": {
          const data = eventMessage.targetObjects[0] as any;
          const orgId = data.id;
          if (Number(orgId) === Number(organization.id)) {
            dispatch(fetchPaEmbedLinks({}));
            dispatch(
              fetchAnonymousSessionsCount({ componentId: ANONYMOUS_SESSION_COUNTER_COMPONENT }),
            );
          }
          return null;
        }

        default:
          return null;
      }
    };
  }, [user?.id]);

  useEffect(() => {
    if (jwt) {
      dispatch(fetchSubscription({}));
      // dispatch(getEditorWebLastCommit()).then((res) => {
      //   const lastCom = (res as any).payload?.commitHash;
      //   checkNewCommit(lastCom);
      // });
    }
  }, [jwt]);

  useEffect(() => {
    // const appIsInLandscapeMode = window.matchMedia("(orientation: landscape)");
    // setLandscapeMode(appIsInLandscapeMode.matches);

    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenSize.height]);

  const lstorage = localStorage.getItem("wixar.auth.jwt");
  useEffect(() => {
    if (isStringNullOrEmpty(lstorage)) {
      dispatch(logoutAuth());
    }
  }, [lstorage]);

  const renderTheAppropriateRouter = () => {
    /* We have several cases to handle: 
    - User is not subscribed
    - User is subscribed & hasn't accepted EULA
    - User is subscribed, has accepted EULA and is on Mobile device
    - User is subscribed, has accepted EULA and is on Mobile device
    */

    /* Case: user is not subscribed */
    if (user && !isSubscriptionRunning) {
      return (
        <>
          <Routes>
            <Route index element={<NoSubscriptionHome />} />
            <Route path="*" element={<Navigate to={""} />} />
          </Routes>
        </>
      );
    }

    if (user && !user?.has_accepted_cgv) {
      return (
        <Routes>
          <Route index element={<EULA />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgottenpassword" element={<ForgottenPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      );
    }

    if (user?.role.name === "Enduser") {
      return (
        <ErrorBoundary currentUserId={Number(user?.id)}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<EnduserDashboard />} />

              <Route path="profile" element={<ProfileWrapper enduser />}>
                <Route index element={<ProfileMain />} />
                <Route path="me" element={<ProfileMain />} />
                <Route path="organization" element={<Organization />} />
              </Route>

              <Route path="courses" element={<EnduserProjectGroupsList />} />
              <Route path="courses/:id" element={<EnduserProjectsList />}>
                <Route index element={<EnduserProjectsList />} />
                <Route path="list" element={<EnduserProjectsList />} />
                <Route path="todo" element={<EnduserProjectsList />} />
                <Route path="pending" element={<EnduserProjectsList />} />
                <Route path="completed" element={<EnduserProjectsList />} />
              </Route>

              <Route path="download-client" element={<DownloadClient />} />
              <Route path="/error" element={<ErrorView />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Theres nothing here!</p>
                  </main>
                }
              />
            </Route>
          </Routes>
        </ErrorBoundary>
      );
    } else if (
      /* Case: user is subscribed & on Mobile screen, portrait mode */
      isMobile
      //&& !isInLandscapeMode
    ) {
      return (
        <>
          <ErrorBoundary currentUserId={Number(user?.id)}>
            <Routes>
              <Route path="/login" element={<Login mobile={isMobile} />} />
              <Route path="/forgottenpassword" element={<ForgottenPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/" element={<MobileLayout screenSize={screenSize} />}>
                <Route index element={<MobileDashboard />} />
                <Route path="/upload" element={<MobileUploadSelectedFile />} />
                <Route path="/uploads" element={<MobileUploadsList />} />
              </Route>
            </Routes>
          </ErrorBoundary>
          <ZendeskChat />
        </>
      );
      /* TODO: user is subscribed & on Mobile screen, landscape mode */
    } else {
      /* Case: user is subscribed & on Desktop screen */
      return (
        <>
          <ErrorBoundary currentUserId={Number(user?.id)}>
            <Routes location={location} key={location.pathname}>
              <Route path="/login" element={<Login />} />
              <Route path="/forgottenpassword" element={<ForgottenPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/" element={<Layout />}>
                <Route index element={<CreatorDashboard />} />

                <Route path="courses" element={<ProjectGroupsList />} />
                <Route path="courses/:id" element={<ProjectGroupEdit />}>
                  <Route path="list" element={<ProjectsList />} />
                  <Route path="preview" element={<ProjectPreview />} />
                  <Route path="publish" element={<ProjectGroupPublish />} />
                  <Route path="information" element={<ProjectGroupInformation />} />
                  <Route path="publish/new" element={<AskPublicationWrapper />}>
                    <Route
                      path="platforms"
                      element={<SelectPlatformsStep isTheOnlyPublishingStep />}
                    />
                    <Route path="version" element={<PublishNewVersion />} />
                  </Route>
                  <Route path="team" element={<ProjectGroupCollaborators />} />
                </Route>

                <Route path="courses/new" element={<CreateProjectGroupWrapper />} />
                <Route path="courses/:id/edit" element={<EditProjectGroupNameWrapper />} />
                <Route path="courses/:id/edit-name" element={<EditProjectGroupNameWrapper />} />
                <Route
                  path="courses/:id/edit-thumbnail"
                  element={<EditProjectGroupThumbnailWrapper />}
                />
                <Route path="courses/:id/edit-image" element={<EditProjectGroupImageWrapper />} />
                <Route path="courses/:id/edit-theme" element={<EditProjectGroupThemeWrapper />} />
                <Route path="courses/:id/new" element={<Templates />} />
                <Route path="courses/:id/new/:templateId" element={<CreateProjectWrapper />} />
                <Route path="courses/:id/list/:projectId" element={<Main />}>
                  <Route index element={<ProjectEdit />} />
                  <Route path="graph" element={<ProjectGraph />} />
                  <Route path="preview" element={<ProjectPreview />} />
                  <Route path="information" element={<ProjectInfo />} />
                  <Route
                    path="graph/background-music/edit"
                    element={<BackgroundMusic mode="edit" />}
                  />
                  <Route path="graph/node/:contentType/:nodeId" element={<NodeScreen />} />
                  <Route path="graph/create/scene" element={<CreateNewScene />} />
                  <Route path="learners" element={<ProjectParticipants />} />
                </Route>
                <Route
                  path="courses/:id/list/:projectId/edit"
                  element={<EditProjectNameWrapper />}
                />
                <Route
                  path="courses/:id/list/:projectId/edit-name"
                  element={<EditProjectNameWrapper />}
                />
                <Route
                  path="courses/:id/list/:projectId/edit-language"
                  element={<EditProjectLanguageWrapper mode="edit-language" />}
                />
                <Route
                  path="courses/:id/list/:projectId/edit-thumbnail"
                  element={<EditProjectThumbnailWrapper />}
                />
                <Route path="courses/:id/template/:projectId" element={<FaitTemplateWrapper />} />

                <Route path="statistics" element={<StatisticsWrapper />}>
                  <Route index element={<Statistics />} />
                  <Route path="group" element={<Statistics />} />
                  <Route path="learners" element={<StatisticsParticipants />} />
                  <Route path="learners/:id" element={<StatisticsDetail />} />
                </Route>
                <Route path="profile" element={<ProfileWrapper />}>
                  <Route index element={<ProfileMain />} />
                  <Route path="me" element={<ProfileMain />} />
                  <Route path="organization" element={<Organization />} />
                  <Route path="subscription" element={<Subscription />} />
                </Route>

                <Route path="download-client" element={<DownloadClient />} />
                <Route path="help" element={<Help />} />
                <Route path="help-center" element={<HelpCenter />} />
                <Route path="medias" element={<Gallery />} />
                <Route path="chat" element={<ChatWrapper />}>
                  <Route path="scenario-helper" element={<ScenarioHelperChat />} />
                  <Route path="wixarpedia" element={<WixarpediaChat />} />
                </Route>

                <Route path="team" element={<CollaboratorsWrapper />}>
                  <Route index element={<CollaboratorsList />} />
                  <Route path="list" element={<CollaboratorsList />} />
                  <Route path=":id" element={<UpdateCollaborator />} />
                  <Route path="new" element={<CreateCollaborator />} />
                  {/* For now there is no CollaboratorsCsvImport
									<Route path="new/invite" element={CreateCollaborator />} />
                  <Route path="new/csv-import" element={<CollaboratorsCsvImport />} /> */}
                </Route>
                <Route path="learners" element={<ParticipantsWrapper />}>
                  <Route index element={<ParticipantsList />} />
                  <Route path="list" element={<ParticipantsList />} />
                  <Route path=":id" element={<UpdateParticipant />} />
                  <Route path="new" element={<NewParticipants />} />
                  <Route path="new/invite-authenticated" element={<CreateParticipant />} />
                  <Route
                    path="new/invite-simplified"
                    element={<CreateParticipant authType="simplified" />}
                  />
                  <Route path="new/invite" element={<NewParticipants />} />
                  <Route path="new/csv-import" element={<ParticipantsCsvImport />} />
                </Route>
                <Route path="templates" element={<TemplatesWrapper />}>
                  <Route index element={<TemplatesList />} />
                </Route>
                <Route path="templates/:id" element={<SpecificTemplateWrapper />}>
                  <Route index element={<ProjectGraph />} />
                  <Route path="node/:contentType/:nodeId" element={<NodeScreen />} />
                  <Route path="create/scene" element={<CreateNewScene />} />
                  <Route path="background-music/edit" element={<BackgroundMusic mode="edit" />} />
                  <Route path="background-music/view" element={<BackgroundMusic mode="view" />} />
                </Route>
                <Route path="templates/:id/new" element={<FaitTemplateWrapper />} />
                <Route path="themes" element={<ThemesWrapper />}>
                  <Route index element={<ThemesList />} />
                </Route>

                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>Theres nothing here!</p>
                    </main>
                  }
                />
              </Route>

              <Route path="/error" element={<ErrorView />} />
            </Routes>
          </ErrorBoundary>
          <GlobalModals />
          <ZendeskChat />
        </>
      );
    }
  };

  return renderTheAppropriateRouter();
};

export default Router;
