import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SlideOver, {
  classSlideCloseAnimation,
  sliderAnimationDurationInMs,
} from "../../../common/components/SlideOver/SlideOver";
import {
  closeSlider,
  getCurrentModalStateSlider,
  setAnimationClassState,
} from "../../../common/state/slice/modal/modalSlice";
import { RootState } from "../../../common/state/store";
import { setAlert } from "../../alert/state/alertsSlice";
import {
  fetchBlockadeLabsImageModels,
  generateVoiceOver,
  getBlockadeLabsModels,
  getElevenlabsVoices,
  getTtsPreview,
  resetTtsPreview,
  setOpenedSlider,
  uploadMultipart,
} from "../state/gallerySlice";
import { LoadingState, loadingSelector } from "../../../common/state/slice/appStateSlice";
import { Dropdown, Input } from "src/common/components/Input";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import TextArea from "src/common/components/Input/TextArea";
import { useAppDispatch } from "src/common/state/hooks";
import InformationFrame from "src/common/components/InfomationFrame/InformationFrame";
import { getCurrentUser, updateUser } from "../../profile/state/profileSlice";
import { Tabs, Tab, Box } from "@mui/material";
import { getSubscriptionFeature } from "src/features/subscription/state/subscriptionSlice";
import { GenerateBlockadeLabsImage } from "./GenerateBlockadeLabsImage";
import { fromFileReturnMD5AndSize } from "../state/uploadUtil";
import { ITtsPreferences } from "src/model/model";
import { MediaGenerationInfoParagraph } from "./MediaGenerationInfoParagraph";
import Spinner from "src/common/components/Loader/Spinner";

export const GENERATE_MEDIA_PANEL_COMPONENT = "generateMediaPanel";
export const FETCH_AI_MODELS_FROM_BLOCKADE_LABS = "fetchAiModelsFromBlockadeLabs";
export const GENERATE_MEDIA_SLIDEOVER_COMPONENT = "generateMediaPanelSlideover";

export const GenerateMediaPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isFormEnabled, setIsFormEnabled] = useState(true);
  const [isTtsPreviewPreparingToBeDisplayed, setIsPreviewPreparingToBeDisplayed] = useState(false);
  const openSliderId = useSelector(getCurrentModalStateSlider);
  const ttsPreview = useSelector(getTtsPreview);
  const currentUser = useSelector(getCurrentUser);
  const getUserPreferences = () => {
    try {
      if (Object.prototype.hasOwnProperty.call(currentUser, "preferences")) {
        return typeof currentUser.preferences === "string"
          ? JSON.parse(currentUser.preferences)
          : currentUser.preferences;
      }
    } catch (error) {
      console.info("currentUser have no preferences set.");
      return null;
    }
  };
  const userPreferences = getUserPreferences() as unknown as ITtsPreferences;

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { loading, blockadeLabsFeature } = useSelector((state: RootState) => {
    return {
      loading: loadingSelector(state, GENERATE_MEDIA_PANEL_COMPONENT),
      blockadeLabsFeature: getSubscriptionFeature(state, "blockade-labs-integration"),
    };
  });

  const isAllowedToUseBlockadeLabsIntegration = blockadeLabsFeature?.IsEnabled;

  const [tagEditorKey, setTagEditorKey] = useState(0);
  const [mediaNameToEdit, setMediaNameToEdit] = useState<string>("");
  const [voice, setVoice] = useState(userPreferences?.favoriteVoiceId || "pFZP5JQG7iQjIQuC4Bku");

  const elevenLabsVoices = useSelector(getElevenlabsVoices);
  const mapElevenlabsVoices = () => {
    return elevenLabsVoices.map((voice) => {
      const localizedVoiceGender = t(`general.${voice.gender}`);
      return { ...voice, value: voice.id, optionText: `${voice.name} (${localizedVoiceGender})` };
    });
  };
  const [voiceOptions, setVoiceOptions] = useState(mapElevenlabsVoices());

  const blockadeLabsModels = useSelector(getBlockadeLabsModels);

  const MAX_CHAR_NB = 1500;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<{ textToGenerate: string }>({
    defaultValues: {
      textToGenerate: "",
    },
  });

  const closeSliderAnimation = () => {
    setTimeout(() => {
      dispatch(closeSlider(GENERATE_MEDIA_PANEL_COMPONENT));
      dispatch(setOpenedSlider(""));
    }, sliderAnimationDurationInMs);

    setTimeout(() => {
      dispatch(setAnimationClassState(classSlideCloseAnimation));
    }, 1);
  };

  const tts = async () => {
    setIsFormEnabled(false);
    dispatch(
      updateUser({
        ...currentUser,
        preferences: JSON.stringify({
          favoriteVoiceId: voice,
        }),
      }),
    );
    setIsPreviewPreparingToBeDisplayed(true);
    await dispatch(
      generateVoiceOver({
        voice,
        text: getValues("textToGenerate"),
        componentId: GENERATE_MEDIA_PANEL_COMPONENT,
      }),
    ).then((res: any) => {
      setIsAlertVisible(true);
      return res.payload;
    });
  };

  const resetTts = () => {
    setIsFormEnabled(true);
    reset({
      textToGenerate: "",
    });
    setMediaNameToEdit("");
    dispatch(resetTtsPreview());
    setTagEditorKey(tagEditorKey + 1);

    setVoice(userPreferences?.favoriteVoiceId || "pFZP5JQG7iQjIQuC4Bku");

    setVoiceOptions(mapElevenlabsVoices());
  };

  useEffect(() => {
    resetTts();
  }, [openSliderId]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isAllowedToUseBlockadeLabsIntegration) {
      if (blockadeLabsModels.length === 0) {
        dispatch(fetchBlockadeLabsImageModels({ componentId: FETCH_AI_MODELS_FROM_BLOCKADE_LABS }));
      }
    }
  }, [isAllowedToUseBlockadeLabsIntegration, blockadeLabsModels]);

  useEffect(() => {
    if (ttsPreview) {
      setIsPreviewPreparingToBeDisplayed(false);
      const textToGenerate = getValues("textToGenerate");
      const sanitizedText = textToGenerate.replace(/\s+/g, " ").trim();
      const defaultName = sanitizedText.slice(0, 29).trimEnd(); // Ensure no trailing spaces
      setMediaNameToEdit(defaultName);

      // Wait for the DOM to update and then select the text
      setTimeout(() => {
        const inputElement = document.querySelector("input[name='name']") as HTMLInputElement;
        if (inputElement) {
          inputElement.select();
        }
      }, 0);
    }
  }, [ttsPreview]);

  /* this was used with model multilingual v2_5 turbo but accent was clunky AF, so its unused for now 
  const mapAvailableLanguages = () => {
    const availableLanguagesAsISO_639_1 = [
      "zh",
      "ko",
      "nl",
      "tr",
      "sv",
      "id",
      "fil",
      "ja",
      "uk",
      "el",
      "cs",
      "fi",
      "ro",
      "ru",
      "da",
      "bg",
      "ms",
      "sk",
      "hr",
      "ar",
      "ta",
      "en",
      "pl",
      "de",
      "es",
      "fr",
      "it",
      "hi",
      "pt",
      "hu",
      "vi",
      "no",
    ];

    return availableLanguagesAsISO_639_1.map((languageCode) => {
      return {
        value: languageCode,
        optionText: t(`general.languages.ISO_639-1.${languageCode}`),
      };
    });
  };
*/

  const getErrorText = () => {
    switch (errors.textToGenerate?.type) {
      case "maxLength":
        return t("pages.gallery.generateMediaPanel.maxCharacters");
      case "isNotEmpty":
        return t("pages.gallery.generateVoicePanel.emptyText");
      default:
        return "";
    }
  };

  return (
    <SlideOver large={false} id={GENERATE_MEDIA_SLIDEOVER_COMPONENT}>
      <div className="flex flex-col px-6 pt-8 pb-3 relative">
        {isAllowedToUseBlockadeLabsIntegration && (
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label={t("pages.gallery.generateMediaPanel.voiceOver")} />
            <Tab label={t("pages.gallery.generateMediaPanel.360Image")} />
          </Tabs>
        )}
        <Box>
          {selectedTab === 0 && (
            <div>
              {isAlertVisible && (
                <InformationFrame
                  className="w-[90%] px-6 py-6 mx-auto absolute top-[-20px] transition-opacity z-[1000]"
                  style="success"
                  onClose={() => setIsAlertVisible(false)}
                  withTimeout
                  showCloseButton
                >
                  <div>{t("pages.gallery.generateVoicePanel.success")}</div>
                </InformationFrame>
              )}
              <>
                <h3 className={"font-medium text-lg mb-6 mt-2"}>
                  {t("pages.gallery.generateVoicePanel.title")}
                </h3>
                <div>
                  <form onSubmit={handleSubmit(tts)}>
                    {ttsPreview && (
                      <>
                        <div className="block font-medium text-gray-700 ">
                          {t("general.fileName")}
                        </div>

                        <Input
                          className="mb-4"
                          name="name"
                          type="text"
                          maxLength={30}
                          value={mediaNameToEdit}
                          placeholder={t("general.fileName")}
                          onChange={(e) => {
                            setMediaNameToEdit(e.target.value);
                          }}
                          required
                          disabled={loading === LoadingState.Loading}
                        />
                      </>
                    )}
                    {!ttsPreview && (
                      <MediaGenerationInfoParagraph
                        text={t("pages.gallery.generateVoicePanel.disclaimer")}
                      />
                    )}
                    {!ttsPreview && (
                      <Dropdown
                        options={voiceOptions}
                        label={t("pages.gallery.generateVoicePanel.voice")}
                        labelFontClassName={"text-md mt-2"}
                        onChangeCb={(e: any) => {
                          setVoice(e.target.value);
                        }}
                        asDefaultValue
                        defaultValueIndex={
                          voiceOptions.findIndex((option) => option.value === voice) || 0
                        }
                      />
                    )}
                    <IsLoading
                      componentId={GENERATE_MEDIA_PANEL_COMPONENT}
                      spinnerPlaceholder
                      showSuccess={false}
                      spinnerStyle={{ marginTop: "4vh" }}
                    >
                      {!ttsPreview ? (
                        isTtsPreviewPreparingToBeDisplayed ? (
                          <div className="flex items-center justify-center relative h-full">
                            <Spinner />
                          </div>
                        ) : (
                          <>
                            <div className="block font-medium text-gray-700 mt-4">
                              {t("general.textVoice")}
                            </div>
                            <TextArea
                              isResizable={false}
                              className="bg-white w-full h-64 mt-4"
                              placeholder={t(
                                "pages.gallery.generateVoicePanel.textInputPlaceholder",
                              )}
                              registerFct={() =>
                                register("textToGenerate", {
                                  maxLength: MAX_CHAR_NB,
                                  validate: {
                                    isNotEmpty: (value) => value.trim().length > 0,
                                  },
                                })
                              }
                            />

                            {errors.textToGenerate && (
                              <span className="text-red-600 text-sm mt-2">{getErrorText()}</span>
                            )}
                          </>
                        )
                      ) : (
                        ttsPreview && (
                          <div className="mt-4">
                            <audio id="tts-preview" className="w-full" controls autoPlay>
                              <source src={ttsPreview.url} type="audio/mpeg" />
                            </audio>
                          </div>
                        )
                      )}
                      <div className="mt-5" key={JSON.stringify(ttsPreview)}>
                        {!ttsPreview ? ( // todo: add condition "if !tts loading"
                          <button
                            disabled={!isFormEnabled}
                            className={`btn-primary-fill mr-3 mt-2 ${
                              !isFormEnabled ? "cursor-not-allowed" : ""
                            }`}
                            type="submit"
                          >
                            {t("general.generate")}
                          </button>
                        ) : (
                          <div className="flex items-center justify-end">
                            <button
                              type="button"
                              className={"btn-primary-outline mr-3 mt-2"}
                              onClick={() => {
                                resetTts();
                              }}
                            >
                              {t("general.cancel")}
                            </button>
                            <button
                              type="button"
                              className={"btn-primary-fill mr-3 mt-2"}
                              disabled={!mediaNameToEdit}
                              onClick={async () => {
                                if (ttsPreview) {
                                  try {
                                    // Convert Blob URL to File
                                    const response = await fetch(ttsPreview.url);
                                    const blob = await response.blob();
                                    const file = new File(
                                      [blob],
                                      `${mediaNameToEdit || "audio_preview"}.mp3`,
                                      {
                                        type: "audio/mpeg",
                                      },
                                    );

                                    const { MD5 } = await fromFileReturnMD5AndSize(file);

                                    const uploadParams = {
                                      file,
                                      orgId: currentUser.organization.id,
                                      name: mediaNameToEdit || "audio_preview",
                                      media_360_tag: "other",
                                      MD5,
                                      metadata: { initialPrompt: getValues("textToGenerate") },
                                    };

                                    dispatch(uploadMultipart(uploadParams))
                                      .then(() => {
                                        dispatch(
                                          setAlert({
                                            type: "success",
                                            msg: t(
                                              "pages.gallery.generateVoicePanel.uploadSuccess",
                                            ),
                                          }),
                                        );
                                        resetTts();
                                        closeSliderAnimation();
                                      })
                                      .catch((err) => {
                                        console.error("Upload error:", err);
                                      });
                                  } catch (err) {
                                    console.error("Error handling file upload:", err);
                                  }
                                }
                              }}
                            >
                              {t("general.save")}
                            </button>
                          </div>
                        )}
                      </div>
                    </IsLoading>
                  </form>
                </div>
              </>
            </div>
          )}
          {selectedTab === 1 && (
            <GenerateBlockadeLabsImage
              models={blockadeLabsModels}
              closeSliderAnimation={closeSliderAnimation}
            />
          )}
        </Box>
      </div>
    </SlideOver>
  );
};
