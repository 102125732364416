import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuild } from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IAskPublicationPayload {
  projectGroupId: number;
  selectedProjectsIds: number[];
  selectedPlatforms: string[];
  shouldBeTested: number; // 0 or 1
  isNewVersion: boolean;
  callback(): any;
}

export interface IPublicationParams {
  selectedProjects: number[];
  shouldBeTested: number; // 0 or 1
}

export interface IProjectGroupPublicationState {
  currentProjectGroupBuilds: IBuild[];
  newPublication: IPublicationParams;
}

interface IBuilds {
  all: IBuild[];
  newPublication: {
    selectedProjectsIds: [];
    shouldBeTested: 0 | 1; // 0 or 1
    isNewVersion: boolean;
  };
}

const initialState: IBuilds = {
  all: [],
  newPublication: {
    selectedProjectsIds: [],
    shouldBeTested: 0, // 0 or 1
    isNewVersion: false,
  },
};

export const fetchBuilds = createAsyncThunk(
  "builds/fetchBuilds",
  // eslint-disable-next-line no-empty-pattern
  async ({}: any & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.builds}?populate=project&populate=project_group`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const fetchCurrentProjectGroupBuilds = createAsyncThunk(
  "builds/fetchCurrentProjectGroupBuilds",
  async ({
    projectGroupId,
  }: {
    projectGroupId: number;
  } & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.builds}?filters[project_group][id]=${projectGroupId}&populate=project_group`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.error(e);
      });
  },
);

export const askPublication = createAsyncThunk(
  "project/askPublication",
  async ({
    projectGroupId,
    selectedProjectsIds,
    selectedPlatforms,
    shouldBeTested,
    isNewVersion,
    callback,
  }: IAskPublicationPayload & IAppLoaderAction) => {
    try {
      return await axios
        .post(`${apiUrls.projects.askPublication}`, {
          projectGroupId,
          selectedProjectsIds,
          selectedPlatforms,
          shouldBeTested,
          isNewVersion,
        })
        .then((res) => {
          callback();
          return { data: res.data };
        });
    } catch (e: any) {
      console.error(e);
      return { data: [] };
    }
  },
);

export const retryPublication = createAsyncThunk(
  "builds/retryPublication",
  async ({
    buildId,
  }: {
    buildId: number;
  } & IAppLoaderAction) => {
    return await axios
      .post(`${apiUrls.projects.retryPublication}`, { buildId })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.error(e);
      });
  },
);

export const buildsSlice = createSlice({
  name: "builds",
  initialState,
  reducers: {
    setPublicationParams: (state: IBuilds, action: PayloadAction<any>) => {
      state.newPublication.selectedProjectsIds = action.payload.selectedProjectsIds;
      state.newPublication.shouldBeTested = action.payload.shouldBeTested;
    },
    setIsPublicationNewVersion: (state: IBuilds, action: PayloadAction<boolean>) => {
      state.newPublication.isNewVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuilds.fulfilled, (state: IBuilds, { payload }) => {
      state.all = payload;
    });
    builder.addCase(askPublication.fulfilled, (state, { payload }) => {
      const newBuilds = payload.data as IBuild[];
      const updatedBuildsList = [...state.all, ...newBuilds];
      state.all = updatedBuildsList;
    });
    builder.addCase(retryPublication.fulfilled, (state, { payload }) => {
      const buildsIR = [...state.all].map((build: IBuild) => {
        if (Number(build.id) === Number(payload.id)) {
          return payload;
        }
        return build;
      });
      state.all = buildsIR;
    });
    builder.addCase(fetchCurrentProjectGroupBuilds.fulfilled, (state, { payload }) => {
      payload.forEach((elt: any) => {
        const ind = state.all.findIndex((oldBuild) => Number(oldBuild.id) === Number(elt.id));
        if (ind >= 0) {
          state.all[ind] = elt;
        } else {
          state.all.push(elt);
        }
      });
      state.all = [...state.all];
    });
  },
});

export const { setPublicationParams, setIsPublicationNewVersion } = buildsSlice.actions;

export const buildsReducer = buildsSlice.reducer;

export const getBuilds = (state: RootState) => state.builds.all;
export const getNewPublicationSettings = (state: RootState) => state.builds.newPublication;

export const getCurrentProjectGroupBuilds = (state: RootState) =>
  state.builds.all.filter((b: IBuild) => b.project_group?.id === state.projectGroups.current.id);

export const getLatestBuildsOfProjectGroup = (projectGroupId: number) => (state: RootState) => {
  let latestVersion = 0;
  const relevantBuilds = state.builds.all.filter((b: IBuild) => {
    const matchingProjectGroup = Number(b.project_group?.id) === projectGroupId;
    if (matchingProjectGroup) {
      if (b.version > latestVersion) {
        latestVersion = b.version;
      }
    }
    return matchingProjectGroup;
  });
  return relevantBuilds.filter((b: IBuild) => b.version === latestVersion);
};
