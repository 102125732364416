import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllProjectGroupsWithNestedElements,
  setSearchProjectGroups,
  getCurrentProjectGroupForSearch,
  getCurrentElements,
  setSearchProject,
  getCurrentProjectForSearch,
  setUpdateProject,
} from "../../../features/projectGroups/state/projectGroupsSlice";
import { useEffect, useState } from "react";
import iconProject from "src/assets/iconProjectSearchBar.png";
import SwitchTooltip from "../Button/SwitchTooltip";
import { useLocation } from "react-router-dom";

interface IProjectGroupSearchProps {
  isProjectGroup: boolean;
}

export const SearchProjectGroups = ({ isProjectGroup = false }: IProjectGroupSearchProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const projectListElements = useSelector(getCurrentElements);
  const projectGroupNoUpdated = useSelector(getCurrentProjectGroupForSearch);
  const projectListNoUpdated = useSelector(getCurrentProjectForSearch);
  const currentProjectGroups = useSelector(getAllProjectGroupsWithNestedElements);

  useEffect(() => {
    dispatch(setUpdateProject(projectListNoUpdated));
  }, []);

  const [inputBorder, setInputBorder] = useState(false);
  const [activeFilter, setActiveFilter] = useState<"groupItems" | "projectFromGroup">("groupItems");
  const [searchQuery, setSearchQuery] = useState("");

  const groupItems = currentProjectGroups.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  const projectFromProjectGroupsItems = currentProjectGroups.flatMap((group: any) =>
    group.project_group_elements.map((element: any) => ({
      id: element.project.id,
      name: element.project.name,
      parentGroupName: group.name,
    })),
  );

  const filterProjects = projectListElements.map((item: any) => item.project);
  const projectItems = filterProjects.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  const handleOnSearch = (inputValue: string) => {
    setSearchQuery(inputValue);
    if (isProjectGroup) {
      const forGroupFilterSearch = {
        name: "",
        actualGroupList: projectGroupNoUpdated,
      };
      dispatch(setSearchProjectGroups(forGroupFilterSearch));
    } else {
      const forProjectFilterSearch = {
        name: "",
        actualProjectList: projectListNoUpdated,
      };
      dispatch(setSearchProject(forProjectFilterSearch));
    }
  };
  const handleOnSelect = (item: any) => {
    if (isProjectGroup) {
      if (activeFilter === "groupItems") {
        const forGroupFilterSearch = {
          name: item.name,
          actualGroupList: currentProjectGroups,
        };
        dispatch(setSearchProjectGroups(forGroupFilterSearch));
        return;
      }
      if (activeFilter === "projectFromGroup") {
        const parentGroup = currentProjectGroups.find((group: any) =>
          group.project_group_elements.some((element: any) => element.project.id === item.id),
        );

        if (parentGroup) {
          const forGroupFilterSearch = {
            name: parentGroup.name,
            actualGroupList: [parentGroup],
          };
          dispatch(setSearchProjectGroups(forGroupFilterSearch));
        }
        return;
      }
    }

    const forProjectFilterSearch = {
      name: item.name,
      actualProjectList: projectListElements,
    };
    dispatch(setSearchProject(forProjectFilterSearch));
  };

  const handleFilterSwitch = (filter: "groupItems" | "projectFromGroup") => {
    setActiveFilter(filter);
    setSearchQuery("");

    if (isProjectGroup) {
      const forGroupFilterSearch = {
        name: "",
        actualGroupList: projectGroupNoUpdated,
      };
      dispatch(setSearchProjectGroups(forGroupFilterSearch));
    } else {
      const forProjectFilterSearch = {
        name: "",
        actualProjectList: projectListNoUpdated,
      };
      dispatch(setSearchProject(forProjectFilterSearch));
    }
  };

  const correctModeItems = () => {
    if (isProjectGroup) {
      return activeFilter === "groupItems" ? groupItems : projectFromProjectGroupsItems;
    } else {
      return projectItems;
    }
  };

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const isOnProjectsPage = location.pathname.includes("/list");

  return (
    <div className={`search-filter mb-2 pt-14 ${isOnProjectsPage ? "mt-3" : ""}`}>
      <header className="">
        <div onBlur={() => setInputBorder(false)} style={{ width: 250 }}>
          <ReactSearchAutocomplete
            items={correctModeItems()}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            inputSearchString={searchQuery}
            showClear
            autoFocus
            onFocus={() => setInputBorder(true)}
            formatResult={formatResult}
            placeholder={
              isProjectGroup && activeFilter === "groupItems"
                ? t("general.searchProjectGroup")
                : t("general.searchProject")
            }
            showIcon={false}
            styling={{
              borderRadius: "10px",
              iconColor: "#00c889",
              clearIconMargin: "10px 30px 10px 10px",
              placeholderColor: "#ccd7e0",
              border: inputBorder ? "1px solid #d4d5d8" : "0px",
              height: "46px",
              boxShadow: "0px",
            }}
            showNoResultsText={t("general.noResults")}
          />
        </div>
        <SwitchTooltip
          isProjectGroup={isProjectGroup}
          activeFilter={activeFilter}
          handleFilterSwitch={handleFilterSwitch}
          iconProject={iconProject}
        />
      </header>
    </div>
  );
};
