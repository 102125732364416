import { useSelector } from "react-redux";
import ModalStandard from "../Modal/ModalStandard";
import { getIdOfLatestProjectGroupCursedByAi } from "src/features/chatbot/state/chatbotSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/common/state/hooks";
import { closeStandardModal } from "src/common/state/slice/modal/modalSlice";

export const GlobalModals = () => {
  const projectGroupId = useSelector(getIdOfLatestProjectGroupCursedByAi);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <>
      <ModalStandard id="notifyAIProjectHasBeenGenerated" showCloseButton fullScreen={false}>
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-4">
            {t("pages.chat.createProjectFromAiGraph.modalCreationProjectNotification")}
          </h3>
          {projectGroupId && (
            <button
              className="btn-primary-fill"
              type="button"
              onClick={() => {
                navigate(`/courses/${projectGroupId}/list`);
                dispatch(closeStandardModal("notifyAIProjectHasBeenGenerated"));
              }}
            >
              {t("pages.chat.createProjectFromAiGraph.modalCreationGotoButton")}
            </button>
          )}
        </div>
      </ModalStandard>
      <ModalStandard id="notifyAIProjectGroupHasBeenGenerated" showCloseButton fullScreen={false}>
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-4">
            {t("pages.chat.createProjectFromAiGraph.modalCreationProjectGroupNotification")}
          </h3>
          {projectGroupId && (
            <button
              className="btn-primary-fill"
              type="button"
              onClick={() => {
                navigate(`/courses/${projectGroupId}/list`);
                dispatch(closeStandardModal("notifyAIProjectGroupHasBeenGenerated"));
              }}
            >
              {t("pages.chat.createProjectFromAiGraph.modalCreationGotoButton")}
            </button>
          )}
        </div>
      </ModalStandard>
    </>
  );
};
