import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";

interface ButtonAscDescProps {
  label: string;
  column: "name" | "theme_info";
  sortColumn: string | null;
  sortDirection: "asc" | "desc";
  onSort: (column: "name" | "theme_info") => void;
}

const ButtonAscDesc = ({
  label,
  column,
  sortColumn,
  sortDirection,
  onSort,
}: ButtonAscDescProps) => {
  return (
    <div className="flex items-center cursor-pointer" onClick={() => onSort(column)}>
      {label}
      <button className="ml-2">
        {sortColumn === column &&
        ((column === "name" && sortDirection === "asc") ||
          (column === "theme_info" && sortDirection === "desc")) ? (
          <ArrowUpIcon className="stroke-green-600 w-3 h-3" />
        ) : (
          <ArrowDownIcon className="stroke-green-600 w-3 h-3" />
        )}
      </button>
    </div>
  );
};

export default ButtonAscDesc;
