/* eslint-disable indent */
import {
  ObjectAudio,
  ObjectCommons,
  ObjectHotspot,
  ObjectOnboarding,
  ObjectPhotoCatch,
  ObjectQuizz1,
  ObjectQuizz2,
  ObjectScene,
  ObjectText,
  ObjectThumbnail,
  ObjectTuto,
  ObjectVideo2d,
  ObjectSpeechToCheck,
  ObjectZoneDetect,
  ModelName,
  isSceneModelName,
  ContentType,
  isSceneContentType,
  isAssetContentType,
  ObjectPdfViewer,
} from "./dataTypes/ContentType";
import { Node } from "./nodeTypes";
import * as yup from "yup";

export const validateNode = (node: Node) => {
  const validator = getValidatorForModalName(node.modelName);
  return validator.isValidSync(node.details);
};

export const isNodeEditable = (node: Node) => {
  switch (node.modelName) {
    case "content-scoring-scene":
      //case "content-type": // ⬅︎ won't be loaded
      return false;
  }
  return true;
};

export const getValidatorForContentType = (contentType: ContentType): yup.ObjectSchema =>
  isSceneContentType(contentType) || isAssetContentType(contentType)
    ? getValidatorForModalName(contentType.modelName)
    : anyObjectValidator;

export const getValidatorForModalName = (modelName: ModelName): yup.ObjectSchema => {
  if (isSceneModelName(modelName)) {
    return sceneValidator;
  } else {
    switch (modelName) {
      case "content-audio":
        return audioValidator;
      case "content-button-1":
      case "content-button-2":
      case "content-button-reload":
      case "content-clean":
      case "content-scoring-general":
      case "content-vr-locator":
      case "content-random-quizz":
      case "content-element-catch":
      case "content-highlight":
      case "content-danger-catch":
      case "content-tuto2":
      case "content-quizz-sort":
      case "content-quizz-image-multiple":
      case "content-avatar-ai":
      case "content-avatar-ai-persona":
        return nameFieldValidator;
      case "content-zone-detect":
        return nameAndEntitled;
      case "content-hotspot-scene-image":
      case "content-hotspot-scene-video":
      case "content-text":
        return nameAndTextFieldsValidator;
      case "content-onboarding":
        return onboardingAssetValidator;
      case "content-photo-catch":
        return photoCatchValidator;
      case "content-thumbnail":
        return thumbnailValidator;
      case "content-quizz-1":
        return quizz1CatchValidator;
      case "content-quizz-2":
        return quizz2CatchValidator;
      case "content-tuto":
        return tutoValidator;
      case "content-video-2-d":
        return video2dValidator;
      case "content-speech-to-check":
        return speechToCheckValidator;
      case "content-pdf-viewer":
        return pdfViewerValidator;

      // NO VALIDATION FOR THE FOLLOWING ELEMENTS
      case "content-scoring-scene":
        return anyObjectValidator;
      default:
        return anyObjectValidator;
    }
  }
};

//
// Validator
//

const textValidator = yup.string().required(); //.max(16);
const idValidator = yup.number().required().min(1);

const anyObjectValidator = yup.object();

const sceneValidator = yup.object<Partial<ObjectScene>>({
  name: textValidator,
  source_media: idValidator,
});

const nameFieldValidator = yup.object<Partial<ObjectCommons>>({
  name: textValidator,
});

const nameAndTextFieldsValidator = yup.object<Partial<ObjectHotspot | ObjectText>>({
  name: textValidator,
  //text: textValidator,
});

const nameAndEntitled = yup.object<Partial<ObjectZoneDetect>>({
  name: textValidator,
  //  entitled: textValidator,
});

const onboardingAssetValidator = yup.object<Partial<ObjectOnboarding>>({
  name: textValidator,
  //  text: textValidator,
  source_image_2d: idValidator,
});

const audioValidator = yup.object<Partial<ObjectAudio>>({
  name: textValidator,
  source_audio: idValidator,
});

const thumbnailValidator = yup.object<Partial<ObjectThumbnail>>({
  name: textValidator,
  //  text: textValidator,
  //  gazed_text: textValidator,
  source_image: idValidator,
});

const photoCatchValidator = yup.object<Partial<ObjectPhotoCatch>>({
  name: textValidator,
  //  text_instruction: textValidator,
  //  text_success: textValidator,
  //  text_fail: textValidator,
});

const quizz1CatchValidator = yup.object<Partial<ObjectQuizz1>>({
  name: textValidator,
  //question_text: textValidator,
  //answer_1_text: textValidator,
  //answer_2_text: textValidator,
  //answer_3_text: textValidator,
  //explanation_true_text: textValidator,
  //explanation_false_text: textValidator,
  //is_answer_1_true: boolValidator,
  //is_answer_2_true: boolValidator,
  //is_answer_3_true: boolValidator,
});

const quizz2CatchValidator = yup.object<Partial<ObjectQuizz2>>({
  name: textValidator,
  //question_text: textValidator,
  //answer_1_image: idValidator,
  //answer_2_image: idValidator,
  //answer_3_image: idValidator,
  //explanation_true_text: textValidator,
  //explanation_false_text: textValidator,
  //is_answer_1_true: boolValidator,
  //is_answer_2_true: boolValidator,
  //is_answer_3_true: boolValidator,
});

const tutoValidator = yup.object<Partial<ObjectTuto>>({
  name: textValidator,
  source_logo: idValidator,
});

const video2dValidator = yup.object<Partial<ObjectVideo2d>>({
  name: textValidator,
  source_media: idValidator,
  //has_video_controller: boolValidator,
});

const speechToCheckValidator = yup.object<Partial<ObjectSpeechToCheck>>({
  name: textValidator,
  //  intro_text: textValidator,
});

const pdfViewerValidator = yup.object<Partial<ObjectPdfViewer>>({
  name: textValidator,
  //  page_to_start: textValidator,
  source_pdf: idValidator,
  // NB: this isn't meant to be commented, but I had to, to respect the deadline..
});
