import { t } from "i18next";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProjectGroupCharts from "src/features/stats/components/ProjectGroupCharts/ProjectGroupCharts";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import InfoCard from "../../common/components/Cards/InfoCard";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import { useAppDispatch } from "../../common/state/hooks";
import { roundPercentage, roundScorePercentage } from "../../common/util/round";
import Podium from "../../features/stats/components/Charts/Podium";
// import ProjectCharts from "../../features/stats/components/ProjectCharts/ProjectCharts";
import {
  getAvarageScorePerProjectForProjectGroup,
  getSelectedProjectGroupId,
  getSelectedProjectGroupWithSessions,
  ISessionsByProjectGroup,
  ScorePerScene,
  sessionsByProjectGroupSelector,
} from "../../features/stats/state/statsSlice";
import { ISession } from "../../model/model";

export const SUCCESS_RATE_PER_PROJECT_COMPONENT_ID = "successRatePerProjectComponent";

const StatisticsForProjectGroup = () => {
  const dispatch = useAppDispatch();

  const [bestScoreSessionName, setBestScoreSessionName] = useState("");
  const [worstScoreSessionName, setWorstScoreSessionName] = useState("");
  const [averageDurationCompletedSession, setAverageDurationCompletedSession] = useState("");

  const selectedProjectGroupId = useSelector(getSelectedProjectGroupId);
  const sessionsPerProjectGroup = useSelector(sessionsByProjectGroupSelector);

  const projectGroup = sessionsPerProjectGroup.find(
    (ssp: ISessionsByProjectGroup) => Number(ssp.project_group_id) === selectedProjectGroupId,
  );

  const sessions =
    useSelector(getSelectedProjectGroupWithSessions(selectedProjectGroupId))?.sessions || [];
  const [numberOfParticipants, setNumberOfParticipants] = useState(sessions.length); // handles !need_login project by default

  // Number of unique participants
  const numberOfSessions = String(sessions.length);
  const countUniqueParticipants = new Set();

  // Percentage of success
  const successPercentage =
    sessions.length / sessions.filter((s: ISession) => s.is_succeeded).length;
  const prettySuccessPercentage = roundPercentage(successPercentage);

  // Percentage of completion
  const completionPercentage =
    sessions.length / sessions.filter((s: ISession) => s.is_completed).length;
  const prettyCompletionPercentage = roundPercentage(completionPercentage);

  useEffect(() => {
    // Number of participants & average duration of completed session
    let duration = 0;
    sessions.forEach((s: ISession) => {
      countUniqueParticipants.add(s.user_name);
      duration += s.total_session_duration;
    });
    setNumberOfParticipants(Array.from(countUniqueParticipants).length);
    setAverageDurationCompletedSession(`${Math.round(duration / sessions.length)} s`);

    // Best score scene
    dispatch(
      getAvarageScorePerProjectForProjectGroup({
        projectGroupId: selectedProjectGroupId,
        componentId: SUCCESS_RATE_PER_PROJECT_COMPONENT_ID,
      }),
    ).then((res: any) => {
      const sortedSessions = [...res.payload.projects]; // this is needed: https://stackoverflow.com/a/64958159
      sortedSessions.sort((a: ScorePerScene, b: ScorePerScene) => {
        return Number(roundScorePercentage({ ...a })) - Number(roundScorePercentage({ ...b }));
      });
      if (sortedSessions.length === 0) {
        setBestScoreSessionName(t("pages.statistics.noData"));
        setWorstScoreSessionName(t("pages.statistics.noData"));
      } else {
        setBestScoreSessionName(sortedSessions[0]?.name);
        setWorstScoreSessionName(sortedSessions[sortedSessions.length - 1]?.name);
      }
    });

    return () => {
      setBestScoreSessionName("");
      setWorstScoreSessionName("");
    };
  }, [selectedProjectGroupId]);

  return (
    <div className="w-full max-w-9xl mx-auto pt-6 sm:px-6 lg:px-8 personalize-scroll-visible overflow-auto statistics-for-project-group">
      <InfoButton relevantArticle="aboutStatistiques" />
      <IsLoading
        componentId={SUCCESS_RATE_PER_PROJECT_COMPONENT_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <ProjectGroupCharts projectGroupId={selectedProjectGroupId} />
        <div className="grid grid-cols-3 gap-4 w-full mx-auto xl:px-0 py-6">
          <div className="row-span-3">
            <Podium
              sessions={
                projectGroup?.projects
                  ? projectGroup.projects
                      .filter((project) => project.need_login) // Filter projects that require login
                      .reduce((acc, project) => acc.concat(project.sessions), []) // Flatten the sessions from these projects into a single array
                  : []
              }
              moreThanThreeParticipants={numberOfParticipants > 2}
              key={`${selectedProjectGroupId}<<`}
            />
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.successRate")}
              data={
                numberOfSessions === "0"
                  ? t("pages.statistics.noSessions")
                  : `${prettySuccessPercentage}% `
              }
            />
          </div>
          <div className="row-span-2">
            <IsLoading
              componentId={SUCCESS_RATE_PER_PROJECT_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.projectWithBestScores")}
                data={
                  numberOfSessions === "0"
                    ? t("pages.statistics.noSessions")
                    : `${bestScoreSessionName}`
                }
              />
            </IsLoading>
          </div>

          <div className="row-span-1">
            <InfoCard
              title={t("pages.statistics.completionRate")}
              data={
                numberOfSessions === "0"
                  ? t("pages.statistics.noSessions")
                  : `${prettyCompletionPercentage}% `
              }
            />
          </div>
          <div className="row-span-1">
            <IsLoading
              componentId={SUCCESS_RATE_PER_PROJECT_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.projectWithWorstScores")}
                data={
                  numberOfSessions === "0"
                    ? t("pages.statistics.noSessions")
                    : `${worstScoreSessionName}`
                }
              />
            </IsLoading>
          </div>
          <div className="row-span-2">
            <InfoCard title={t("pages.statistics.numberOfSessions")} data={numberOfSessions} />
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.averageDurationCompletedSession")}
              data={
                numberOfSessions === "0"
                  ? t("pages.statistics.noSessions")
                  : averageDurationCompletedSession
              }
            />
          </div>
        </div>
      </IsLoading>
    </div>
  );
};

export default StatisticsForProjectGroup;
