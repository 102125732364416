import { useEffect, useState } from "react";
import { useAppDispatch } from "src/common/state/hooks";
import { prettifyErrorMessage } from "src/common/util/prettifyErrorMessage";
import { checkValidityOnly } from "src/features/graph/state/graphLegacy";
import WarningIconWithTooltip from "../Icon/WarningIconWithTooltip";
import Spinner from "../Loader/Spinner";
import { Tooltip } from "../Tooltip/Tooltip";

export interface SelectableCard {
  component: any;
  id: string;
  disabled?: boolean;
  selected?: boolean;
}

export interface ICardsSelectorProps {
  parentClassName?: string;
  cardClassName?: string;
  disabledTooltipText: string;
  cards: SelectableCard[];
  cardClickCb: any;
  shouldCheckProjectIntegrity?: boolean;
}

const CardsSelector = ({
  parentClassName = "flex items-center justify-center",
  cardClassName = "p-4 m-1 w-28 h-full border-2 cursor-pointer select-none",
  cards = [],
  cardClickCb = () => null,
  disabledTooltipText = "",
  shouldCheckProjectIntegrity = false,
}: ICardsSelectorProps) => {
  const dispatch = useAppDispatch();
  const [isFinishedCheckingValidity, setIsFinishedCheckingValidity] = useState(false);
  const [errorMessagesByProjectId, setErrorMessagesByProjectId] = useState([]);

  const computeStyle = (card: SelectableCard, hasErrors: boolean) => {
    if (hasErrors || card.disabled) {
      return "cursor-not-allowed opacity-70"; // Lighter grayed-out effect
    } else if (card.selected) {
      return "border-green-500";
    } else {
      return "border-gray-500";
    }
  };

  useEffect(() => {
    if (shouldCheckProjectIntegrity) {
      const fetchValidity = async () => {
        const results = await Promise.all(
          cards.map(async (card) => {
            const res = await dispatch(checkValidityOnly(Number(card.id)));
            return {
              projectId: card.id,
              errorMessages: (res?.payload as any)?.data?.errorMessages || [],
              warningMessages: (res?.payload as any)?.data?.warningMessages || [],
            };
          }),
        );
        setErrorMessagesByProjectId(results);
        setIsFinishedCheckingValidity(true);
      };
      fetchValidity();
    } else {
      setIsFinishedCheckingValidity(true);
    }
  }, [cards, dispatch]);

  return (
    <>
      {!isFinishedCheckingValidity ? (
        <div className="flex items-center justify-center relative h-full">
          <Spinner />
        </div>
      ) : (
        <div className={parentClassName}>
          {cards.map((card: SelectableCard, i: number) => {
            const cardValidity = errorMessagesByProjectId.find(
              (error) => error.projectId === card.id,
            );
            const hasErrors = cardValidity?.errorMessages.length > 0;
            const hasWarnings = cardValidity?.warningMessages.length > 0;

            const errorMessage = hasErrors
              ? prettifyErrorMessage(cardValidity.errorMessages.join(","))
              : { msg: "", subMsg: "" };
            const warningMessage = hasWarnings
              ? prettifyErrorMessage(JSON.stringify(cardValidity.warningMessages))
              : { msg: "", subMsg: "" };

            const messageText = hasErrors
              ? `${errorMessage.msg}. ${errorMessage.subMsg}`
              : `${warningMessage.msg}. ${warningMessage.subMsg}`;

            return (
              <Tooltip
                className={`${
                  card.disabled || hasErrors ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                classNameChildren="left-0"
                forceSingleLine={false}
                key={`${i}=_${i}`}
                message={card.disabled && !hasErrors ? disabledTooltipText : ""} // Show tooltip only if disabled and no errors
              >
                <div
                  className={`${cardClassName} ${computeStyle(card, hasErrors)} w-full`}
                  onClick={() => !hasErrors && cardClickCb(card.id)}
                >
                  <div className="relative w-full">
                    {card.component}
                    {(hasErrors || hasWarnings) && (
                      <div
                        className={`absolute z-50 top-2 right-2 h-6 w-6 rounded-md overflow-visible ${
                          hasErrors ? "text-red-500" : "text-yellow-900"
                        }`}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                        }}
                      >
                        <WarningIconWithTooltip
                          message={messageText.trim()}
                          iconColor={hasErrors ? "red" : "yellow"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CardsSelector;
