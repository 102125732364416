/**
 * Encodes a JSON object as a Base64URL string.
 * @param {Record<string, unknown>} obj - The object to encode.
 * @returns {string} Base64URL-encoded string.
 */
const base64UrlEncode = (obj: Record<string, unknown>): string => {
  const json = JSON.stringify(obj);
  if (typeof window === "undefined") {
    return Buffer.from(json).toString("base64url");
  } else {
    const uint8Array = new TextEncoder().encode(json);
    return btoa(String.fromCharCode(...Array.from(uint8Array))) // <-- FIXED
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
};

/**
 * Decodes a Base64URL-encoded string.
 * @param {string} str - The Base64URL-encoded string.
 * @returns {string} Decoded string.
 */
const base64UrlDecode = (str: string): string => {
  const base64 = str.replace(/-/g, "+").replace(/_/g, "/");
  if (typeof window === "undefined") {
    return Buffer.from(base64, "base64").toString("utf-8");
  } else {
    const binaryString = atob(base64);
    return new TextDecoder().decode(
      new Uint8Array(Array.from(binaryString, (c) => c.charCodeAt(0))),
    );
  }
};

/**
 * Decodes a JSON Web Token (JWT) and extracts its payload.
 * @param {string} token - The JWT string.
 * @returns {Record<string, any> | null} The decoded payload object or null if invalid.
 */
const parseJwt = (token: string): Record<string, any> | null => {
  try {
    const base64Url = token.split(".")[1];
    const jsonPayload = base64UrlDecode(base64Url);
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Error parsing JWT:", e);
    return null;
  }
};

/**
 * Schedules automatic logout when the JWT expires.
 * @param {Object} params - Configuration object.
 * @param {string} params.jwt - The JWT string.
 * @param {() => void} params.logoutCb - The callback function to execute on logout.
 */
const scheduleLogoutWhenTokenExpires = ({
  jwt,
  logoutCb,
}: {
  jwt: string;
  logoutCb: () => void;
}) => {
  try {
    if (!jwt) return;

    const { exp } = parseJwt(jwt) ?? {};
    if (!exp) return;

    const expirationTime = exp * 1000;
    const timeLeft = expirationTime - Date.now();
    console.log(
      "4139 in scheduleLogoutWhenTokenExpires, jwt:",
      jwt,
      " .. expirationTime:",
      expirationTime,
      "... timeLeft:",
      timeLeft,
    );
    timeLeft > 0
      ? setTimeout(logoutCb, timeLeft) && console.log(`Logging out in ${timeLeft / 1000} seconds`)
      : (console.log("Token already expired, logging out immediately"), logoutCb());
  } catch (e) {
    console.error("Invalid token format", e);
  }
};

export { parseJwt, scheduleLogoutWhenTokenExpires, base64UrlEncode, base64UrlDecode };
