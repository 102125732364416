import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../common/state/store";
import { IAppLoaderAction } from "src/common/state/loaderHandleMiddleware";
import apiUrls from "src/api";
import axios from "axios";

export interface ILanguage {
  id: number;
  name: string;
  ISO_639_1: string;
  IETF_BCP_47: string;
  right_to_left: boolean;
}

interface ILanguagesState {
  all: ILanguage[];
}
const initialState: ILanguagesState = {
  all: [],
};

export const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLanguages.fulfilled, (state: ILanguagesState, { payload }) => {
      state.all = payload;
    });
  },
});

export const fetchLanguages = createAsyncThunk(
  "languages/fetchLanguages",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    return await axios
      .get(apiUrls.languages)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
);

// export const {};

export const getAllLanguages = (state: RootState) => state.languages.all;

export default languagesSlice.reducer;
