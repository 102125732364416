import { t } from "i18next";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import InfoCard from "../../common/components/Cards/InfoCard";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import { useAppDispatch } from "../../common/state/hooks";
import { roundPercentage, roundScorePercentage } from "../../common/util/round";
import Podium from "../../features/stats/components/Charts/Podium";
import ProjectCharts from "../../features/stats/components/ProjectCharts/ProjectCharts";
import {
  getScorePerSceneForProject,
  getSelectedProjectGroupId,
  getSelectedProjectGroupWithSessions,
  getSelectedProjectId,
  ISessionsByProject,
  ISessionsByProjectGroup,
  ScorePerScene,
  sessionsByProjectGroupSelector,
} from "../../features/stats/state/statsSlice";
import { ISession } from "../../model/model";

export const SUCCESS_RATE_PER_SCENE_COMPONENT_ID = "successRatePerSceneComponent";

const StatisticsForProject = () => {
  const dispatch = useAppDispatch();

  const [bestScoreSessionName, setBestScoreSessionName] = useState("");
  const [worstScoreSessionName, setWorstScoreSessionName] = useState("");
  const [averageDurationCompletedSession, setAverageDurationCompletedSession] = useState("");

  const selectedProjectId = useSelector(getSelectedProjectId);

  const selectedProjectGroupId = useSelector(getSelectedProjectGroupId);
  const sessionsPerProjectGroup = useSelector(sessionsByProjectGroupSelector);

  const project = sessionsPerProjectGroup
    .find((ssp: ISessionsByProjectGroup) => Number(ssp.project_group_id) === selectedProjectGroupId)
    .projects.find((ssp: ISessionsByProject) => Number(ssp.project_id) === selectedProjectId);

  const sessions =
    useSelector(getSelectedProjectGroupWithSessions(selectedProjectGroupId))?.sessions || [];

  const [numberOfParticipants, setNumberOfParticipants] = useState(sessions.length); // handles !need_login project by default

  // Number of unique participants
  const numberOfSessions = String(sessions?.length);
  const countUniqueParticipants = new Set();

  // Percentage of success
  const successPercentage =
    sessions?.length / sessions.filter((s: ISession) => s.is_succeeded)?.length;
  const prettySuccessPercentage = roundPercentage(successPercentage);

  // Percentage of completion
  const completionPercentage =
    sessions?.length / sessions.filter((s: ISession) => s.is_completed)?.length;
  const prettyCompletionPercentage = roundPercentage(completionPercentage);

  useEffect(() => {
    // Number of participants & average duration of completed session
    let duration = 0;
    sessions.forEach((s: ISession) => {
      countUniqueParticipants.add(s.user_name);
      duration += s.total_session_duration;
    });
    setNumberOfParticipants(Array.from(countUniqueParticipants).length);
    setAverageDurationCompletedSession(`${Math.round(duration / sessions.length)} s`);

    // Best score scene
    dispatch(
      getScorePerSceneForProject({
        projectId: selectedProjectId,
        componentId: SUCCESS_RATE_PER_SCENE_COMPONENT_ID,
      }),
    ).then((res: any) => {
      const sortedSessions = [...res.payload]; // this is needed: https://stackoverflow.com/a/64958159
      sortedSessions.sort((a: ScorePerScene, b: ScorePerScene) => {
        return Number(roundScorePercentage({ ...a })) - Number(roundScorePercentage({ ...b }));
      });

      setWorstScoreSessionName(sortedSessions[0]?.name);
      setBestScoreSessionName(sortedSessions[sortedSessions.length - 1]?.name);
    });

    return () => {
      setBestScoreSessionName("");
      setWorstScoreSessionName("");
    };
  }, [selectedProjectId]);

  return (
    <div className="w-full max-w-9xl mx-auto pt-12 sm:px-6 lg:px-8 personalize-scroll-visible overflow-auto statistics-for-project">
      <InfoButton relevantArticle="aboutStatistiques" />
      <IsLoading
        componentId={SUCCESS_RATE_PER_SCENE_COMPONENT_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <ProjectCharts projectId={selectedProjectId} />
        <div className="grid grid-cols-3 gap-4 w-full mx-auto xl:px-0 py-6">
          <div className="row-span-3">
            <Podium
              sessions={sessions}
              moreThanThreeParticipants={numberOfParticipants > 2 && Boolean(project?.need_login)}
              key={`${selectedProjectId}<<`}
            />
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.successRate")}
              data={
                numberOfSessions === "0"
                  ? t("pages.statistics.noSessions")
                  : `${prettySuccessPercentage}% `
              }
            />
          </div>
          <div className="row-span-2">
            <IsLoading
              componentId={SUCCESS_RATE_PER_SCENE_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.sceneWithBestSuccessPercentage")}
                data={
                  numberOfSessions === "0"
                    ? t("pages.statistics.noSessions")
                    : `${bestScoreSessionName}`
                }
              />
            </IsLoading>
          </div>

          <div className="row-span-1">
            <InfoCard
              title={t("pages.statistics.completionRate")}
              data={
                numberOfSessions === "0"
                  ? t("pages.statistics.noSessions")
                  : `${prettyCompletionPercentage}% `
              }
            />
          </div>
          <div className="row-span-1">
            <IsLoading
              componentId={SUCCESS_RATE_PER_SCENE_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.sceneWithWorstSuccessPercentage")}
                data={
                  numberOfSessions === "0"
                    ? t("pages.statistics.noSessions")
                    : `${worstScoreSessionName}`
                }
              />
            </IsLoading>
          </div>
          <div className="row-span-2">
            <InfoCard title={t("pages.statistics.numberOfSessions")} data={numberOfSessions} />
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.averageDurationCompletedSession")}
              data={
                numberOfSessions === "0"
                  ? t("pages.statistics.noSessions")
                  : averageDurationCompletedSession
              }
            />
          </div>
        </div>
      </IsLoading>
    </div>
  );
};

export default StatisticsForProject;
