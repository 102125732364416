import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "../../common/components/Navigation/Navigation";

const ChatWrapper = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isWixarpedia = location.pathname === "/chat/wixarpedia";

  return (
    <>
      <Navigation title={isWixarpedia ? t("pages.chat.tabs.wixarpedia") : t("pages.chat.title")} />
      <Outlet />
    </>
  );
};

export default ChatWrapper;
