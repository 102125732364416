import React, { useRef, useEffect } from "react";
import parseMarkdown from "./parseMarkdown";

const SCENE_WIDTH = 200; // Width of large scene nodes (circles)
const SCENE_HEIGHT = 100; // Height of large scene nodes (circles)
const ASSET_WIDTH = 50; // Small circle for interactions
const INTERACTION_FO_WIDTH = 50; // foreignObject height
const FO_HEIGHT = 200; // foreignObject height
const SPACING = 30; // Spacing between elements
const COLOR = "#00C889"; // Color for the nodes and lines

const AIGeneratedGraph: React.FC<{ markdown: string; mdStringId?: string }> = ({
  markdown,
  mdStringId,
}) => {
  const scenes = parseMarkdown(markdown);
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable container
  const nodes: JSX.Element[] = [];
  const links: JSX.Element[] = [];
  let xPosition = SPACING; // Starting position for the first scene
  let prevX = 0; // For linking lines between nodes, starting from 0

  // Add horizontal scroll event listener when component mounts
  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const handleWheel = (event: WheelEvent) => {
        // Check if the mouse is over the container and scroll horizontally
        if (event.deltaY !== 0) {
          event.preventDefault(); // Prevent default vertical scroll
          container.scrollLeft += event.deltaY; // Scroll horizontally based on deltaY
        }
      };

      container.addEventListener("wheel", handleWheel);

      // Cleanup event listener on unmount
      return () => {
        container.removeEventListener("wheel", handleWheel);
      };
    }
    return null;
  }, []);

  // Iterate over each scene and create the SVG nodes and links
  scenes.forEach((scene, sceneIndex) => {
    const sceneX = xPosition; // X position for the scene circle or triangle
    const sceneY = SCENE_HEIGHT / 2; // Centered on Y-axis

    // For the first scene, draw it as a triangle, others as circles
    if (sceneIndex === 0) {
      const triangleHeight = ((Math.sqrt(3) / 2) * SCENE_WIDTH) / 1.5; // Height of equilateral triangle

      nodes.push(
        <g key={`scene-${sceneIndex}`}>
          <polygon
            points={`${sceneX},${sceneY - triangleHeight / 2} ${sceneX},${
              sceneY + triangleHeight / 2
            } ${sceneX + SCENE_WIDTH / 1.5},${sceneY}`}
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={2}
          />
          {/* Add the text below the triangle */}
          <foreignObject
            x={sceneX}
            y={SCENE_HEIGHT + 10}
            width={SCENE_WIDTH / 1.5}
            height={FO_HEIGHT}
          >
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
                wordWrap: "break-word",
                width: `${SCENE_WIDTH / 1.5}px`,
                whiteSpace: "normal",
              }}
            >
              {scene.title.toUpperCase()}
            </div>
          </foreignObject>
        </g>,
      );
    } else {
      // Draw the scene circle (large circle for each scene after the first one)
      nodes.push(
        <g key={`scene-${sceneIndex}`}>
          <circle
            cx={sceneX + SCENE_WIDTH / 2}
            cy={sceneY}
            r={SCENE_HEIGHT / 2}
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={2}
          />
          {/* Add the text below the circle */}
          <foreignObject x={sceneX} y={SCENE_HEIGHT + 10} width={SCENE_WIDTH} height={FO_HEIGHT}>
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflow: "visible", // Ensure the text overflows if necessary
                height: "10vh",
              }}
            >
              {scene.title.toUpperCase()}
            </div>
          </foreignObject>
        </g>,
      );
    }

    // Draw a line from the center of the previous element (prevX) to the center of the current scene
    if (sceneIndex > 0) {
      links.push(
        <line
          key={`line-prev-scene-${sceneIndex}`}
          x1={prevX} // End of previous element (centered)
          y1={sceneY}
          x2={sceneX + SCENE_WIDTH / 2} // Center of current element
          y2={sceneY}
          stroke={COLOR}
          strokeWidth={2}
        />,
      );
    }

    // Update the previous X position to the **center of the current circle or triangle**
    prevX = sceneX + SCENE_WIDTH / 2;

    // Draw interaction nodes (small circles) and lines connecting them to the scene
    scene.interactions.forEach((interaction, interactionIndex) => {
      const interactionX =
        xPosition + SCENE_WIDTH + SPACING + interactionIndex * (ASSET_WIDTH + SPACING);
      const interactionY = sceneY;

      nodes.push(
        <g key={`interaction-${sceneIndex}-${interactionIndex}`}>
          <circle
            cx={interactionX + ASSET_WIDTH / 2}
            cy={interactionY}
            r={ASSET_WIDTH / 2}
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={2}
          />
          {/* Add the text below the interaction circle */}
          <foreignObject
            x={interactionX}
            y={interactionY + ASSET_WIDTH}
            width={INTERACTION_FO_WIDTH}
            height={FO_HEIGHT}
          >
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
                wordWrap: "break-word",
                fontSize: "10px",
                whiteSpace: "normal",
                overflow: "visible", // Ensure the text overflows if necessary
              }}
            >
              {interaction.toUpperCase()}
            </div>
          </foreignObject>
        </g>,
      );

      // Add the link from the scene circle to the interaction center
      links.push(
        <line
          key={`link-scene-${sceneIndex}-interaction-${interactionIndex}`}
          x1={sceneX + SCENE_WIDTH / 2} // Center of scene circle or triangle
          y1={interactionY}
          x2={interactionX + ASSET_WIDTH / 2} // Center of the interaction circle
          y2={interactionY}
          stroke={COLOR}
          strokeWidth={2}
        />,
      );
    });

    // Update xPosition for the next scene
    xPosition += SCENE_WIDTH + SPACING + scene.interactions.length * (ASSET_WIDTH + SPACING);
    prevX = xPosition - SPACING;
  });

  // Calculate the size of the SVG based on content
  const svgHeight = SCENE_HEIGHT + 120; // Extra space for the text and triangle top
  const svgWidth = xPosition + SPACING;

  // Use viewBox to ensure the entire content is visible without cropping
  return (
    <div ref={containerRef} style={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100%" }}>
      <svg
        id={mdStringId ? `scenario-helper-svg-${mdStringId}` : undefined}
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight - 30}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {links}
        {nodes}
      </svg>
    </div>
  );
};

export default AIGeneratedGraph;
