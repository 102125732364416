import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Icon from "src/common/components/Icon/Icon";

const AskUsForAdditionalLanguages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-[26rem]">
      <p className="text-xl font-medium mb-2">
        {t("pages.editProjectLanguage.askUsForAdditionalLanguages.didntFindYourLanguageTitle")}
      </p>
      <p>{t("pages.editProjectLanguage.askUsForAdditionalLanguages.didntFindYourLanguageText")}</p>

      <div
        className="flex flex-row items-center cursor-pointer mt-2"
        onClick={() => navigate("/help-center")}
      >
        <p className="text-green-500 uppercase text-lg font-bold mr-2">{t("sidebar.helpCenter")}</p>
        <Icon icon="QuestionMarkCircleIcon" height={28} width={28} className="stroke-green-500" />
      </div>
    </div>
  );
};

export default AskUsForAdditionalLanguages;
