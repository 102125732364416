import React from "react";
import { changeHasAcceptedCGV, getCurrentUser } from "../../features/profile/state/profileSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { ButtonIcon, ButtonText } from "../../common/components/Button";
import { logoutAuth } from "../../features/auth/state/authSlice";
import { storeInLocalStorage } from "../../common/util/localStorage";
import { IsLoading } from "../../common/components/AppState/IsLoading";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EULA = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(getCurrentUser) as any;
  const isUserFrench = user?.preferred_language?.name === "French (France)";
  const AUTH_USER_KEY = "wixar.profile.user";
  const [isVisible, setIsVisible] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const componentId = "eulaComponent";

  const listenToScroll = () => {
    const heightToHideFrom = 300;

    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (
      winScroll > heightToHideFrom &&
      !(
        document.documentElement.clientHeight + Math.ceil(document.documentElement.scrollTop) >=
        document.documentElement.scrollHeight
      )
    ) {
      !isVisible && // to limit setting state only the first time
        setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <>
      <IsLoading componentId={componentId} showSuccess={false}>
        <div className="w-full h-full flex flex-col h-1/6 w-full items-center justify-evenly">
          <h3 className="text-3xl font-bold text-black">{t("pages.EULA.consentEULA")}</h3>
          <Document
            className="h-full"
            file={`${`https://s3.eu-west-3.amazonaws.com/storage.wixar.io/${
              isUserFrench ? "EULA" : "CGV"
            }_SAAS_WIXAR_2022.pdf`}`}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={({ numPages }: any) => setNumPages(numPages)}
            onLoadError={console.error}
          >
            {
              //eslint-disable-next-line
              Array.apply(null, Array(numPages)).map((page, i) => {
                return <Page pageNumber={i + 1} key={`${page}_${i}`} scale={2} />;
              })
            }
            {isVisible && (
              <ButtonIcon
                id="hide"
                text=""
                icon="ArrowDownIcon"
                styled="fixed bottom-20 right-10 w-12 h-12 rounded-full bg-green-500 font-medium text-base border-0"
                iconeDivStyle="flex flex-row items-center justify-evenly"
                marginRight=""
                iconeSize="w-full h-auto stroke-white m-1"
                onClick={() => {
                  window.scrollTo(0, document.documentElement.scrollHeight);
                }}
              />
            )}
          </Document>
          <div>
            <ButtonText
              className="w-40 h-12 rounded-full bg-red-500 font-medium text-lg border-0 mr-5 !text-black"
              text={t("general.reject")}
              onClick={() => {
                dispatch(logoutAuth());
              }}
            />
            <ButtonText
              className="w-40 h-12 rounded-full bg-green-500 font-medium text-lg border-0 !text-white"
              text={t("pages.EULA.button")}
              onClick={() => {
                dispatch(changeHasAcceptedCGV({ componentId: componentId, userId: user?.id })).then(
                  (response) => {
                    storeInLocalStorage(AUTH_USER_KEY, JSON.stringify(response.payload));
                  },
                );
              }}
            />
          </div>
        </div>
      </IsLoading>
    </>
  );
};

export default EULA;
