import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import ProjectLanguageSelectorWrapper from "./LanguageSelection/ProjectLanguageSelectorWrapper";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";

const EditProjectLanguageWrapper = ({ mode = "edit-project" }: { mode?: string }) => {
  const { t } = useTranslation();

  const selectedProject = useSelector(getCurrentProject);

  return (
    <>
      <Navigation
        title={`${t("pages.editProjectName.title")}: ${selectedProject.name}`}
        isProjectView
      />
      <ProjectLanguageSelectorWrapper mode={mode} />
    </>
  );
};

export default EditProjectLanguageWrapper;
