import { NodeScene } from "./nodeTypes";
import { ContentType } from "./dataTypes/ContentType";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const UPCASABLE_ELEMENTS = new Set(["true", "false"]);

export const useNameFormatter = () => {
  const { t } = useTranslation();
  return useMemo(() => new NameFormatter(), [t]);
};

class NameFormatter {
  // we build the class using the translator function from useTranslation

  getHumanizedNodeNameFromNode = (node: NodeScene): string => {
    const { name } = node.details;
    return this.getHumanizedNodeName(name);
  };

  getHumanizedNodeNameFromContentType = (contentType: ContentType) => {
    const { name } = contentType.object;

    return this.getHumanizedNodeName(name);
  };

  getHumanizedNodeName = (name: string | undefined | null) => {
    if (!name) return "";

    // special case of the START NODE
    // ETA 02/08: this behaviour is no longer wanted
    /*
    if (isStartScene) {
      return this.t("graph.nodes.startScene");
    } */

    // now we try to "build" a meaningful name (prefix = some string ending with '_')
    // 1) we remove any prefix (such as "discoVRy_")
    const prefixStart = name.indexOf("_");
    if (prefixStart > -1) {
      name = name.slice(prefixStart + 1);
    }

    // 2) then we split words and keep at most 2 of them
    let elements = name.split(/\s+/g).slice(0, 2);
    elements = elements.flatMap((element) => element.split(/(?=[A-Z]+)/g)).slice(0, 2);

    // 3) optionally we detect "sequenced" names
    if (elements.length === 1 && /-\d+$/.test(elements[0])) {
      const [firstElement] = elements;
      const suffixStart = firstElement.lastIndexOf("-");
      elements = [firstElement.slice(0, suffixStart), `(${firstElement.slice(suffixStart + 1)})`];
    }

    return elements.join(" ");
  };

  getHumanizedFieldName = (fieldName: string) => {
    if (!fieldName) return "";
    const elements = fieldName.split(/[-_\s]+/g);
    const capitalizedElements = [
      // Capitalize the beginning of the sentence
      (elements[0] = this.capitalize(elements[0])),
      // And downcase the others
      ...elements
        .slice(1)
        .map((element) =>
          UPCASABLE_ELEMENTS.has(element)
            ? element.toLocaleUpperCase()
            : element.toLocaleLowerCase(),
        ),
    ];
    // detect questions and add a question mark
    if (capitalizedElements[0] === "Is") {
      capitalizedElements.push("?");
    }
    return capitalizedElements.join(" ");
  };

  getFormattedModelName = (modelName: string) => {
    if (modelName === "content-clean") {
      modelName = "content-clear"; // :happyleaddev:
    }

    let elements = modelName.split("-");
    if (elements[0] === "content") {
      elements = elements.slice(1);
    }

    return elements.map(this.capitalize).join(" ");
  };

  private capitalize = (s?: string) => {
    if (!s) return "";
    return s[0].toLocaleUpperCase() + s.slice(1).toLocaleLowerCase();
  };

  getSameFormattedModelNameThanUnity = (modelName: string) => {
    const { t } = useTranslation();

    switch (modelName) {
      case "content_audio":
      case "content-audio":
        return "Audio";
      case "content_button_1":
        return "Button";
      case "content_button_reload":
        return "Reload Button";
      case "content_button_2":
        return "Countdown Button";
      case "content_clean":
        return "Clear";
      case "content_hotspot_scene_image":
        return "Hotspot Image";
      case "content_hotspot_scene_video":
        return "Hotspot Video";
      case "content_onboarding":
      case "content-onboarding":
        return "Image 2D";
      case "content_photo_catch":
        return "Photo Catch";
      case "content_quizz_1":
        return "Quizz text";
      case "content_quizz_2":
      case "content-quizz-2":
        return "Quizz photo";
      case "content_scoring_general":
        return "Scoring General";
      case "content_scoring_scene":
        return "Scoring Scene";
      case "content_text":
        return "Text";
      case "content_thumbnail":
      case "content-thumbnail":
        return "Thumbnail";
      case "content_tuto":
      case "content-tuto":
        return "Tuto";
      case "content_video_2d":
      case "content-video-2-d":
        return "Video 2d";
      case "content_vr_locator":
        return "Vr Locator";
      case "content_zone_detect":
        return "Zone Detect";
      case "content_speech_to_check":
        return "Speech AI";
      case "content_pdf_viewer":
      case "content-pdf-viewer":
        return "PDF Viewer";
      case "content_3d_viewer":
      case "content-3-d-viewer":
      case "content-3d-viewer":
        return "3D Viewer";
      case "content_quizz_3":
        return "Quizz text";
      case "content_yes_no_question":
        return "Yes/No question";
      case "content_blur":
        return "Blur";
      case "content_scene":
      case "content-scene":
        return "Scene";
      case "content_zone_sort":
        return "Zone Sort";
      case "content_quizz_sort":
        return "Quizz Sort";
      case "content_danger_catch":
        return "Danger Catch";
      case "content_tuto2":
        return "Tuto 2";
      case "content_highlight":
        return "Highlight";
      case "content_random_quizz":
        return "Random Quizz";
      case "content_element_catch":
        return "Element Catch";
      case "content_fire_extinguisher":
        return "Fire Extinguisher";
      case "content_fire":
        return "Fire";
      case "content_quizz_image_multiple":
        return "Quizz Image";
      case "content-avatar-ai":
        return "Avatar AI";
      case "content-avatar-ai-persona":
        return "People AI";

      case "project.id_background_music":
        return `${t("pages.project.backgroundMusic")}`;
      case "project.source_thumbnail":
        return `${t("pages.gallery.deleteMedia.moduleLogo")}`;
      case "project.source_logo":
        return `${t("pages.gallery.deleteMedia.moduleLogo")}`;
      case "project-language-datum.media_image_id":
      case "project-language-datum.media_answer1_id":
      case "project-language-datum.media_answer2_id":
      case "project-language-datum.media_answer3_id":
        return `${t("pages.gallery.deleteMedia.languageData")}`;
      case "project-group.source_thumbnail":
        return `${t("pages.gallery.deleteMedia.projectGroupLogo")}`;
      default:
        return "Unknown";
    }
  };
}

export default NameFormatter;
