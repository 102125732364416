import { Menu, Transition } from "@headlessui/react";
import { GlobeIcon, PencilIcon, PhotographIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/common/state/hooks";
import {
  getSelectedProjectGroup,
  setCurrentProjectGroupAsEdited,
} from "src/features/projectGroups/state/projectGroupsSlice";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";
import Icon from "../Icon/Icon";
import ThemeIcon from "../Icon/ThemeIcon";
import icon360 from "src/assets/icon360.png";
import { setEdited as setEditedProject } from "src/features/projects/state/projectsSlice";

interface ButtonSquareWithDropdownMenuProps {
  options: "projectGroup" | "project";
  onButtonClick?: (value: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
}

const ButtonSquareWithDropdownMenu = ({
  options,
  onButtonClick,
  onOpenChange,
}: ButtonSquareWithDropdownMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);
  const currentProject = useSelector(getCurrentProject);

  const handleDropDown = (selectedAction: string) => {
    dispatch(setCurrentProjectGroupAsEdited());
    switch (selectedAction) {
      case "edit-name":
        navigate(`/courses/${currentProjectGroup.id}/edit-name`);

        break;
      case "edit-thumbnail":
        navigate(`/courses/${currentProjectGroup.id}/edit-thumbnail`);
        break;
      case "edit-image":
        navigate(`/courses/${currentProjectGroup.id}/edit-image`);
        break;
      case "edit-theme":
        navigate(`/courses/${currentProjectGroup.id}/edit-theme`);
        break;
      default:
        break;
    }
    if (onButtonClick) {
      onButtonClick(selectedAction);
    }
  };

  const handleDropdownProject = (selectedAction: string) => {
    dispatch(setEditedProject());
    switch (selectedAction) {
      case "edit-name":
        navigate(`/courses/${currentProjectGroup.id}/list/${currentProject.id}/edit-name`);
        break;
      case "edit-thumbnail":
        navigate(`/courses/${currentProjectGroup.id}/list/${currentProject.id}/edit-thumbnail`);
        break;
      case "edit-language":
        navigate(`/courses/${currentProjectGroup.id}/list/${currentProject.id}/edit-language`);
        break;
      default:
        break;
    }
    if (onButtonClick) {
      onButtonClick(selectedAction);
    }
  };

  const menuOptions =
    options === "projectGroup"
      ? [
          { label: t("tooltip.editNameCourse"), value: "edit-name", icon: PencilIcon },
          {
            label: t("tooltip.editTheme"),
            value: "edit-theme",
            icon: () => (
              <span className="flex justify-center h-5 w-5 mr-2">
                <ThemeIcon />
              </span>
            ),
          },
          { label: t("tooltip.editThumbnail"), value: "edit-thumbnail", icon: PhotographIcon },
          {
            label: t("tooltip.editImage"),
            value: "edit-image",
            icon: () => (
              <div className="relative">
                <img src={icon360} alt="Edit Image" className="h-5 w-5 mr-2" />
              </div>
            ),
          },
        ]
      : [
          { label: t("tooltip.editModuleName"), value: "edit-name", icon: PencilIcon },
          { label: t("tooltip.editLanguage"), value: "edit-language", icon: GlobeIcon },
          { label: t("tooltip.editThumbnail"), value: "edit-thumbnail", icon: PhotographIcon },
        ];

  const handleOptionClick = (value: string) => {
    if (options === "projectGroup") {
      handleDropDown(value);
    } else if (options === "project") {
      handleDropdownProject(value);
    }
  };

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="inline-flex justify-center w-8 h-8">
        {({ open }) => {
          if (onOpenChange) {
            onOpenChange(open);
          }

          return <Icon icon={"PencilAltIcon"} className={"stroke-green-500"} />;
        }}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute w-52 bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none">
          <div className="py-1">
            {menuOptions.map((item) => (
              <Menu.Item key={item.value}>
                {({ active }) => (
                  <button
                    onClick={() => handleOptionClick(item.value)}
                    className={`${
                      active ? "bg-gray-100" : ""
                    } flex px-2 py-2 text-sm text-gray-700 stroke-green-500 w-full text-left items-center`}
                  >
                    <item.icon className="h-5 w-5 stroke-green-500 mr-2" />
                    {t(item.label)}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ButtonSquareWithDropdownMenu;
