export const prettifyPlatformName = (string: string) => {
  switch (string) {
    case "MetaQuest":
      return "(2, 3 & 3S)";
    case "PicoNeo":
      return "(3 & 4)";
    case "Windows10":
      return "Windows 64bit";
    case "WebGLApp":
      return "WebGL App";
    case "WebGLiframe":
      return "WebGL iframe";
    case "WebGLApp_scorm":
      return "SCORM";
    default:
      return string;
  }
};
