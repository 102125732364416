import { StyleSheet, Font, Text } from "@react-pdf/renderer";

export const ReactPdfTextComponents = ({ markdown }: { markdown: string }) => {
  // Markdown content split into lines
  const markdownLines = markdown.split("\n").filter((markdownLine) => {
    return (
      markdownLine &&
      !markdownLine.startsWith("Voici un exemple de scénario pour votre cas d'usage:")
    );
  });

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf",
      },
    ],
  });

  const styles = StyleSheet.create({
    title: {
      fontFamily: "Roboto",
      fontSize: 20,
    },
    subTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
    },
    listElement: {
      fontFamily: "Roboto",
      fontSize: 15,
      marginLeft: 20,
    },
  });

  const PDFTextLines = [];

  if (markdownLines && markdownLines.length) {
    markdownLines.forEach((markdownLine, index) => {
      let rawText = "";

      if (markdownLine.startsWith("### ")) {
        console.log(
          `${index} markdownLines foreach, found title in ${markdownLine}, should be styling it now.. `,
        );
        rawText = markdownLine.substring(4);
        // First scene: do not pad above the scene. Else add an empty line as a breather
        if (index === 0) {
          return PDFTextLines.push(
            <Text style={styles.title as any /* frakkin typescript 🤦‍♀️*/}>{rawText}</Text>,
          );
        }
        PDFTextLines.push(<Text> </Text>);
        return PDFTextLines.push(
          <Text style={styles.title as any /* frakkin typescript 🤦‍♀️*/}>{rawText}</Text>,
        );
      }

      if (markdownLine.startsWith("#### ")) {
        console.log(
          `${index} markdownLines foreach, found subTitle in ${markdownLine}, should be styling it now.. `,
        );
        rawText = markdownLine.substring(5);
        PDFTextLines.push(<Text style={styles.subTitle as any}>{rawText}</Text>);
      }

      if (markdownLine.startsWith("- ")) {
        console.log(
          `${index} markdownLines foreach, found listElement in ${markdownLine}, should NOT be styling it now.. `,
        );
        rawText = `    ${markdownLine};`;
        PDFTextLines.push(<Text style={styles.listElement as any}>{rawText}</Text>);
      }

      return null;
    });
  }

  return <>{PDFTextLines}</>;
};
