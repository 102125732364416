import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputIcon } from "../../../common/components/Input";
import { useForm } from "react-hook-form";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import { IAuthFormData, signIn } from "../state/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import React from "react";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { Error } from "../../../common/components/AppState/Error";
import Footer from "../../../common/components/Footer/Footer";

export const LOGIN_COMPONENT_ID = "loginComponent";

interface ILoginForm {
  labels: {
    title: string;
    subtitle: string;
    email: string;
    password: string;
    lostpwd: string;
    keepSession: string;
    login: string;
  };
  error: string;
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
}

export const LoginFormContainer = ({ mobile }: { mobile: boolean }) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error, jwt } = useSelector((state: RootState) => {
    return {
      error: errorSelector(state, LOGIN_COMPONENT_ID),
      jwt: jwtSelector(state),
    };
  });

  const labels = {
    title: t("pages.login.title"),
    subtitle: t("pages.login.subtitle"),
    email: t("pages.login.email"),
    password: t("pages.login.password"),
    lostpwd: t("pages.login.lostpwd"),
    keepSession: t("pages.login.keepSession"),
    login: t("pages.login.login"),
  };

  const onSubmit = (data: IAuthFormData) => {
    setIsSubmitting(true);
    const from_environment = mobile ? "WebAppMobile" : "WebApp";
    dispatch(signIn({ componentId: LOGIN_COMPONENT_ID, from_environment, ...data }))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  React.useEffect(() => {
    if (jwt && jwt !== "") {
      navigate("/");
    }
  }, [jwt]);

  return (
    <LoginForm labels={labels} onSubmit={onSubmit} error={error} isSubmitting={isSubmitting} />
  );
};

const LoginForm = ({ labels, onSubmit, isSubmitting }: ILoginForm) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { register, handleSubmit } = useForm<IAuthFormData>();
  const { t } = useTranslation();
  return (
    <div className="h-screen">
      <div className="bg-slate-100 flex flex-col h-full relative">
        <svg
          id="visual"
          viewBox="0 0 960 540"
          style={{ position: "absolute", width: "100%", height: "100%", top: "0", bottom: "0" }}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
        >
          <path
            d="M0 399L22.8 393.3C45.7 387.7 91.3 376.3 137 372.7C182.7 369 228.3 373 274 365C319.7 357 365.3 337 411.2 337.2C457 337.3 503 357.7 548.8 357.7C594.7 357.7 640.3 337.3 686 336.7C731.7 336 777.3 355 823 364.3C868.7 373.7 914.3 373.3 937.2 373.2L960 373L960 541L937.2 541C914.3 541 868.7 541 823 541C777.3 541 731.7 541 686 541C640.3 541 594.7 541 548.8 541C503 541 457 541 411.2 541C365.3 541 319.7 541 274 541C228.3 541 182.7 541 137 541C91.3 541 45.7 541 22.8 541L0 541Z"
            fill="#69facc"
          ></path>
          <path
            d="M0 361L22.8 361.3C45.7 361.7 91.3 362.3 137 367.5C182.7 372.7 228.3 382.3 274 392C319.7 401.7 365.3 411.3 411.2 410.5C457 409.7 503 398.3 548.8 396C594.7 393.7 640.3 400.3 686 407.8C731.7 415.3 777.3 423.7 823 424.7C868.7 425.7 914.3 419.3 937.2 416.2L960 413L960 541L937.2 541C914.3 541 868.7 541 823 541C777.3 541 731.7 541 686 541C640.3 541 594.7 541 548.8 541C503 541 457 541 411.2 541C365.3 541 319.7 541 274 541C228.3 541 182.7 541 137 541C91.3 541 45.7 541 22.8 541L0 541Z"
            fill="#00c5d2"
          ></path>
          <path
            d="M0 445L22.8 447.3C45.7 449.7 91.3 454.3 137 449C182.7 443.7 228.3 428.3 274 422.2C319.7 416 365.3 419 411.2 419.7C457 420.3 503 418.7 548.8 424.7C594.7 430.7 640.3 444.3 686 440.5C731.7 436.7 777.3 415.3 823 409.5C868.7 403.7 914.3 413.3 937.2 418.2L960 423L960 541L937.2 541C914.3 541 868.7 541 823 541C777.3 541 731.7 541 686 541C640.3 541 594.7 541 548.8 541C503 541 457 541 411.2 541C365.3 541 319.7 541 274 541C228.3 541 182.7 541 137 541C91.3 541 45.7 541 22.8 541L0 541Z"
            fill="#008cb9"
          ></path>
          <path
            d="M0 470L22.8 466.7C45.7 463.3 91.3 456.7 137 450.5C182.7 444.3 228.3 438.7 274 438.2C319.7 437.7 365.3 442.3 411.2 446.2C457 450 503 453 548.8 453.7C594.7 454.3 640.3 452.7 686 450.8C731.7 449 777.3 447 823 444.3C868.7 441.7 914.3 438.3 937.2 436.7L960 435L960 541L937.2 541C914.3 541 868.7 541 823 541C777.3 541 731.7 541 686 541C640.3 541 594.7 541 548.8 541C503 541 457 541 411.2 541C365.3 541 319.7 541 274 541C228.3 541 182.7 541 137 541C91.3 541 45.7 541 22.8 541L0 541Z"
            fill="#00548e"
          ></path>
          <path
            d="M0 515L22.8 509.8C45.7 504.7 91.3 494.3 137 489.8C182.7 485.3 228.3 486.7 274 487.2C319.7 487.7 365.3 487.3 411.2 491.5C457 495.7 503 504.3 548.8 505.7C594.7 507 640.3 501 686 496.2C731.7 491.3 777.3 487.7 823 491C868.7 494.3 914.3 504.7 937.2 509.8L960 515L960 541L937.2 541C914.3 541 868.7 541 823 541C777.3 541 731.7 541 686 541C640.3 541 594.7 541 548.8 541C503 541 457 541 411.2 541C365.3 541 319.7 541 274 541C228.3 541 182.7 541 137 541C91.3 541 45.7 541 22.8 541L0 541Z"
            fill="#0c1e59"
          ></path>
        </svg>
        <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center flex-col h-[95%] z-10">
          <div className="grid grid-cols-1 grid-rows-3 items-center justify-items-center h-[85%]">
            <img
              src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/WixarLogoDark.png"
              alt="wixar-logo"
              width={"75%"}
            />
            <form className="w-full sm:w-3/4" onSubmit={handleSubmit(onSubmit)}>
              <div className="border bg-white border-gray-200 rounded-lg shadow-md py-3 px-4">
                <div className="my-2">
                  <h2 className="text-xl font-medium text-black">{labels.title}</h2>
                  <p className="text-sm">{labels.subtitle}</p>
                </div>
                <Error
                  componentId={LOGIN_COMPONENT_ID}
                  errorString={t("pages.profile.main.changePassword.error")}
                />
                <IsLoading componentId={LOGIN_COMPONENT_ID} showSuccess={false} />

                <Input
                  registerFct={() => register("email", { required: true, pattern: /^\S+@\S+$/i })}
                  type="email"
                  label={labels.email}
                  placeholder="example@wixar.com"
                />

                <InputIcon
                  registerFct={() => register("password", { required: true })}
                  type={passwordVisible ? "text" : "password"}
                  position="right"
                  label={labels.password}
                  placeholder={labels.password}
                  handleClick={() => setPasswordVisible(!passwordVisible)}
                  icon={passwordVisible ? "EyeIcon" : "EyeOffIcon"}
                />
                <div className="flex justify-between">
                  <button className="text-purple-500 font-medium text-xs border-0">
                    <Link to="/forgottenpassword">{labels.lostpwd}</Link>
                  </button>
                  <div
                    className="invisible"
                    // disabled for now (WIXAR-4139)
                  >
                    <label className="text-xs text-gray-600 select-none">
                      <input
                        type="checkbox"
                        className="appearance-none rounded-sm mr-2 cursor-pointer checked:bg-green-500 ring-0"
                        {...register("rememberme")}
                        disabled // disabled for now (WIXAR-4139)
                      />
                      {labels.keepSession}
                    </label>
                  </div>
                </div>
                <div className="grid pt-3 justify-items-end">
                  <button className="btn-primary-fill" type="submit" disabled={isSubmitting}>
                    {labels.login}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer className="m-1 self-end flex relative" />
      </div>
    </div>
  );
};
