import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getNewProjectSelector,
  getEditedProject,
  getCurrentProject,
  setNewProjectEnabledLanguagesIds,
  setEditedProjectEnabledLanguagesIds,
  updateProject,
} from "../../../features/projects/state/projectsSlice";
import {
  getCurrentElements,
  getSelectedProjectGroup,
  setCurrentElements,
} from "../../../features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "../../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../common/state/store";
import { useEffect, useState } from "react";
import { FooterButtons } from "../../../common/components/FooterButtons";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { loadGraph } from "src/features/graph/state/graphLegacy";
import { PROJECT_LIST_COMPONENT_ID } from "src/features/projects/components/ProjectList";
import { getAllLanguages } from "src/features/languages/state/languagesSlice";
import ModernLanguageSelector from "./ModernLanguageSelector";
import { Dropdown } from "src/common/components/Input";
import { FaRegQuestionCircle } from "react-icons/fa";

interface IProjectLanguageSelectorWrapper {
  incrementStep?: () => void;
  decrementStep?: () => void;
  mode?: string;
}

const ProjectLanguageSelectorWrapper = ({
  incrementStep,
  decrementStep,
  mode = "",
}: IProjectLanguageSelectorWrapper) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    newProject,
    selectedProjectGroup,
    editedProject,
    currentUser,
    currentProject,
    availableLanguages,
  } = useSelector((state: RootState) => ({
    newProject: getNewProjectSelector(state),
    selectedProjectGroup: getSelectedProjectGroup(state),
    editedProject: getEditedProject(state),
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    availableLanguages: getAllLanguages(state),
  }));

  const selectedProjectElements = useSelector(getCurrentElements);

  const userPreferredLanguage: string = currentUser?.preferred_language?.id?.toString() || "1";

  const deduceDefaultLanguageIds = (): string[] => {
    if (mode === "edit-language") {
      return editedProject.enabledLanguagesIds?.length
        ? editedProject.enabledLanguagesIds.map(String)
        : [userPreferredLanguage];
    }
    return newProject.enabledLanguagesIds?.length
      ? newProject.enabledLanguagesIds.map(String)
      : [userPreferredLanguage];
  };

  const [languageIdsValue, setLanguageIdsValue] = useState<string[]>(deduceDefaultLanguageIds());

  // Prepare options for the Dropdown using our selected language IDs.
  const dropdownOptions = languageIdsValue.map((id) => {
    const language = availableLanguages.find((l) => l.id.toString() === id);
    return {
      value: id,
      optionText: language ? t(`general.languages.IETF_BCP_47.${language.IETF_BCP_47}`) : id,
    };
  });

  // When a new source language is picked, make it the first element.
  const handleSourceLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSourceId = e.target.value;
    if (newSourceId !== languageIdsValue[0]) {
      const reordered = [newSourceId, ...languageIdsValue.filter((id) => id !== newSourceId)];
      setLanguageIdsValue(reordered);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === "edit-language") {
      dispatch(setEditedProjectEnabledLanguagesIds(languageIdsValue));
    } else if (mode === "newProject" || mode === "newProjectFromCustomTemplate") {
      dispatch(setNewProjectEnabledLanguagesIds(languageIdsValue));
      if (incrementStep) {
        incrementStep();
      }
    }
  };

  const handleSave = () => {
    if (mode === "edit-language") {
      dispatch(
        updateProject({
          id: currentProject.id,
          payload: {
            enabled_languages: languageIdsValue,
          },
          componentId: PROJECT_LIST_COMPONENT_ID,
        }),
      ).then((res: any) => {
        const updatedProjectElements = [...selectedProjectElements].map((element) => {
          if (element.project.id === res.payload.id) {
            return {
              ...element,
              project: res.payload,
            };
          }
          return element;
        });
        dispatch(setCurrentElements(updatedProjectElements));
        dispatch(loadGraph(currentProject.id));
      });
      navigate(`/courses/${selectedProjectGroup.id}/list/${currentProject.id}/graph`);
    }
  };

  useEffect(() => {
    const baseLanguages =
      mode === "edit-language" ? editedProject.enabledLanguagesIds : newProject.enabledLanguagesIds;
    const newValue =
      baseLanguages && baseLanguages.length ? baseLanguages.map(String) : [userPreferredLanguage];
    setLanguageIdsValue(newValue);
  }, [mode, editedProject.enabledLanguagesIds, newProject.enabledLanguagesIds]);

  const handleCancel = () => {
    navigate(`/courses/${selectedProjectGroup.id}/list/${currentProject.id}/graph`);
  };

  return (
    <>
      <h1 className="text-xl mt-2 mx-9 mb-4">
        {t("pages.editProjectLanguage.selectModuleLanguages")}
      </h1>

      <div className="mx-9 flex flex-row items-center">
        <p className="text-xl font-medium mr-2">{t("pages.editProjectLanguage.sourceLanguage")}:</p>
        <Dropdown
          asDefaultValue
          borderOff
          label=""
          value={languageIdsValue[0]}
          options={dropdownOptions}
          onChangeCb={handleSourceLanguageChange}
          selectedTextStyle={{ color: "#00C889" }}
          optionTextStyle={{ color: "#000000" }}
        />
      </div>

      <div className="flex flex-row items-center mx-9 mt-1 mb-8">
        <FaRegQuestionCircle className="mr-2" color="#00C889" />
        <h1 className="text-md italic">
          {t("pages.editProjectLanguage.sourceLanguageExplanation")}
        </h1>
      </div>

      <form className="flex flex-col grow w-full justify-between" onSubmit={onSubmit}>
        <div className="mx-9">
          <ModernLanguageSelector
            rows={availableLanguages}
            selectedLanguages={languageIdsValue}
            setSelectedLanguages={(selected: string[]) => {
              console.log("Selected language IDs:", selected);
              setLanguageIdsValue(selected);
            }}
          />
        </div>
        {mode === "edit-language" ? (
          <FooterButtons
            cancelCb={handleCancel}
            cancelText={t("general.cancel")}
            nextText={t("general.save")}
            nextCb={handleSave}
          />
        ) : (
          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() => navigate(`/courses/${selectedProjectGroup.id}/list`)}
            backText={t("general.back")}
            backCb={() => decrementStep && decrementStep()}
            nextText={t("general.next")}
            nextDisabled={languageIdsValue.length < 1}
            nextDisabledTooltipText={t("pages.editProjectLanguage.selectAtLeastALanguage")}
          />
        )}
      </form>
    </>
  );
};

export default ProjectLanguageSelectorWrapper;
