import { Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface MadeWithAiIconProps {
  width?: number;
  height?: number;
}

const MadeWithAiIcon: React.FC<MadeWithAiIconProps> = ({ width = 30, height = 30 }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("pages.gallery.generatedByAi")}>
      <div
        className="relative"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <div
          className="absolute inset-0 rounded-full"
          style={{
            borderStyle: "solid",
            borderColor: "rgb(241 245 249)",
            borderWidth: "1px",
          }}
        ></div>

        <div
          className="absolute inset-[2%] rounded-full"
          style={{
            background: "linear-gradient(to right,#00C889,#414587)",
          }}
        ></div>

        <div className="absolute inset-0 flex items-center justify-center">
          <span
            className="text-white font-bold"
            style={{
              fontSize: `${width * 0.45}px`,
              textShadow: "0 0 2px #FFD700",
              position: "relative",
              top: "7%", // Lowered text to make space for sparkle
            }}
          >
            {t("general.ai")}
          </span>
        </div>

        <div
          className="absolute flex items-center justify-center text-white"
          style={{
            top: "5%",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: `${width * 0.25}px`,
            textShadow: "0 0 4px #FFFFFF",
          }}
        >
          ✨
        </div>
      </div>
    </Tooltip>
  );
};

export default MadeWithAiIcon;
