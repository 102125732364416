import { ReactNode } from "react";

// https://logfetch.com/tailwind-css-tooltip-react-next-js/
export const Tooltip = ({
  message,
  children,
  className = "",
  classNameChildren = "",
  forceSingleLine = true,
  lineLength = "w-80",
}: {
  message: string | "";
  children: ReactNode;
  className?: string;
  classNameChildren?: string;
  forceSingleLine?: boolean;
  lineLength?: string;
}) => {
  return message === "" ? (
    <div className={`${className}`}>{children}</div>
  ) : (
    <div className={`relative flex group ${className}`}>
      {children}
      <div
        className={`absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex ${classNameChildren}`}
      >
        <span
          className={`relative z-50 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md select-none ${
            forceSingleLine ? "min-w-max" : lineLength
          }`}
        >
          {message}
        </span>
      </div>
    </div>
  );
};
