import iconImage from "./spatial_full_green.png";

interface VrStudioIconProps {
  height?: number;
  width?: number;
  className?: string;
}

export const VrStudioIcon = ({ height = 32, width = 32, className }: VrStudioIconProps) => {
  return (
    <img src={iconImage} alt="Vr Studio Icon" width={width} height={height} className={className} />
  );
};
