import { createAsyncThunk, createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import { IMedia, ITagConfig } from "../../../model/unityObject";
import { Field } from "../../../features/graph/util/graphFieldsExtractor";
import { getMediaLabelValue, getMediaTypeValue, getTagsOrganizationMedia } from "./filterUtil";
import { cUPP } from "./uploadUtil";
import { uuidv4 } from "src/common/util/uuidv4";

export interface IMediaDraft {
  name: string;
  s3Url: string;
  mimeType: string;
  media360tag: string;
  fileSizeInByte: number;
  fileName: string;
  MD5: string;
  upload_status: string;
  tags: [] | ITagUpload[];
}

export interface IBlockadeLabsModel {
  id: number;
  name: string;
  "max-char": number;
  image: string;
}

export interface IBlockadeLabsPreview {
  thumbnailUrl: string;
  imageUrl: string;
  faultyImageId?: string;
}

export interface IBlockadeLabsStore {
  models: IBlockadeLabsModel[];
  preview?: IBlockadeLabsPreview | null;
}

export interface IElevenlabsVoice {
  id: string;
  name: string;
  language: string;
  gender: string;
}

export interface ITtsPreview {
  url: string;
}

export interface IMediaState {
  all: Array<IMedia>;
  config: {
    tags: Array<ITagConfig>;
    mediaType: Array<string>;
    labels: Array<string>;
  };
  shouldResetFilters: boolean;
  activeArrowFilterList: string;
  currentFilter: IMediaFilter;
  currentEditedMediaField: Field;
  currentMediaSidebar: number;
  currentMediaGallery: number;
  originalMediaGallery: number;
  isUploading: boolean;
  uploadedFile?: File;
  uploadedFileMetadata?: cUPP;
  uploadedOldFile?: File;
  uploadedOldFileMetadata?: cUPP;
  currentUploadsStateList: Array<IUploadItem>;
  openedSlider: string;
  isGalleryLoaded: boolean;
  playingMedia: number;
  warningMediaModules: Array<any>;
  currentModeListGallery: boolean;
  activeButtonModalGalleryConfirm: boolean;
  ttsPreview?: ITtsPreview;
  elevenlabsVoices: IElevenlabsVoice[];
  blockadeLabs: IBlockadeLabsStore;
}

export interface IMediaFilter {
  labels: Array<string>;
  mediaType: string;
  order: "date_asc" | "date_desc" | "size_asc" | "size_desc";
  media360tag?: string;
  filename?: string;
  property?: string;
}

export interface IMediaUpload {
  name: string;
  size_in_bytes: number;
  type: string;
  media_360_tag: string;
  MD5: string;
}
interface UploadStateConfigTags {
  newTags: Array<any>;
  oldTags: Array<any>;
}

interface ISUFPayload {
  file: File;
  metadata: cUPP;
}
export interface IDeleteTagsInAllMedias {
  dataTags: ITagConfig[];
  idSourceMedia: number;
  allMedia: IMedia[];
}

export type UploadState =
  | "in_progress"
  | "completed"
  | "pause"
  | "canceled"
  | "error"
  | "transcoding";
export interface IUploadItem {
  uploadId: string;
  name?: string;
  s3name?: string;
  percent?: number;
  errorMessage?: string | null;
  isTranscoding?: boolean;
  size?: number;
  uploadState?: UploadState;
  uploadData?: IPutChunkParams | null;
}

export interface IUploadState {
  currentUploadsStateList: Array<IUploadItem>;
}
export interface IUploadTagsForDisableButton {
  id: any;
  text: any;
}

export interface IUpdatedWithPlaceholder {
  id: number;
  modelName: string;
  keyName: string;
  mediaType: string;
  newMediaId: number;
}

export interface IDeleteMediaResponse {
  deletedMediaId: number;
  updatedWithPlaceholders: IUpdatedWithPlaceholder[];
}

export interface IBlockadeLabsPreviewPayload {
  modelId: number;
  prompt: string;
  imagePrompt?: string;
}

const initialState: IMediaState = {
  all: [],
  config: { tags: [], mediaType: [], labels: [] },
  currentFilter: {
    order: "date_desc",
    mediaType: "all",
    labels: [],
    media360tag: "",
    filename: "",
    property: "all",
  },
  shouldResetFilters: false,
  activeArrowFilterList: "",
  currentEditedMediaField: { fieldName: "", currentValue: 0, type: "media", mediaType: "image" },
  currentMediaSidebar: 0,
  currentMediaGallery: 0,
  originalMediaGallery: 0,
  isUploading: false,
  currentUploadsStateList: [],
  openedSlider: "",
  isGalleryLoaded: false,
  playingMedia: 0,
  warningMediaModules: [],
  currentModeListGallery: false,
  activeButtonModalGalleryConfirm: true,
  ttsPreview: null,
  blockadeLabs: {
    models: [],
    preview: undefined,
  },
  elevenlabsVoices: [],
};

export const getAllMedia = createAsyncThunk(
  "media/all",
  async ({ orgId }: { orgId: number } & IAppLoaderAction) => {
    const allMediaUrl = `${apiUrls.medias.all}?filters[organization][id][$in][0]=${orgId}&filters[organization][id][$in][1]=3&populate[0]=organization`;
    const tagsOrganizationUrl = `${apiUrls.medias.getTagsOrganization}?populate[organization_id]=*`;

    // Parallelize requests using Promise.all
    const [response, responseTagsOrganization] = await Promise.all([
      axios.get<Array<IMedia>>(allMediaUrl),
      axios.get(tagsOrganizationUrl),
    ]);

    const media = response.data.map((media) => {
      return {
        ...media,
      };
    });

    // Return structured data from both requests
    return {
      data: media,
      orgId: orgId,
      tagsOrganization: responseTagsOrganization.data,
    };
  },
);

export const getMedia = createAsyncThunk(
  "media/one",
  async ({ mediaId }: { mediaId: number } & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.medias.all}/${mediaId}?populate[0]=organization`)
      .then((res) => res.data);
  },
);

export const addTagToDatabase = createAsyncThunk(
  "media/addTagToDatabase",
  async ({ mediaId, orgaId, tags }: ITagUpload & IAppLoaderAction) => {
    const tagsLower = tags.map((tag) => {
      return {
        id: tag.id,
        name: tag.name.toLowerCase(),
      };
    });

    const formData = {
      mediaId: mediaId,
      orgaId: orgaId,
      tags: tagsLower,
    };
    const response = (await axios.post)(`${apiUrls.medias.addTagstoDatabase}`, { data: formData });
    return { data: (await response).data };
  },
);

export const updateMedia = createAsyncThunk(
  "media/update",
  async ({ media }: { media: IMedia } & IAppLoaderAction) => {
    await axios.put(`${apiUrls.medias.all}/${media.id}`);
    return media;
  },
);
export const deleteMedia = createAsyncThunk(
  "media/delete",
  async ({ mediaId }: { mediaId: IMedia["id"] } & IAppLoaderAction) => {
    return await axios
      .delete(`${apiUrls.medias.all}/${mediaId}`)
      .then((res) => res.data as IDeleteMediaResponse);
  },
);

export const deleteMultipleMediaFromIds = createAsyncThunk(
  "media/deleteMultipleMediaFromIds",
  async ({ mediaIds }: { mediaIds: Array<[]> } & IAppLoaderAction) => {
    return await axios
      .delete(`${apiUrls.medias.deleteMultipleMedias}?mediaIds=${mediaIds.toString()}`)
      .then((res) => res.data);
  },
);

export const checkIfMediasAreUsed = createAsyncThunk(
  "media/checkIfMediasAreUsed",
  async ({
    mediaIDs,
    isToDeleteOne = false,
  }: { mediaIDs: Array<IMedia>; isToDeleteOne: boolean } & IAppLoaderAction) => {
    const response = axios.post(apiUrls.medias.checkIfMediasAreUsed, { mediaIDs, isToDeleteOne });
    return { data: (await response).data };
  },
);
export const getMediaUpdate = createAsyncThunk(
  "media/getMediaUpdate",
  async ({ orgId, limit = -1 }: { orgId: number; limit?: number } & IAppLoaderAction) => {
    const response = (await axios.get)<Array<IMedia>>(
      `${apiUrls.medias.all}?_limit=${limit}&organization=${orgId}&organization=3`,
    );
    const responseTagsOrganization = (await axios.get)(`${apiUrls.medias.getTagsOrganization}/`);
    return {
      data: (await response).data,
      orgId: orgId,
      tagsOrganization: (await responseTagsOrganization).data,
    };
  },
);
export const updateMediaDetail = createAsyncThunk(
  "media/update",
  async ({ media }: { media: IMedia } & IAppLoaderAction) => {
    await axios.put(`${apiUrls.medias.all}/${media.id}`, {
      name: media.name,
      media_360_tag: media.media_360_tag,
    });
    return media;
  },
);

export const fetchElevenlabsVoices = createAsyncThunk(
  "media/fetchElevenlabsVoices",
  // eslint-disable-next-line no-empty-pattern
  async ({}: Record<string, any> & IAppLoaderAction) => {
    return await axios.get(`${apiUrls.medias.elevenLabs.fetchVoices}`).then((res) => res.data);
  },
);

export const generateVoiceOver = createAsyncThunk<
  ArrayBuffer,
  {
    voice: string;
    language?: string; // was used with multilingual_v2_5_turbo model; not anymo'e
    text: string;
    tags?: any[];
  } & IAppLoaderAction
>("media/generateVoiceOver", async ({ voice, language = undefined, text, tags }) => {
  const response = await axios.post(
    apiUrls.medias.generateAudio,
    {
      voice,
      language,
      text,
      tags,
    },
    {
      timeout: 300000,
      responseType: "arraybuffer",
    },
  );

  return response.data;
});

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllMedia.pending, (state) => {
      state.isGalleryLoaded = false;
    });
    builder.addCase(getAllMedia.fulfilled, (state, { payload }) => {
      const mediaList = payload.data as Array<IMedia>;
      state.config.tags = getTagsOrganizationMedia(payload.tagsOrganization, payload.orgId);
      state.config.mediaType = getMediaTypeValue(mediaList);
      state.config.labels = getMediaLabelValue(mediaList);
      state.all = mediaList;
      state.isGalleryLoaded = true;
    });
    builder.addCase(updateMedia.fulfilled, (state, { payload }) => {
      state.all = [...state.all].map((cur: IMedia) => (cur.id === payload.id ? payload : cur));
    });
    builder.addCase(getMedia.fulfilled, (state, { payload }) => {
      if (Object.prototype.hasOwnProperty.call(payload, "id")) {
        state.all = [...state.all].map((cur: IMedia) =>
          Number(cur.id) === Number(payload.id) ? payload : cur,
        );
      }
    });
    builder.addCase(getMediaUpdate.fulfilled, (state, { payload }) => {
      const mediaList = payload.data as Array<IMedia>;
      state.all = mediaList;
    });
    builder.addCase(deleteMedia.fulfilled, (state, { payload }) => {
      // remove from gallery store
      state.all = [...state.all].filter((cur: IMedia) => {
        return Number(cur.id) !== Number(payload.deletedMediaId);
      });
    });
    builder.addCase(deleteMultipleMediaFromIds.fulfilled, (state, { payload }) => {
      // remove from gallery store
      state.all = [...state.all].filter((cur: IMedia) => {
        return !payload.find((elt) => Number(elt.deletedMediaId) === Number(cur.id));
      });
    });
    builder.addCase(generateVoiceOver.fulfilled, (state, { payload }) => {
      const createBlobUrl = (audioBuffer: ArrayBuffer) => {
        const blob = new Blob([audioBuffer], { type: "audio/mpeg" });
        return URL.createObjectURL(blob);
      };

      const blobUrl = createBlobUrl(payload);
      state.ttsPreview = { url: blobUrl };
    });
    builder.addCase(fetchElevenlabsVoices.fulfilled, (state, { payload }) => {
      state.elevenlabsVoices = payload;
      //state.all = [...state.all, payload];
    });
    // updates of the store are done in deleteMedia.then(...)
    // (it was too painful to dispatch in other slices from here)
    builder.addCase(fetchBlockadeLabsImageModels.fulfilled, (state, { payload }) => {
      state.blockadeLabs.models = payload;
    });
    builder.addCase(generateBlockadeLabsImagePreview.fulfilled, (state, { payload }) => {
      state.blockadeLabs.preview = payload;
    });
  },
  reducers: {
    setMediaFilter: (state: IMediaState, action: PayloadAction<IMediaFilter>) => {
      state.currentFilter.labels = action.payload.labels;
      state.currentFilter.mediaType = action.payload.mediaType;
      state.currentFilter.order = action.payload.order;
      state.currentFilter.media360tag = action.payload.media360tag;
      state.currentFilter.property = action.payload.property;
    },
    resetMediaFilters: (state: IMediaState, action: PayloadAction<any>) => {
      // Can't understand the purpose of this Marloncode; commenting just in case I missed something
      // if (state.shouldResetFilters && action.payload.cb) {
      //   state.currentFilter = initialState.currentFilter;
      //   state.shouldResetFilters = false;
      //   state.activeArrowFilterList = "";
      //   action.payload.cb();
      // }
      state.currentFilter = initialState.currentFilter;
      state.shouldResetFilters = false;
      state.activeArrowFilterList = "";
      action.payload;
    },
    setFindMediaFilenames: (state: IMediaState, action: PayloadAction<any>) => {
      state.currentFilter.filename = String(action.payload);
    },
    setCurrentEditedMediaField: (state: IMediaState, action: PayloadAction<Field>) => {
      state.currentEditedMediaField = action.payload;
    },
    setCurrentMediaSidebar: (state: IMediaState, action: PayloadAction<number>) => {
      state.currentMediaSidebar = action.payload;
    },
    setCurrentMediaGallery: (state: IMediaState, action: PayloadAction<number>) => {
      state.currentMediaGallery = action.payload;
    },
    setOriginalMediaGallery: (state: IMediaState, action: PayloadAction<number>) => {
      state.originalMediaGallery = action.payload;
    },
    setIsUploading: (state: IMediaState, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload;
    },
    setUploadedFile: (state: IMediaState, action: PayloadAction<ISUFPayload | undefined>) => {
      if (action.payload) {
        state.uploadedFile = action.payload.file;
        state.uploadedFileMetadata = action.payload.metadata;
      } else {
        state.uploadedFile = undefined;
        state.uploadedFileMetadata = undefined;
      }
    },
    setUploadedOldFile: (state: IMediaState, action: PayloadAction<ISUFPayload | undefined>) => {
      if (action.payload) {
        state.uploadedOldFile = action.payload.file;
        state.uploadedOldFileMetadata = action.payload.metadata;
      } else {
        state.uploadedOldFile = undefined;
        state.uploadedOldFileMetadata = undefined;
      }
    },
    setCurrentModeListGallery: (state: IMediaState, action: PayloadAction<boolean>) => {
      state.currentModeListGallery = action.payload;
    },
    //filters state

    setUploadNewTagsInSuggestions: (
      state: IMediaState,
      action: PayloadAction<UploadStateConfigTags>,
    ) => {
      const tagsInStore = action.payload.oldTags;
      const newTag = action.payload.newTags;
      const tagsNewArray = tagsInStore.concat(newTag);
      const uniqueIds: any[] = [];

      const uniqueTags = tagsNewArray.filter((element: any) => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      });
      state.config.tags = uniqueTags;
    },
    addMedia: (state: IMediaState, action: PayloadAction<IMedia>) => {
      state.all.push(action.payload);
      state.shouldResetFilters = true;
      state.activeArrowFilterList = "";
    },
    addNewTags: (state: any, action: PayloadAction<[]>) => {
      state.media.config.iTags.push(action.payload);
    },
    setactiveArrowFilterList: (state: any, action: PayloadAction<string>) => {
      state.activeArrowFilterList = action.payload;
    },
    handleTagForDelete: (state: any, action: PayloadAction<any[]>) => {
      state.UploadedTagsToDelete = action.payload;
    },
    addUploadToCurrentList: (state: IMediaState, action: PayloadAction<IUploadItem>) => {
      if (!state.currentUploadsStateList) {
        state.currentUploadsStateList = [];
      }
      const index = state.currentUploadsStateList.findIndex((e) => {
        return e.uploadId === action.payload.uploadId;
      });
      if (index === -1) {
        state.currentUploadsStateList.push(action.payload);
      } else {
        Object.entries(action.payload).forEach((kv: any) => {
          (state.currentUploadsStateList[index] as any)[kv[0]] = kv[1];
        });
      }
    },
    removeUploadToCurrentList: (state, action) => {
      const index = state.currentUploadsStateList.findIndex((e) => {
        return e.uploadId === action.payload.uploadId && e.s3name === action.payload.s3name;
      });
      state.currentUploadsStateList.splice(index, 1);
    },
    removeTagInTheFilter: (state: any, action: PayloadAction<string>) => {
      state.currentFilter.tags = state.currentFilter.tags.filter(
        (tag: string) => tag !== action.payload,
      );
    },
    resetTagsInTheFilter: (state: any, action: PayloadAction<[]>) => {
      state.currentFilter.tags = action.payload;
    },
    setUploadCurrentList: (state, action) => {
      state.currentUploadsStateList = action.payload;
    },

    setCurrentButtonModalGalleryConfirm: (state, action) => {
      state.activeButtonModalGalleryConfirm = action.payload;
    },
    clearGallery: (state: IMediaState) => {
      (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        (state[key] as any) = initialState[key];
      });
    },
    setOpenedSlider: (state, action) => {
      state.openedSlider = action.payload;
    },
    setPlayingMedia: (state, action) => {
      state.playingMedia = action.payload;
    },
    resetTtsPreview: (state) => {
      state.ttsPreview = null;
    },
    setBlockadeLabsImagePreview: (state, action) => {
      state.blockadeLabs.preview = action.payload;
    },
    resetBlockadeLabsImagePreview: (state) => {
      state.blockadeLabs.preview = null;
    },
    /* ETA 18 sept 2023: this is deprecated 
    setUploadHasBeenTranscoded: (state, action) => {
     // We need to update state.all to stop hiding the relevant media and
    //  we need to update state.currentUploadsStateList to display "completed" & allow to empty the upload list
      
const updatedState = state.all.map((media: IMedia) => {
	if (Number(media.id) === Number(action.payload)) {
		state.currentUploadsStateList = [...state.currentUploadsStateList].map(
			(currentUpload: IUploadItem) => {
				if (media.filename === currentUpload.s3name) {
					return {
						...currentUpload,
                  uploadState: "completed",
                };
              } else {
								return currentUpload;
              }
            },
						);
						return {
							...media,
							upload_status: "completed",
						};
        } else {
					return media;
        }
      });
      state.all = updatedState;
    },
		*/
  },
});

export const mediaConfigSelector = (state: RootState) => state.media.config;

export const mediaSelector = (state: RootState) => state.media.all;

export const mediaByProjectSelector = (state: RootState): Array<IMedia> => {
  //@todo don't use graphLegacy
  const mediaIdForCurrentProject = state.graphLegacy.sourceMedias.ids;
  const allMedia = mediaSelector(state);
  return allMedia.filter((media: IMedia) => mediaIdForCurrentProject.includes(media.id));
};
export const currentFilterSelector = (state: RootState) => state.media.currentFilter;
export const isUploading = (state: RootState) => state.media.isUploading;
export const currentMediaSelector = (state: RootState) =>
  state.media.all.find(
    (media: IMedia) => Number(media.id) === Number(state.media.currentMediaSidebar),
  );
export const getCurrentEditedMediaField = (state: RootState) => state.media.currentEditedMediaField;
export const currentEditedMediaSelector = (state: RootState) =>
  state.media.all.find(
    (media: IMedia) =>
      Number(media.id) === Number(state.media.currentEditedMediaField.currentValue),
  );
export const getCurrentMediaGallery = (state: RootState) =>
  state.media.all.find(
    (media: IMedia) => Number(media.id) === Number(state.media.currentMediaGallery),
  );
export const getOriginalMediaGallery = (state: RootState) =>
  state.media.all.find(
    (media: IMedia) => Number(media.id) === Number(state.media.originalMediaGallery),
  );
export const getCurrentMediaGalleryId = (state: RootState) => state.media.currentMediaGallery;
export const getOriginalMediaGalleryId = (state: RootState) => state.media.originalMediaGallery;
export const getUploadedFile = (state: RootState) => state.media.uploadedFile;
export const getUploadedOldFile = (state: RootState) => state.media.uploadedOldFile;
export const getUploadedFileMetadata = (state: RootState) => state.media.uploadedFileMetadata;
export const getCurrentUploadsStateList = (state: RootState) => state.media.currentUploadsStateList;
export const getCurrentTagsOrganizationId = (state: RootState) => state.media.config.tags;
export const getOpenedSlider = (state: RootState) => state.media.openedSlider;
export const getIsGalleryLoaded = (state: RootState) => state.media.isGalleryLoaded;
export const getPlayingMedia = (state: RootState) => state.media.playingMedia;
export const getCurrentMediaFilterTag = (state: RootState) => state.media.currentFilter;
export const mediaReducer = mediaSlice.reducer;
export const getCurrentModeListGallery = (state: RootState) => state.media.currentModeListGallery;
export const getCurrentActiveArrowFilterList = (state: RootState) =>
  state.media.activeArrowFilterList;
export const getActiveButtonModalGalleryConfirm = (state: RootState) =>
  state.media.activeButtonModalGalleryConfirm;
export const getElevenlabsVoices = (state: RootState) => state.media.elevenlabsVoices;
export const getTtsPreview = (state: RootState) => state.media.ttsPreview;
export const getBlockadeLabsModels = (state: RootState) => state.media.blockadeLabs.models;
export const getBlockadeLabsPreview = (state: RootState) => state.media.blockadeLabs.preview;

export const {
  addMedia,
  setMediaFilter,
  setCurrentModeListGallery,
  setFindMediaFilenames,
  setCurrentMediaSidebar,
  setCurrentEditedMediaField,
  setCurrentMediaGallery,
  setOriginalMediaGallery,
  setIsUploading,
  removeTagInTheFilter,
  setCurrentButtonModalGalleryConfirm,
  setUploadedFile,
  setUploadedOldFile,
  setUploadNewTagsInSuggestions,
  handleTagForDelete,
  addUploadToCurrentList,
  removeUploadToCurrentList,
  setUploadCurrentList,
  clearGallery,
  setOpenedSlider,
  resetTagsInTheFilter,
  setPlayingMedia,
  resetMediaFilters,
  setactiveArrowFilterList,
  resetTtsPreview,
  setBlockadeLabsImagePreview,
  resetBlockadeLabsImagePreview,
  // setUploadHasBeenTranscoded,
} = mediaSlice.actions;

interface IMultipartUploadParams {
  file: File;
  orgId: number;
  cb?: any;
  media_360_tag: string;
  name: string;
  MD5: string;
  metadata?: Record<string, unknown>;
}

interface IPutChunkParams {
  uploadId: string;
  name: string;
  S3filename: string;
  media360tag: string;
  MD5: string;
  partNumber: number;
  file: File;
  chunkBegining: number;
  chunkEnd: number;
  unsuccessfulTry: number;
  cb: any;
  dispatch?: Dispatch;
  getStore?: any;
  metadata?: Record<string, unknown>;
}

interface ITagUpload {
  mediaId: number;
  orgaId: number;
  tags: ITagConfig[];
}

/* UPLOAD STUFF STARTS HERE */
export const uploadMultipart =
  ({ file, orgId, cb, media_360_tag, name, MD5, metadata = {} }: IMultipartUploadParams): any =>
  (dispatch: Dispatch, getStore: any) => {
    const uploadLimitInBytes = 8e18;
    const chunkSizeInBytes = 10e6;

    if (file.size <= uploadLimitInBytes) {
      const tabTmp = file.name.split(".");
      const extension = tabTmp[tabTmp.length - 1];

      let fileType = file.type;

      if (window.navigator.userAgent.includes("Safari")) {
        if (
          fileType === "" &&
          (extension.toLowerCase() === "ogg" || extension.toLowerCase() === "oga")
        ) {
          fileType = "audio/" + extension.toLowerCase();
        }
      }

      const params = {
        filename: name === "" ? file.name : name,
        mime: fileType,
        extension: extension.toLowerCase(),
        organization: orgId,
        fileSize: file.size,
        MD5: MD5,
        Metadata: { MD5 },
      };

      return axios
        .post(apiUrls.medias.initializeMultipart, { data: params })
        .then((response) => {
          if (!response) throw response;
          // trim S3 path: it will help find the proper "currentUploadsStateList" item to update on 301_TRANSCODED_MEDIA event
          const s3name =
            response.data.S3filename.split("/")[response.data.S3filename.split("/").length - 1];
          dispatch(
            addUploadToCurrentList({
              s3name,
              uploadId: response.data.UploadId,
              name: name === "" ? file.name : name,
              percent: 0,
              errorMessage: null,
              isTranscoding: false,
              size: file.size,
              uploadState: "in_progress",
            }),
          );
          setTimeout(
            () =>
              putChunk({
                uploadId: response.data.UploadId,
                name: name === "" ? file.name : name,
                S3filename: response.data.S3filename,
                media360tag: media_360_tag,
                MD5,
                partNumber: 1,
                file,
                chunkBegining: 0,
                chunkEnd: chunkSizeInBytes,
                unsuccessfulTry: 0,
                cb,
                metadata,
              })(dispatch, getStore),
            0,
          );
          return response;
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
    return 1;
  };

export const askForPublicationOf3DEnvironment =
  ({ file, name }): any =>
  async () => {
    const params = {
      fileName: name === "" ? file.name : name,
    };

    let response;
    await axios
      .post(apiUrls.medias.askForPublicationOf3DEnvironment, { data: params })
      .then((res) => {
        response = res;
      })
      .catch((e: any) => {
        console.warn(e);
      });

    return response;
  };

export const putChunk =
  ({
    uploadId,
    name,
    S3filename,
    media360tag,
    MD5,
    partNumber,
    file,
    chunkBegining,
    chunkEnd,
    unsuccessfulTry,
    cb,
    metadata = {},
  }: IPutChunkParams) =>
  (dispatch: Dispatch, getStore: any) => {
    const waitAfterUnsuccessfulInMs = 6000;
    const waitAfterSuccessfullInMs = 0;
    const chunkSizeInBytes = 10e6;
    let percent = 0;
    const uploadList: [] = getStore().media.currentUploadsStateList;
    const currUpload: any = uploadList.find((elt: IUploadItem) => elt.uploadId === uploadId);
    if (currUpload?.uploadState === "canceled") {
      return;
    }

    if (chunkEnd > file.size) {
      chunkEnd = file.size;
    }

    const getSignedUploadLinkData = {
      UploadId: uploadId,
      PartNumber: partNumber,
      S3filename: S3filename,
    };

    axios
      .post(apiUrls.medias.getSignedPartUploadLink, { data: getSignedUploadLinkData })
      .then((response) => {
        const chunk = file.slice(chunkBegining, chunkEnd);

        const axiosUploadInstance = axios.create();
        delete axiosUploadInstance.defaults.headers.common["Authorization"];

        axiosUploadInstance
          .put(response.data, chunk)
          .then(() => {
            percent = (chunkEnd / file.size) * 100;
            unsuccessfulTry = 0;
            dispatch(
              addUploadToCurrentList({
                uploadId: uploadId,
                percent: percent,
              }),
            );

            if (chunkEnd === file.size) {
              const confirmData = {
                UploadId: uploadId,
                S3filename: S3filename,
              };

              axios
                .post(apiUrls.medias.completeMultipartUpload, { data: confirmData })
                .then((confirmResponse) => {
                  let fileType = file.type;
                  if (window.navigator.userAgent.includes("Safari")) {
                    const tabTmp = file.name.split(".");
                    const extension = tabTmp[tabTmp.length - 1];

                    if (
                      fileType === "" &&
                      (extension.toLowerCase() === "ogg" || extension.toLowerCase() === "oga")
                    ) {
                      fileType = "audio/" + extension.toLowerCase();
                    }
                  }

                  addMediaToDatabase({
                    name,
                    s3Url: confirmResponse.data,
                    mimeType: fileType,
                    media360tag,
                    fileSizeInByte: file.size,
                    fileName: S3filename,
                    cb,
                    dispatch,
                    uploadId,
                    MD5,
                    metadata,
                  });
                })
                .catch((e) => {
                  console.error("error => ", e);
                  throw e;
                });
            } else {
              setTimeout(
                () =>
                  putChunk({
                    uploadId,
                    name,
                    S3filename,
                    media360tag,
                    MD5,
                    partNumber: partNumber + 1,
                    file,
                    chunkBegining: chunkBegining + chunkSizeInBytes,
                    chunkEnd: chunkEnd + chunkSizeInBytes,
                    unsuccessfulTry: 0,
                    cb,
                  })(dispatch, getStore),
                waitAfterSuccessfullInMs,
              );
            }
          })
          .catch((e) => {
            console.error("error => ", e);
            unsuccessfulTry += 1;
            setTimeout(
              () =>
                putChunk({
                  uploadId,
                  name,
                  S3filename,
                  media360tag,
                  MD5,
                  partNumber,
                  file,
                  chunkBegining,
                  chunkEnd,
                  unsuccessfulTry,
                  cb,
                  dispatch,
                })(dispatch, getStore),
              waitAfterUnsuccessfulInMs,
            );
          });
      })
      .catch((e) => {
        console.error("error => ", e);
        dispatch(
          addUploadToCurrentList({
            uploadId: uploadId,
            percent: percent,
            errorMessage: e?.response?.data?.message ?? e,
            uploadState: "error",
          }),
        );
      });
  };

export const addMediaToDatabase = ({
  name,
  s3Url,
  mimeType,
  media360tag,
  fileSizeInByte,
  fileName,
  cb,
  dispatch,
  uploadId = "",
  MD5,
  tts,
  metadata = {},
}: {
  name: string;
  s3Url: string;
  mimeType: string;
  media360tag: string;
  fileSizeInByte: number;
  fileName: string;
  cb?: any;
  dispatch: Dispatch;
  uploadId: string;
  MD5: string;
  tts?: boolean;
  metadata?: Record<string, any>;
}) => {
  const uploadState = "completed";

  const formData = {
    name: name,
    s3Url: s3Url,
    mimeType: mimeType,
    media360tag: media360tag,
    fileSizeInByte: fileSizeInByte,
    fileName: fileName,
    MD5: MD5,
    upload_status: uploadState,
    metadata,
  };

  return axios
    .post(apiUrls.medias.addMediaToDatabase, { data: formData })
    .then((response) => {
      if (cb) {
        cb(response.data);
      }
      if (!tts) {
        dispatch(
          addUploadToCurrentList({
            uploadId: uploadId,
            percent: 100,
            uploadState,
          }),
        );
      }

      dispatch(addMedia(response.data));
      return response.data;
    })
    .catch((e) => {
      console.error("error => ", e);
      dispatch(
        addUploadToCurrentList({
          uploadId: uploadId,
          percent: 100,
          errorMessage: e?.response?.data?.message ?? e,
          uploadState: "error",
        }),
      );
    });
};

export const addBlockadeImageToDatabase = ({
  name,
  imageUrl,
  thumbnailUrl,
  fileSizeInByte,
  fileName,
  cb,
  dispatch,
  prompt,
  MD5,
}: {
  name: string;
  imageUrl: string;
  thumbnailUrl: string;
  fileSizeInByte: number;
  fileName: string;
  cb?: any;
  dispatch: Dispatch;
  prompt: string;
  MD5: string;
}) => {
  const uploadState = "completed";

  const formData = {
    name: name,
    imageUrl: imageUrl,
    thumbnailUrl: thumbnailUrl,
    fileSizeInByte: fileSizeInByte,
    fileName: fileName,
    MD5: MD5,
    upload_status: uploadState,
    prompt,
  };

  return axios
    .post(apiUrls.medias.blockadeLabs.addBlockadeImageToDatabase, { data: formData })
    .then((response) => {
      if (cb) {
        cb(response.data);
      }
      dispatch(
        addUploadToCurrentList({
          uploadId: uuidv4(),
          name,
          percent: 100,
          uploadState,
        }),
      );

      dispatch(addMedia(response.data));
      return response.data;
    })
    .catch((e) => {
      console.error("error => ", e);
      dispatch(
        addUploadToCurrentList({
          uploadId: uuidv4(),
          percent: 100,
          name,
          errorMessage: e?.response?.data?.message ?? e,
          uploadState: "error",
        }),
      );
    });
};

export const fetchBlockadeLabsImageModels = createAsyncThunk(
  "media/fetchBlockadeLabsModels",
  // eslint-disable-next-line no-empty-pattern
  async ({}: any & IAppLoaderAction) => {
    return await axios.get(`${apiUrls.medias.blockadeLabs.models}`).then((res) => res.data);
  },
);

export const generateBlockadeLabsImagePreview = createAsyncThunk(
  "media/generateBlockadeLabsImage",
  async ({
    modelId,
    prompt,
    imagePrompt = undefined,
  }: IBlockadeLabsPreviewPayload & IAppLoaderAction) => {
    try {
      const response = await axios.post(apiUrls.medias.blockadeLabs.generate, {
        skybox_style_id: modelId,
        prompt,
        imagePrompt,
      });
      return response.data;
    } catch (e) {
      console.error("Error in generateBlockadeLabsImagePreview: ", e);
      throw new Error(e);
    }
  },
);
