import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  getCurrentProjectGroupElement,
  getSelectedProjectId,
} from "../../../features/stats/state/statsSlice";
import { Tooltip } from "../Tooltip/Tooltip";

export type NavigationTabsProps = {
  tabs: {
    title: string;
    urlEndPoint: string;
    hidden?: boolean;
  }[];
};

const NavigationTabs = ({ tabs }: NavigationTabsProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const selectedProjectId = useSelector(getSelectedProjectId);
  const currentProjectGroupElement = useSelector(getCurrentProjectGroupElement);
  const activeClassName =
    "inline-block py-2 px-8 text-green-500 rounded-t-lg border-b-2 border-green-500 h-full";
  const inStatsView = location.pathname.includes("statistics");
  // We want to hide tabs while on /statistics Overview
  const isStatisticsOverview = selectedProjectId === 0 && inStatsView;
  // We want to disable 'Participants' tab on !need_login projects
  const shouldDisableParticipants =
    inStatsView && currentProjectGroupElement?.project_group.auth_type === "none";
  return (
    <div
      className={`text-sm font-medium text-center text-gray-500 border-b border-gray-200 ${
        isStatisticsOverview ? "mt-8" : "dark:border-gray-700"
      } dark:text-gray-400 flex flex-wrap -mb-px 
        `}
    >
      {!isStatisticsOverview &&
        tabs.map((tab, i) => {
          const onboardingSteps = (tabTitle: string) => {
            switch (String(tabTitle)) {
              case String(t("general.participants")):
                return "onboardingHowToAddScene-step-3";
              case String(t("general.publish")):
                return "onboardingAboutModules-step-3";
              case String(t("pages.project.projectGroupCollaborators.collaborators")):
                return "onboardingAboutModules-step-2";
              case String(t("general.preview")):
                return "onboardingHowToAddScene-step-2";
              default:
                return "";
            }
          };

          return tab.hidden ? (
            <></>
          ) : (
            <Tooltip
              key={`${tab.toString()}-${i}`}
              message={
                shouldDisableParticipants && tab.urlEndPoint === "learners"
                  ? t("pages.statistics.unavailableForAnonymous")
                  : ""
              }
            >
              <NavLink
                key={i}
                to={tab.urlEndPoint}
                className={({ isActive }) =>
                  isActive
                    ? activeClassName
                    : `inline-block py-2 px-8 rounded-t-lg border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 h-full ${onboardingSteps(
                        tab.title,
                      )} ${shouldDisableParticipants ? "disabled-link" : ""}
										`
                }
                onClick={(e) => {
                  if (shouldDisableParticipants) {
                    return e.preventDefault();
                  } else {
                    return null;
                  }
                }}
              >
                {tab.title}
              </NavLink>
            </Tooltip>
          );
        })}
    </div>
  );
};

export default NavigationTabs;
