import { useState } from "react";
import { useSelector } from "react-redux";
import { closeStandardModal, getShowStandardModal } from "src/common/state/slice/modal/modalSlice";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useTranslation } from "react-i18next";
import { getThemes } from "src/features/themes/state/themeSlice";
import { updateProjectGroup } from "src/features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { BiSearchAlt2 } from "react-icons/bi";
import ButtonAscDesc from "./ButtonAscDesc";
import { setAlert } from "src/features/alert/state/alertsSlice";

interface ProjectGroup {
  id: number;
  name: string;
  theme_info: number;
}

interface AssignThemeProps {
  projectGroups: ProjectGroup[];
  selectedThemeId: number;
  onConfirm: (selectedGroups: number[]) => void;
}

const AssignThemeToProjectGroup = ({
  projectGroups,
  onConfirm,
  selectedThemeId,
}: AssignThemeProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const themes = useSelector(getThemes);
  const showModal = useSelector(getShowStandardModal);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState<"name" | "theme_info" | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [selectAll, setSelectAll] = useState(false);

  const showSelectedTheme = themes.find((theme) => Number(theme.id) === Number(selectedThemeId));

  if (!showModal.show || showModal.id !== "assignToProjectGroupModal") {
    return null;
  }

  const handleToggleGroup = (groupId: number, isDisabled: boolean) => {
    if (isDisabled) return;

    setSelectedGroups((prev) => {
      const newSelectedGroups = prev.includes(Number(groupId))
        ? prev.filter((id) => Number(id) !== Number(groupId))
        : [...prev, Number(groupId)];

      const assignedGroups = projectGroups.filter(
        (group) => group.theme_info === selectedThemeId,
      ).length;
      setSelectAll(newSelectedGroups.length + assignedGroups === projectGroups.length);

      return newSelectedGroups;
    });
  };

  const filteredGroups = [...projectGroups]
    .filter((group) => group.name.toLowerCase().includes(searchText.toLowerCase()))
    .sort((a, b) => {
      if (sortColumn === "name") {
        return sortDirection === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        return sortDirection === "asc" ? a.theme_info - b.theme_info : b.theme_info - a.theme_info;
      }
    });

  const handleOnSelect = (item: unknown) => {
    setSearchText((item as { name: string }).name);
  };

  const resetFields = () => {
    setSearchText("");
    setSelectedGroups([]);
    setSelectAll(false);
    setSortColumn(null);
    setSortDirection("asc");
  };

  const handleOnConfirm = async () => {
    try {
      await Promise.all(
        selectedGroups.map((groupId) =>
          dispatch(
            updateProjectGroup({
              id: groupId,
              payload: { theme_info: selectedThemeId },
            }),
          ),
        ),
      );
      resetFields();
      setSelectedGroups([]);
      dispatch(closeStandardModal("assignToProjectGroupModal"));

      dispatch(
        setAlert({
          type: "success",
          msg: t("pages.themeEditor.themeAssignSuccess"),
          subMsg: t("pages.themeEditor.themeAssignedToProjectGroup"),
          isOpen: true,
        }),
      );

      onConfirm(selectedGroups);
    } catch (error) {
      console.error(error);
      dispatch(
        setAlert({
          type: "warning",
          msg: t("pages.themeEditor.errorAssignTheme"),
          subMsg: t("pages.themeEditor.errorSubAssignTheme"),
          isOpen: true,
        }),
      );
    }
  };

  const handleCancel = () => {
    dispatch(closeStandardModal("assignToProjectGroupModal"));
    resetFields();
  };

  const handleSort = (column: "name" | "theme_info") => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection(column === "name" ? "asc" : "desc");
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allGroupIds = projectGroups
        .filter(
          (group) =>
            !Number(group.theme_info) || Number(group.theme_info) !== Number(selectedThemeId),
        )
        .map((group) => Number(group.id));
      setSelectedGroups(allGroupIds);
    } else {
      setSelectedGroups([]);
    }
    setSelectAll(!selectAll);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-xl shadow-lg max-w-4xl w-full p-6">
        <h2 className="text-xl font-bold mb-4">
          {`${t("pages.themeEditor.themeAssignationTitle")} "${showSelectedTheme?.name}"`}
        </h2>
        <div className="max-h-[300px] w-full pr-[2px] overflow-y-scroll personalize-scroll-visible">
          <table className="w-full border-collapse min-h-[300px]">
            <thead className="sticky top-0 z-10">
              <tr className="bg-gray-200">
                <th className="px-4 py-3 font-normal rounded-tl-lg">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="mr-5 cursor-pointer"
                    />
                    <ButtonAscDesc
                      label={t("general.projectGroups")}
                      column="name"
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th className="px-4 py-2 font-normal w-1/4">
                  <div className="flex justify-center">
                    <ButtonAscDesc
                      label={t("pages.themeEditor.actualTheme")}
                      column="theme_info"
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th className="px-4 py-2 w-80 rounded-tr-lg font-normal">
                  <div className="relative">
                    <ReactSearchAutocomplete
                      items={projectGroups.map((group) => ({ id: group.id, name: group.name }))}
                      onSelect={(item) => handleOnSelect(item)}
                      onSearch={setSearchText}
                      showIcon={false}
                      placeholder={t("general.searchProjectGroup")}
                      showNoResultsText={t("general.noResults")}
                      styling={{
                        borderRadius: "10px",
                        iconColor: "#00c889",
                        clearIconMargin: "10px 30px 10px 10px",
                        placeholderColor: "#ccd7e0",
                        fontFamily: "inherit",
                      }}
                    />
                    <BiSearchAlt2 className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-green-600" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredGroups.length > 0 ? (
                filteredGroups.map((group) => {
                  const isAssignedToSelectedTheme = group.theme_info === selectedThemeId;
                  return (
                    <tr key={group.id} className="border-b">
                      <td className="px-4 py-6 text-left">
                        <input
                          type="checkbox"
                          checked={isAssignedToSelectedTheme || selectedGroups.includes(group.id)}
                          disabled={isAssignedToSelectedTheme}
                          onChange={() => handleToggleGroup(group.id, isAssignedToSelectedTheme)}
                          className={`mr-5 ${
                            isAssignedToSelectedTheme
                              ? "opacity-50 cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                        />
                        {group.name}
                      </td>
                      <td className="px-4 py-6 text-center">
                        {
                          themes.find((theme) => Number(theme.id) === Number(group.theme_info))
                            ?.name
                        }
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="border-b h-[200px]">
                  <td colSpan={3} className="px-4 py-6 text-center text-gray-600">
                    {t("general.noResults")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={handleCancel}
            className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-xl"
          >
            {t("general.cancel")}
          </button>
          <button
            onClick={handleOnConfirm}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-xl"
          >
            {t("general.confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignThemeToProjectGroup;
